import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAdmin } from '../../store';
import { useNavigate } from 'react-router-dom';
import { faBell, faMoon, faSun, faWallet, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SERVER_URL } from '../../config';
import axios from 'axios';
import '../Style/Style.css';

export default function Header({ theme, toggleTheme }) {
    const [showLogout, setShowLogout] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notifications, setNotifications] = useState([]); // Notifications data
    const [unreadCount, setUnreadCount] = useState(0); // Unread notifications count
    const username = useSelector((state) => state.admin.username);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Fetch unread notifications count
    const fetchUnreadCount = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/notifications/unread-count`);
            setUnreadCount(response.data.unreadCount);
        } catch (error) {
            console.error('Error fetching unread notifications count:', error);
        }
    };

    // Fetch notifications
    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/notifications`);
            setNotifications(response.data);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    // Mark all notifications as read
    const markNotificationsAsRead = async () => {
        try {
            await axios.post(`${SERVER_URL}/api/notifications/mark-read`);
            setUnreadCount(0); // Reset unread count
        } catch (error) {
            console.error('Error marking notifications as read:', error);
        }
    };

    // Remove a specific notification
    const removeNotification = (notificationId) => {
        setNotifications((prevNotifications) => {
            // Create a new array with the notification removed
            return prevNotifications.filter((notification) => notification.id !== notificationId);
        });
    };
    

    // Toggle notifications popup
    const toggleNotifications = () => {
        setShowNotifications((prev) => !prev);
        if (!showNotifications) {
            fetchNotifications(); // Fetch notifications if opening
            markNotificationsAsRead(); // Mark all as read
        }
    };

    useEffect(() => {
        fetchUnreadCount(); // Fetch unread count on component mount
    }, []);

    const handleLogout = () => {
        dispatch(logoutAdmin());
        navigate('/admin/login');
    };

    return (
        <div className={`h-[10vh] flex justify-center items-center ${theme === 'light' ? 'bg-white text-black' : 'bg-[#0F0F0F] text-white'}`}>
            <nav className='flex justify-between items-center px-5 w-[100%]'>
                <div>
                    <h1 className='uppercase font-bold text-xl md:text-3xl font-Mont'>Admin Panel</h1>
                </div>
                <div className='relative flex justify-end gap-3 lg:gap-7 items-center lg:text-xl'>
                    {/* Bell Icon with Unread Notifications Count */}
                    <div className='relative'>
                        <FontAwesomeIcon
                            icon={faBell}
                            className='shake cursor-pointer'
                            onClick={toggleNotifications}
                        />
                        {unreadCount > 0 && (
                            <span className='absolute top-[-5px] right-[-5px] bg-red-500 text-white text-xs rounded-full h-5 w-5 flex justify-center items-center'>
                                {unreadCount}
                            </span>
                        )}
                    </div>
                    <img
                        src='/assets/Others/Avatar.svg'
                        alt='Admin Avatar'
                        className='cursor-pointer'
                        onClick={() => setShowLogout((prev) => !prev)}
                    />
                    <span className='font-medium'>{username}</span>
                    <FontAwesomeIcon onClick={toggleTheme} icon={theme === 'light' ? faSun : faMoon} className='cursor-pointer' />
                    <FontAwesomeIcon icon={faWallet} className='cursor-pointer' />

                    {/* Logout menu */}
                    {showLogout && (
                        <button
                            onClick={handleLogout}
                            className='absolute top-[50px] bg-white text-black px-4 text-lg rounded-md shadow-md mr-[150px]'
                        >
                            Logout
                        </button>
                    )}

                    {/* Notifications Popup */}
                    {showNotifications && (
                        <div className='absolute top-[50px] right-0 bg-white text-black w-[300px] max-h-[400px] overflow-y-auto rounded-md shadow-md p-4 z-50'>
                            {notifications.length > 0 ? (
                                notifications.map((notification) => (
                                    <div key={notification.id} className='border-b py-2 flex justify-between items-center'>
                                        <div>
                                            <p className='font-bold'>{notification.username}</p>
                                            <p className='text-sm'>{notification.notification_type}</p>
                                        </div>
                                        {/* <FontAwesomeIcon
                                            icon={faTimes} // Cancel icon (replace with appropriate import)
                                            className='cursor-pointer'
                                            onClick={() => removeNotification(notification.id)}
                                        /> */}
                                    </div>
                                ))
                            ) : (
                                <p className='text-center'>No notifications</p>
                            )}

                        </div>
                    )}
                </div>
            </nav>
        </div>
    );
}
