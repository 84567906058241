import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft, faChevronRight, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faEye, faHeart } from "@fortawesome/free-regular-svg-icons"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MintContract, SERVER_URL, TokenContract, TradeContract, PROVIDER_URL, TransferProxyContract, FractionContract } from '../../../config';
import TradeContractABI from '../../../ABI/TradeContractABI.json'
import TokenContractABI from '../../../ABI/TokenContractABI.json'
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { ethers } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';
import { useSelector } from 'react-redux';
import Currentoffers from "./Currentoffers";


export default function TradeMarketplace({ theme, toggleTheme, NFT_data }) {

    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const user = useSelector((state) => state.user.username);
    const [makeofferpopup, setMakeOfferPopup] = useState(false);
    const [buyQuantity, setBuyQuantity] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);
    const [currentTrans, setCurrentTrans] = useState(null);


    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute z-10 top-1/2 transform -translate-y-1/2 left-0 w-10 h-10 rounded-full bg-black cursor-pointer duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div
            {...props}
            className=" absolute top-1/2 transform -translate-y-1/2 right-0 w-10 h-10 rounded-full bg-black cursor-pointer  duration-500 "
        >
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };

    function formatDateToCustomFormat(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        const options = { day: "numeric", month: "long", year: "numeric" };
        return date.toLocaleDateString("en-GB", options);
    }

    const handlePopUp = async () => {
        if (NFT_data.username === user) {
            toast.error("Owner cannot make Offer");
            return false;
        }
        if (buyAmount < 1) {
            toast.error("Enter the amount more than $1");
            return false;
        }

        if (NFT_data.nftType === 'fnft') {
            if (buyQuantity < 1) {
                toast.error("Fractions to buy must be greater than zero");
                return false;
            }

            if (buyQuantity > NFT_data.fractions_to_sell) {
                toast.error("Fractions to buy cannot exceed total available fractions");
                return false;
            }
            getSignature()
        }

        if (NFT_data.nftType === 'nft') {
            setMakeOfferPopup(false)
            getSignature();
        }
        setMakeOfferPopup(false)
    }

    const getSignature = async () => {
        try {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const gasPrice = await provider.getGasPrice();

            const nonce = Math.floor(Date.now() / 1000);
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);

            const mintingData = NFT_data.mintingData;
            const fractionsToSell = NFT_data.fractions_to_sell;
            const nftType = NFT_data.nftType;
            const listingStartPrice = NFT_data.listing_start_price;

            let tokenID = mintingData && mintingData ? mintingData.TokenId : null;
            let fractions = mintingData && mintingData ? mintingData.fractions : null;

            if (nftType === 'fnft' && !fractions || fractionsToSell > fractions) {
                toast.error("Invalid NFT data or fractions to sell exceed the limit.");
                return false;
            }

            const amount = nftType === 'fnft'
                ? ethers.utils.parseEther((Number(listingStartPrice) * Number(fractions)).toString())
                : ethers.utils.parseEther(listingStartPrice.toString());

            const BuyAmountInWei = ethers.utils.parseEther(buyAmount.toString());

            toast.loading("Requesting approval for Tokens...");
            const gasLimit = await TokenCont.estimateGas.approve(TransferProxyContract, BuyAmountInWei);
            const FractionApproval = await TokenCont.approve(TransferProxyContract, BuyAmountInWei, {
                gasPrice,
                gasLimit: gasLimit.mul(2),
            });
            const FractApproval = await FractionApproval.wait();
            toast.dismiss();

            if (FractApproval.status === 0) {
                toast.error("Approval failed.");
                return false;
            }
            toast.success("Approval successful!");

            const contract = nftType === "nft" ? MintContract : mintingData[0].ContractDeployed;
            const feePercentage = await Trade.buyerServiceFee();
            const feeAmount = amount.mul(feePercentage).div(1000);
            const TotalAmountWithGas = amount.add(feeAmount);

            toast.loading("Signing message...");
            const sign = await signMessage(contract, tokenID, TokenContract, TotalAmountWithGas, BuyAmountInWei, nonce);
            const Signature1 = await signer.signMessage(sign);
            const { r, s, v } = await splitSign(Signature1);

            const signature = { r, s, v, nonce };
            toast.loading("Saving signature...");

            const response = await axios.post(`${SERVER_URL}/api/saveSignature`, { tokenID, address, signature, functionName: 'makeoffer', username: user });
            toast.dismiss();
            await axios.post(`${SERVER_URL}/api/StoreMakeOfferData`, { orderType: 'buy', user, buyAmount, tokenID });

            if (response.status === 200) {
                toast.success("Signature saved successfully!");
                return true;
            } else {
                toast.error("Failed to save signature. Please try again.");
                return false;
            }
        } catch (error) {
            console.error("Error during signature process:", error);
            toast.dismiss();
            toast.error("An error occurred. Please try again.");
            return false;
        }
    };

    async function signMessage(MintContract, tokenID, TokenContract, amount, nonce) {
        const hash = ethers.utils.solidityKeccak256(
            ["address", "uint256", "address", "uint256", "uint256"],
            [MintContract, tokenID, TokenContract, amount, nonce]
        );
        const msgHash = ethers.utils.arrayify(hash);
        return msgHash;
    }

    async function splitSign(hash) {
        var signature = ethers.utils.splitSignature(hash);
        return signature;
    }

    const handleBuy = async () => {
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }

        const ToastID = toast.loading("Processing your NFT purchase...");

        try {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);

            const gasPrice = await provider.getGasPrice();

            const ownerAddress = NFT_data.mintingData?.UserAddress;
            const paymentToken = TokenContract;
            const nftType = NFT_data.nftType === "nft" ? 0 : 1;
            const paymentMethodETH = false;

            let startingPrice = Number(NFT_data?.listing_start_price);

            if (NFT_data.nftType === "fnft" && buyQuantity > NFT_data.fractions_to_sell) {
                toast.dismiss(ToastID);
                toast.error("Fractions to buy cannot exceed total available fractions");
                return false;
            }

            const payableAmount = NFT_data.nftType === "nft" ? ethers.utils.parseEther((startingPrice).toString()) : ethers.utils.parseEther((startingPrice * buyQuantity).toString());
            const feePercentage = await Trade.buyerServiceFee();
            const feeAmount = payableAmount.mul(feePercentage).div(1000);
            const TotalAmountWithGas = payableAmount.add(feeAmount);
            const TokenID = NFT_data.mintingData?.TokenId;

            const convertFractions = NFT_data.nftType === "nft" ? ethers.utils.parseEther("1") : ethers.utils.parseEther(buyQuantity.toString());
            const contract = NFT_data.nftType === "nft" ? MintContract : NFT_data.mintingData?.ContractDeployed;

            toast.loading("Fetching signature...", { id: ToastID });
            let signature;
            const response = await axios.get(`${SERVER_URL}/api/getSignature?tokenID=${TokenID}&functionName=auction`);
            if (response.data.status === "success") {
                signature = response.data.signature;
            } else {
                toast.dismiss(ToastID);
                toast.error("Failed to fetch signature");
                setTimeout(() => window.location.reload(), 1500);
                return false;
            }

            // if (NFT_data.nftType === "fnft") {
            //     toast.loading("Approving fractions for purchase...", { id: ToastID });
            //     const FractCont = new ethers.Contract(contract, TokenContractABI, signer);
            //     const FractionApproval = await FractCont.approve(TransferProxyContract, convertFractions, {
            //         gasPrice,
            //         gasLimit: 500000,
            //     });
            //     let FractApproval = await FractionApproval.wait();
            //     if (FractApproval.status === 0) {
            //         toast.dismiss(ToastID);
            //         toast.error("Error during the fraction approval");
            //         window.location.reload();
            //         return false;
            //     }
            //     toast.success("Fractions approved successfully!", { id: ToastID });
            // }

            toast.loading("Approving payment token...", { id: ToastID });
            const TokenApproval = await TokenCont.approve(TransferProxyContract, TotalAmountWithGas, {
                gasPrice,
                gasLimit: 500000,
            });
            let Approval = await TokenApproval.wait();
            if (Approval.status === 0) {
                toast.dismiss(ToastID);
                toast.error("Error during the payment approval");
                window.location.reload();
                return false;
            }
            toast.success("Payment token approved successfully!", { id: ToastID });
            console.log((startingPrice * buyQuantity), feeAmount, buyQuantity)
            toast.loading("Executing trade...", { id: ToastID });
            const tx = await Trade.buyAsset(
                [ownerAddress, address, paymentToken, contract, nftType, paymentMethodETH, 1, TotalAmountWithGas, TokenID, convertFractions],
                [signature.v, signature.r, signature.s, Number(signature.nonce)],
                {
                    gasPrice,
                    gasLimit: 500000,
                    value: TotalAmountWithGas.toString(),
                }
            );

            await tx.wait();
            toast.success("NFT purchased successfully!", { id: ToastID });

            toast.loading("Storing purchase details...", { id: ToastID });
            await axios.post(`${SERVER_URL}/api/storeBuyNft`, {
                tokenID: TokenID,
                functionType: "buy",
                oldOwner: ownerAddress,
                newOwner: address,
                transactionHash: tx.hash,
                purchasedAmount: ethers.utils.formatEther(payableAmount),
                asset_id: NFT_data.asset_id,
                newUser: user,
                type: NFT_data.nftType,
            });

            toast.success("Purchase details stored successfully!", { id: ToastID });
        } catch (error) {
            console.error("Error during minting:", error);
            toast.dismiss(ToastID);
            toast.error("An error occurred during the NFT purchase. Please try again.");
            // setTimeout(() => window.location.reload(), 1500);
        }
    };

    const handleFNFTBuy = async () => {
        if (!address || !isConnected) {
            toast.error("Please connect your wallet");
            return false;
        }

        const ToastID = toast.loading("Processing your NFT purchase...");

        try {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            const signer = provider.getSigner();
            const Trade = new ethers.Contract(TradeContract, TradeContractABI, signer);
            const TokenCont = new ethers.Contract(TokenContract, TokenContractABI, signer);

            const gasPrice = await provider.getGasPrice();

            const ownerAddress = NFT_data.mintingData?.UserAddress;
            const paymentToken = TokenContract;
            const nftType = 1;
            const paymentMethodETH = false;

            let startingPrice = Number(NFT_data?.listing_start_price);

            if (NFT_data.nftType === "fnft" && buyQuantity > NFT_data.fractions_to_sell) {
                toast.dismiss(ToastID);
                toast.error("Fractions to buy cannot exceed total available fractions");
                return false;
            }

            const payableAmount = ethers.utils.parseEther((startingPrice * buyQuantity).toString());
            const feePercentage = await Trade.buyerServiceFee();
            const feeAmount = payableAmount.mul(feePercentage).div(1000);
            const TotalAmountWithGas = payableAmount.add(feeAmount);
            const TokenID = NFT_data.mintingData?.TokenId;

            const convertFractions = ethers.utils.parseEther(buyQuantity.toString());
            const contract = NFT_data.mintingData?.ContractDeployed;

            toast.loading("Approving payment token...", { id: ToastID });
            const TokenApproval = await TokenCont.approve(TransferProxyContract, TotalAmountWithGas, {
                gasPrice,
                gasLimit: 500000,
            });
            let Approval = await TokenApproval.wait();
            if (Approval.status === 0) {
                toast.dismiss(ToastID);
                toast.error("Error during the payment approval");
                window.location.reload();
                return false;
            }
            toast.success("Payment token approved successfully!", { id: ToastID });

            console.log((startingPrice * buyQuantity), feeAmount, buyQuantity)


            toast.loading("Executing trade...", { id: ToastID });
            const tx = await Trade.buyOnLaunchPad(
                ownerAddress, convertFractions, payableAmount, contract, paymentToken,
                {
                    gasPrice,
                    gasLimit: 500000,
                }
            );

            await tx.wait();
            toast.success("NFT purchased successfully!", { id: ToastID });

            toast.loading("Storing purchase details...", { id: ToastID });
            await axios.post(`${SERVER_URL}/api/storeBuyNft`, {
                tokenID: TokenID,
                functionType: "buy",
                oldOwner: ownerAddress,
                newOwner: address,
                transactionHash: tx.hash,
                purchasedAmount: ethers.utils.formatEther(payableAmount),
                asset_id: NFT_data.asset_id,
                newUser: user,
                type: NFT_data.nftType,
                buyFractions: buyQuantity
            });

            toast.success("Purchase details stored successfully!", { id: ToastID });
        } catch (error) {
            console.error("Error during minting:", error);
            toast.dismiss(ToastID);
            toast.error("An error occurred during the NFT purchase. Please try again.");
            // setTimeout(() => window.location.reload(), 1500);
        }
    };


    return (
        <div className={`Sec-1 flex flex-col lg:flex-row justify-center gap-16 w-[90%] mx-auto lg:py-10 `}>
            <Toaster />
            <div
                className={`w-[100%] md:w-[75%] mx-auto lg:w-[35%] ${theme === "dark"
                    ? "bg-[#151515] text-white border-[#2b2b2b]"
                    : "bg-[#f8f8f8] text-black border-[#2b2b2b]/15 "
                    } border rounded-lg py-6 px-8`}
            >
                <div className="flex justify-between">
                    <p className={`${theme === "dark"
                        ? "text-white" : "text-black"} font-Manrope`}>{NFT_data?.asset.name}</p>
                    <p className="flex gap-2 items-center">
                        <img src="/assets/Icons/coins1.svg"></img>&nbsp;
                        <FontAwesomeIcon icon={faHeart} className="text-lg" />
                    </p>
                </div>
                <div className="mt-5">
                    {NFT_data?.files?.image?.length < 2 ? (
                        <div>
                            {NFT_data?.files?.image?.map((item, index) => (
                                <div className='px-1 lg:px-3'>
                                    <div><img src={`${item}`} className='w-52 h-auto mx-auto'></img></div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Slider className='' {...settings} >
                            {NFT_data?.files?.image?.map((item, index) => (
                                <div className='px-1 lg:px-3'>
                                    <div><img src={`${item}`} className='w-52 h-auto mx-auto'></img></div>
                                </div>
                            ))}
                        </Slider>
                    )

                    }
                </div>
                <div className='mt-2 grid grid-cols-4 gap-2 w-[90%] mx-auto'>
                    {NFT_data?.files.image.map((item, index) => (
                        <img src={`${item}`} className='rounded-md'></img>
                    ))}
                    {/* <img src='/assets/NFT/NFT1.svg' className='rounded-md'></img>
                    <img src='/assets/NFT/NFT2.svg' className='rounded-md'></img>
                    <img src='/assets/NFT/NFT3.svg' className='rounded-md'></img>
                    <img src='/assets/NFT/NFT4.svg' className='rounded-md'></img> */}
                </div>
                <div className="mt-4 space-y-3">
                    <p>Total Fractions: {NFT_data.nftType == 'nft' ? 'SINGLE NFT (ERC721)' : NFT_data?.asset.totalfractions}</p>
                    <p className=" text-sm  ">Contract Address: <span className="break-all font-medium" >{NFT_data.nftType == 'nft' ? MintContract : NFT_data.mintingData.Contract}</span></p>
                    <p>Token ID: {NFT_data.mintingData.TokenId}</p>
                    <div className="flex items-center space-x-2">
                        <p>Status:</p>
                        <span className="bg-green-600 text-white py-1 text-sm px-3 rounded-md">Listed</span>
                        {/* <span className="text-gray-400 text-sm truncate">(Minted / Listed / Trading)</span> */}
                    </div>
                </div>
            </div>
            <div className="w-[100%] lg:w-[65%]">
                <div>
                    <p className="lg:text-left text-center text-3xl font-Manrope  font-bold flex items-center gap-2">
                        {NFT_data.nftType == 'nft' ? 'SINGLE NFT (ERC721)' : "Fractional NFT"} <div className='p-1 rounded-md bg-secondary text-sm font-normal'>{NFT_data.nftType == 'nft' ? 'NFT' : "F-NFT"}</div>
                    </p>
                </div>
                <div className="flex flex-col md:flex-row justify-start  md:gap-10 mt-5">
                    <p className="flex justify-center items-center gap-2 text-base">
                        <FontAwesomeIcon icon={faUsers} className="text-[#019444]" />
                        &nbsp;2 owners
                    </p>
                    <p className="flex justify-center items-center gap-2 text-base">
                        <FontAwesomeIcon icon={faEye} className="text-[#019444]" />&nbsp;100 Views
                    </p>
                    <p className="flex justify-center items-center gap-2 text-base">
                        <FontAwesomeIcon icon={faHeart} className="text-[#019444]" />&nbsp;favorite
                    </p>
                    <a href='/fractionaldetails'>
                        <button className='bg-secondary rounded-md font-medium text-sm py-1 px-5'>Trade on Marketplace</button>
                    </a>
                </div>
                <div
                    className={`${theme === "dark"
                        ? "bg-[#151515] text-white border-[#2b2b2b]"
                        : "bg-[#f8f8f8] text-black border-[#2b2b2b]/15 "
                        }
                        } border rounded-lg py-6 px-8 mt-10`}
                >
                    <div className="flex flex-col md:flex-row gap-5 md:gap-10 items-center ">
                        <div className="w-[100%] md:w-[70%] flex flex-col gap-3">
                            <h2 className="text-xl font-bold">Current Price</h2>
                            <div>
                                <h1 className="text-3xl font-medium">{NFT_data?.listing_start_price} USDT</h1>
                                <p className="text-sm 2xl:text-base text-gray-300">Supported creator earnings</p>
                            </div>
                            <div className="text-sm 2xl:text-base">
                                <p>Sale Start :<span> {formatDateToCustomFormat(NFT_data?.listing_start_date)}</span></p>
                                <p>Sale End :<span>{formatDateToCustomFormat(NFT_data?.listing_end_date)}</span></p>
                            </div>
                        </div>
                        <div className="w-[100%] md:w-[30%]">
                            <button className="w-full rounded-lg py-3 text-black bg-[#019444]" onClick={() => setMakeOfferPopup(!makeofferpopup)}>
                                {NFT_data?.listing_type === "fixedprice" ? "Buy Now" : "Make Offer"}
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className={`flex justify-between items-center border  ${theme === "dark"
                        ? "bg-[#151515] text-white border-[#2b2b2b]"
                        : "bg-[#f8f8f8] text-black border-[#2b2b2b]/15 "
                        } rounded-lg py-3 px-8 mt-10`}
                >
                    <p className={` ${theme === "dark" ? "text-white " : "text-black"} flex text-xl`}>Chart</p>
                    <a className="cursor-pointer">
                        <FontAwesomeIcon icon={faChevronDown} className="font-bold text-lg" />
                    </a>
                </div>
            </div>


            {makeofferpopup && (
                <div className=" fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-60 ">
                    <div className=" relative w-[95%] mx-auto max-w-md " >
                        <div className=" bg-[#151515] rounded-lg p-3 ">
                            <div className=" w-full flex justify-end ">
                                <button onClick={() => setMakeOfferPopup(!makeofferpopup)} className=" text-2xl font-bold text-[#707070] rotate-45 ">+</button>
                            </div>

                            <div>
                                <p className="text-2xl font-semibold w-full text-center text-white" >Make Offer</p>

                                <div className=" md:w-[70%] mx-auto mt-5 " >
                                    <div className=" flex items-center " >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Name:</p>
                                        <div>
                                            <p className=" font-semibold  text-white">{NFT_data?.asset?.name}</p>
                                        </div>
                                    </div>
                                    {NFT_data?.nftType === 'fnft' ?
                                        <div className=" flex items-center mt-3" >
                                            <p className="text-[#626262] font-semibold w-[60%] ">Quantity:</p>
                                            <div className=' text-white '>
                                                <input type="number" onChange={(e) => setBuyQuantity(e.target.value)} className="w-14 bg-[#707070] rounded-sm shadow shadow-gray-500 px-2 py-0.5 focus:outline-none " /> / 55
                                            </div>
                                        </div> : ""}
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Listing price:</p>
                                        <div>
                                            <p className=" font-semibold  text-white">{NFT_data?.listing_start_price}</p>
                                        </div>
                                    </div>
                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Unit price:</p>
                                        <div className="flex justify-start text-white " >
                                            <input type="number" onChange={(e) => setBuyAmount(e.target.value)} className="w-14 bg-[#707070] rounded-sm shadow shadow-gray-500 px-2 py-0.5 focus:outline-none " />
                                        </div>
                                    </div>

                                    <div className=" flex  items-center mt-3" >
                                        <p className="text-[#626262] font-semibold w-[60%] ">Total price:</p>
                                        <div className="flex justify-start" >
                                            <p className="font-medium uppercase  text-white ">{buyAmount} usdt</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="flex justify-center items-center mt-10">
                                <button className=" bg-[#019444] rounded-lg py-1 px-10 text-sm font-semibold " onClick={handlePopUp} >Confirm Order</button>
                            </div>

                        </div>
                    </div>
                </div>

            )}
        </div>
    )
}
