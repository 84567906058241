import React, { act, useState } from "react";

const Activeorders = ({ theme }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [tableEntries, setTableEntries] = useState([
    {
      name: "abc",
      ordertype: "Buy",
      assettype: "F-NFT",
      orderqty: 150,
      unitprice: "5",
      totalcost: "1250",
    },
    {
      name: "abc",
      ordertype: "Buy",
      assettype: "F-NFT",
      orderqty: 150,
      unitprice: "5",
      totalcost: "1250",
    },
    {
      name: "abc",
      ordertype: "Buy",
      assettype: "F-NFT",
      orderqty: 150,
      unitprice: "5",
      totalcost: "1250",
    },
    {
      name: "abc",
      ordertype: "Buy",
      assettype: "F-NFT",
      orderqty: 150,
      unitprice: "5",
      totalcost: "1250",
    },
    {
      name: "abc",
      ordertype: "Buy",
      assettype: "F-NFT",
      orderqty: 150,
      unitprice: "5",
      totalcost: "1250",
    },
    
  ]);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  return (
    <div
      className={`w-full overflow-hidden overflow-y-auto h-full font-manrope grid content-center`}>
      <div className=" w-[100%] mx-auto">
        <div className="w-[95%] mx-auto mt-3">
          <div className="font-san">
            <p className="text-end text-xs px-3 mb-2">
              Show no of entity
              <select
                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                onChange={handleDropdownChange}
                value={entitiesPerPage}
              >
                <option className="text-black" value="4">
                  4
                </option>
                <option className="text-black" value="5">
                  5
                </option>
                <option className="text-black" value="10">
                  10
                </option>
              </select>
            </p>
            <div className="pb-5  overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full text-center">
                  <thead
                    className={` ${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                      } text-sm rounded-lg  `}
                  >
                    <tr>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Name
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Order Type
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Asset Type
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Order Qty
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Unit Price
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Total Cost
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Action
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Link
                      </th>
                    </tr>
                  </thead>
                  <tr>
                    <td colSpan="8" className="h-4"></td>
                  </tr>
                  <tbody
                    className={`mt-5 ${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                      } w-full text-sm rounded-lg  divide-y divide-[#363636] px-3`}
                  >
                    {currentEntities.map((entity, index) => (
                      <tr
                        key={index}
                        className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                      >
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.name}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.ordertype}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.assettype}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.orderqty}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.unitprice}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.totalcost}
                        </td>
                        <td className="  px-5 lg:px-0 whitespace-nowrap  ">
                          <button className="text-xs bg-[#ff0054] rounded-lg text-white font-medium px-3 py-0.5 ">
                            Cancel
                          </button>
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 ">
                            Open
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mb-3 flex justify-end text-xs px-10">
              <div className="flex gap-3">
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                    }  rounded-md px-2 py-1  `}
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </button>
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                    }  rounded-md px-2 py-1  `}
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activeorders;
