import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const Pasttransactions = ({ theme, NFT_data, data }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [tableEntries, setTableEntries] = useState([]);
  const user = useSelector((state) => state.user.username);

  useEffect(() => {
    if (data) {
      setTableEntries(data.filter((data) => data.status === 'pending'))
    }
  }, [data])

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  function formatDateToReadable(dateString) {
    if (!dateString) return "";

    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "";
    }
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <div className=" w-[100%] h-full mx-auto font-Manrope ">
      <div className={`rounded-md h-full shadow-md mt-10 lg:mt-0 ${theme === "dark" ? "bg-[#151515] border border-[#2b2b2b]" : "bg-[#f8f8f8]"}`}>
        <h2 className="text-xl font-medium text-center translate-y-3">Past Transactions</h2>
        <div className="">
          <p className="text-end text-xs px-3 mb-2">
            Show no of entity
            <select
              className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
              onChange={handleDropdownChange}
              value={entitiesPerPage}
            >
              <option className="text-black" value="4">
                4
              </option>
              <option className="text-black" value="5">
                5
              </option>
              <option className="text-black" value="10">
                10
              </option>
            </select>
          </p>
          <div className=" rounded-xl overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full text-center">
                <thead className='border-y border-[#707070]'>
                  <tr className="text-sm md:text-base font-light  text-[#019444]  ">
                    <th className="py-4 px-5 md:px-0 whitespace-nowrap">
                      Event
                    </th>
                    <th className="py-4 px-5 md:px-0 whitespace-nowrap">
                      Total Price
                    </th>
                    <th className="py-4 px-5 md:px-0 whitespace-nowrap">
                      Quantity
                    </th>
                    <th className="py-4 px-5 md:px-0 whitespace-nowrap">
                      From
                    </th>
                    <th className="py-4 px-5 md:px-0 whitespace-nowrap">
                      To
                    </th>
                    <th className="py-4 px-5 md:px-0 whitespace-nowrap">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-white/5">
                  {currentEntities.length === 0 ? (
                    <tr className="h-14 text-xs md:text-sm text-center">
                      <td colSpan="6" className="px-5 py-3">
                        No Data
                      </td>
                    </tr>
                  ) : (
                    currentEntities.map(
                      (entity, index) => (
                        <tr
                          key={index}
                          className="h-14 text-xs md:text-sm cursor-pointer rounded-xl px-2"
                        >
                          <td className="px-5 lg:px-2 ">
                            {entity?.orderType}
                          </td>
                          <td className="px-5 lg:px-2 ">
                            {entity?.user}
                          </td>
                          <td className="px-5 lg:px-2 ">
                            {entity?.buyAmount
                              ? Number(entity.buyAmount)?.toFixed(3)
                              : <span className="text-gray-400"></span>}
                          </td>
                          <td className="px-5 lg:px-2 text-xs ">
                            {entity?.createdAt
                              ? formatDateToReadable(entity.createdAt)
                              : <span className="text-gray-400"></span>}
                          </td>
                          <td className="px-5 lg:px-2 text-xs ">
                            {entity?.expiryDate
                              ? formatDateToReadable(entity.expiryDate)
                              : <span className="text-gray-400"></span>}
                          </td>
                          <td className="px-5 lg:px-2 text-xs ">
                            {entity?.status
                              ? entity.status
                              : <span className="text-gray-400"></span>}
                          </td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="my-1 flex justify-end text-xs px-5">
            <div className="flex gap-3">
              <button
                className="bg-white/10 rounded-md px-2 py-1"
                onClick={handlePrevClick}
                disabled={currentPage === 1}
              >
                <i class="fa-solid fa-arrow-left"></i>
              </button>
              <button
                className="bg-white/10 text-black rounded-md px-2 py-1"
                onClick={handleNextClick}
                disabled={currentEntities.length < entitiesPerPage}
              >
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pasttransactions