import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function SimilarAssets({ theme }) {
    const Estate = [
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
    ]
    return (
        <div className='sec-5 w-[90%] mx-auto pb-10'>
            <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                <h2 className="text-xl font-bold">Similar Assets</h2>
            </div>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                {Estate.map((item, index) => (
                    <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div className='flex justify-between font-bold'>
                            <div>
                                <h1>TOTAL PRICE</h1>
                                <p className='text-2xl'>{item.totalprice}</p>
                            </div>
                            <div>
                                <h1>Token Price</h1>
                                <p className='text-2xl'>{item.tokenprice}</p>
                            </div>
                        </div>
                        <div className='mt-2 relative'>
                            <img className='w-full' src={`/assets/building/${item.asset}`}></img>
                            <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.status === 'Available' ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.status}</div>
                        </div>
                        <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                            <div className='flex gap-3 items-center text-center text-black'>
                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                    Dubai
                                </div>
                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                    {item.wealthtype}
                                </div>
                            </div>
                            <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> Capitol Hill Mansion</p>
                        </div>
                        <div className='mt-5'>
                            <h1 className='font-bold text-lg'>{item.assettype}</h1>
                            <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                <div className='text-sm 2xl:text-base'>
                                    <h2 className='font-bold'>Expected Income</h2>
                                    <p className='mt-1'>Not including capital appreciation</p>
                                </div>
                                <p className='font-bold text-lg'>4.91%</p>
                            </div>
                            <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                <h2>Income Start Date</h2>
                                <p>Nov 24,2024</p>
                            </div>
                            <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                <h2>Income per Token</h2>
                                <p>Nov 24,2024</p>
                            </div>
                            <div className='flex justify-center mt-2'>
                                <button className='bg-[#019444] rounded-md px-3 py-1'>View Property</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
