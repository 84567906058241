import React, { useState } from "react";
import axios from "axios";

const UploadImageToIPFS = () => {
  const [image, setImage] = useState(null);
  const [ipfsHash, setIpfsHash] = useState("");
  const [loading, setLoading] = useState(false);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const uploadToIPFS = async () => {
    if (!image) {
      alert("Please select an image to upload.");
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("file", image);

      const projectId = "280963e94ba74079b22199a926b4cda9";
      const projectSecret = "yYgASc++OvY0XeJyehysiyktzO6V1iGVzADIR+iZ8VENtK47wv7Y9A";
      const auth = `Basic ${btoa(`${projectId}:${projectSecret}`)}`;

      const response = await axios.post("https://ipfs.infura.io:5001/api/v0/add", formData, {
        headers: {
          Authorization: auth,
          "Content-Type": "multipart/form-data", 
        },
      });

      if (response.status === 200) {
        setIpfsHash(response.data.Hash);
        alert("Image uploaded successfully!");
      } else {
        alert("Failed to upload image.");
      }
    } catch (error) {
      console.error("Error uploading to IPFS:", error);
      alert("An error occurred while uploading the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Upload Image to IPFS</h1>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="mb-4 p-2 border rounded"
      />
      <button
        onClick={uploadToIPFS}
        disabled={loading}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        {loading ? "Uploading..." : "Upload to IPFS"}
      </button>
      {ipfsHash && (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">IPFS Hash:</h2>
          <p className="break-all">{ipfsHash}</p>
          <a
            href={`https://ipfs.io/ipfs/${ipfsHash}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline"
          >
            View on IPFS
          </a>
        </div>
      )}
    </div>
  );
};

export default UploadImageToIPFS;
