import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios"; // Import axios
import { updateUser } from "../../../store";
import { SERVER_URL } from "../../../config";
import { useNavigate } from 'react-router-dom';


const Profilesetup = () => {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  const user = useSelector((state) => state.user); // Get user info from Redux
  console.log(user.username);
  const dispatch = useDispatch(); // Get dispatch function

  const [kycStatus, setKycStatus] = useState(null);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);
  const [username, setUsername] = useState(""); // Default username
  const [newUsername, setNewUsername] = useState("");

  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [email, setEmail] = useState(""); // Default email
  const [newEmail, setNewEmail] = useState("");

  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState(""); // For input field
  const [newPassword, setNewPassword] = useState(""); // For input field

  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(user.avatar);

  const avatarOptions = [
    { id: 1, src: "/assets/avatar/avatar1.jpeg" },
    { id: 2, src: "/assets/avatar/avatar2.jpeg" },
    { id: 3, src: "/assets/avatar/avatar3.jpeg" },
    { id: 4, src: "/assets/avatar/avatar4.jpeg" },
  ];

  const handleAvatarClick = (avatar) => {
    setSelectedAvatar(avatar);
    handleAvatarChange(avatar); // Save the selected avatar
    setIsEditingAvatar(false); // Close the editing UI
  };

  const handleButtonClick = () => {
    // Navigate to the /Kycverify route
    navigate('/Kycverify');
  };

  const handleAvatarChange = async (avatar) => {
    setSelectedAvatar(avatar);
    setIsEditingAvatar(false);

    try {
      const response = await axios.post(`${SERVER_URL}/api/updateavatar`, {
        username: user.username,
        avatar,
      });

      // Handle success or failure messages
      if (response.data.success) {
        alert(response.data.message || "Avatar updated successfully!");
      } else {
        alert(response.data.message || "Failed to update avatar.");
      }
    } catch (error) {
      console.error("Error updating avatar:", error);

      // Show a meaningful error message if the request fails
      if (error.response && error.response.data && error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert("An unexpected error occurred while updating the avatar.");
      }
    }
  };



  const handleEditClick = () => {
    setIsEditing(true);
    // setNewUsername(user.username); // Pre-fill with current username
  };

  const handleSaveClick = async () => {
    // Basic validation for empty username
    if (!newUsername || newUsername.trim() === "") {
      alert("Username cannot be empty");
      return; // Prevent form submission
    }

    // Check if the new username is the same as the current one
    if (newUsername === user.username) {
      alert("New username cannot be the same as the current username.");
      return;
    }

    try {
      // Send both the current username and new username to the backend
      const response = await axios.post(`${SERVER_URL}/api/updateusername`, {
        currentUsername: user.username,
        newUsername,
      }, {
        headers: {
          'Content-Type': 'application/json', // Ensure the content type is set to JSON
        }
      });

      // Handle server response success or failure
      if (response.data.success) {
        // Dispatch the updated username to Redux
        dispatch(updateUser({ username: newUsername }));
        setIsEditing(false);
        alert(response.data.message); // Show success message
      } else {
        // Show failure message from the server
        alert(response.data.message || "Something went wrong.");
      }
    } catch (error) {
      // Catch any error during the request
      console.error("Error updating username:", error);

      // Handle specific Axios error codes if needed
      if (error.response) {
        const errorMessage = error.response.data.message || "An error occurred while updating the username.";
        alert(errorMessage);
      } else {
        // Handle cases where the response is undefined
        alert("Network error or server is down.");
      }
    }
  };

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/getemail`, {
          params: { username: user.username }, // Pass as query parameter
        });
        if (response.data.success) {
          setEmail(response.data.email);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching email:", error);
      }
    };

    fetchEmail();
  }, [username, SERVER_URL]);



  const handleEditEmailClick = () => {
    setIsEditingEmail(true);
    // setNewEmail(email); // Pre-fill with current email
  };

  const handleSaveEmailClick = async () => {
    if (!newEmail) {
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL}/api/updateemail`, {
        username: user.username,
        newEmail,
      }, {
        headers: {
          'Content-Type': 'application/json', // Ensure the content type is set to JSON
        }
      });

      if (response.data.success) {
        alert("Email updated successfully!");
        setEmail(newEmail); // Update displayed email
        setIsEditingEmail(false);
      } else {
        alert(response.data.message || "Failed to update email.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle the specific error returned by the server
        alert(error.response.data.message || "Bad request.");
      } else {
        // Handle other errors (e.g., network issues)
        console.error("Error updating email:", error);
        alert("An error occurred while updating your email.");
      }
    }
  };

  const handleEditPasswordClick = () => {
    setIsEditingPassword(true); // Show the password input field
    setCurrentPassword(""); // Clear inputs
    setNewPassword("");
  };

  const handleSavePasswordClick = async () => {
    if (!currentPassword || !newPassword) {
      alert("Please fill in both fields.");
      return;
    }

    try {
      const response = await axios.post(`${SERVER_URL}/api/updatepassword`, {
        username: user.username, // Assuming `user.username` comes from Redux
        currentPassword,
        newPassword,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.data.success) {
        alert("Password updated successfully!");
        setIsEditingPassword(false);
        setCurrentPassword("");
        setNewPassword("");
      } else {
        alert(response.data.message || "Failed to update password.");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        alert(error.response.data.message || "Bad request.");
      } else {
        console.error("Error updating password:", error);
        alert("An error occurred while updating your password.");
      }
    }
  };
  useEffect(() => {
    if (!user) {
      setError('Username is required');
      return;
    }

    const fetchKYCStatus = async () => {
      try {
        const response = await axios.post(`${SERVER_URL}/api/getkycstatus`, { user: user.username });
        setKycStatus(response.data);
        console.log(response.data);
      } catch (err) {
        console.error('Error fetching KYC status:', err);
        setError('Failed to fetch KYC status');
      }
    };

    fetchKYCStatus(); // Fetch KYC status on component mount
  }, [user]);

  return (
    <div
      className={`w-full bg-cover h-screen overflow-hidden overflow-y-auto font-manrope ${theme === "dark" ? "bg-black text-white" : "bg-white text-black"
        }`}
      style={{
        backgroundImage: `url("../assets/bg/${theme === "dark" ? "bgdark.svg" : "bglight.svg"
          }")`,
      }}
    >
      <div>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <div>
          <p
            className={`  ${theme === "dark" ? "text-white " : " text-[#151515]"
              }  text-center md:text-2xl text-lg font-semibold `}
          >
            Profile Setup
          </p>
          <div className="w-[95%] lg:w-[85%] xl:w-[80%] mx-auto flex md:flex-row flex-col justify-center md:items-start items-center gap-10 mt-10 md:mb-0 mb-10 ">
            <div
              className={`  sm:w-[80%] md:w-1/2 border rounded-lg py-5 px-10 h-full ${theme === "dark"
                ? "bg-[#151515] border-[#2B2B2B] text-white"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                }`}
            >
              <div className="pb-3 border-b border-gray-300/25 py-4">
                {/* Existing Design */}
                <div className="flex justify-between items-center">
                  <div>
                    <p
                      className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Username
                    </p>
                    <p
                      className={`font-semibold text-sm ${theme === "dark" ? "text-white" : "text-black"
                        }`}
                    >
                      {user.username}
                    </p>
                    <p
                      className={`text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : "text-[#413D3D]"
                        }`}
                    >
                      Set a customized username for your profile.
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={handleEditClick}
                      className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                    >
                      Change/Edit
                    </button>
                  </div>
                </div>
                {/* Conditional Input Field */}
                {/* Conditional Input Field */}
                {isEditing && (
                  <div className="mt-3 flex items-center gap-2">
                    <input
                      type="text"
                      value={newUsername}
                      onChange={(e) => setNewUsername(e.target.value)}
                      className={`border border-gray-300 p-1 rounded-md text-sm flex-1 ${theme === "dark" ? "bg-gray-800 text-white" : "bg-white text-black"}`}
                      placeholder="Enter new username"
                      required
                    />
                    <button
                      onClick={handleSaveClick}
                      className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                    >
                      Save
                    </button>
                  </div>
                )}


              </div>

              <div className="flex flex-col py-4 border-b border-gray-300/25">
                {/* Title Section */}
                <div className="flex justify-between items-center">
                  <div>
                    <p
                      className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"}`}
                    >
                      Avatar
                    </p>
                    <p
                      className={`text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : "text-[#413D3D]"}`}
                    >
                      Select an avatar to personalize your account.
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={() => setIsEditingAvatar(!isEditingAvatar)}
                      className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                    >
                      Change/Edit
                    </button>
                  </div>
                </div>

                {/* Avatar Selection */}
                {isEditingAvatar && (
                  <div className="mt-4">
                    <p className="font-semibold mb-2">
                      Choose an Avatar:
                    </p>
                    <div className="flex gap-4 flex-wrap">
                      {avatarOptions.map((avatar) => (
                        <img
                          key={avatar.id}
                          src={avatar.src}
                          alt={`Avatar ${avatar.id}`}
                          onClick={() => handleAvatarClick(avatar.src)}
                          className={`w-16 h-16 cursor-pointer rounded-full border-2 ${selectedAvatar === avatar.src ? "border-green-500" : "border-gray-300"
                            }`}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>




              <div className="pb-3 border-b border-gray-300/25 py-4">
                {/* Existing Design for Email */}
                <div className="flex justify-between items-center">
                  <div>
                    <p
                      className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Email
                    </p>
                    <p
                      className={`font-semibold text-sm ${theme === "dark" ? "text-white" : "text-black"
                        }`}
                    >
                      {email}
                    </p>
                    <p
                      className={`text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : "text-[#413D3D]"
                        }`}
                    >
                      Edit your email address for your profile.
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={handleEditEmailClick}
                      className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                    >
                      Change/Edit
                    </button>
                  </div>
                </div>

                {/* Conditional Input Field for Email */}
                {isEditingEmail && (
                  <div className="mt-3 flex items-center gap-2">
                    <input
                      type="email"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      className={`border border-gray-300 p-1 rounded-md text-sm flex-1 ${theme === "dark" ? "bg-gray-800 text-white" : "bg-white text-black"
                        }`}
                      placeholder="Enter new email"
                    />
                    <button
                      onClick={handleSaveEmailClick}
                      className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>





              <div className="pb-3 border-b border-gray-300/25 py-4">
                {/* Existing Design for Password */}
                <div className="flex justify-between items-center">
                  <div>
                    <p
                      className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Change Password
                    </p>
                    <p
                      className={`text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : "text-[#413D3D]"
                        }`}
                    >
                      Edit your Password for your profile.
                    </p>
                  </div>
                  <div>
                    <button
                      onClick={handleEditPasswordClick}
                      className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                    >
                      Update
                    </button>
                  </div>
                </div>

                {/* Conditional Input Fields for Changing Password */}
                {isEditingPassword && (
                  <div className="mt-3 space-y-2">
                    {/* Current Password Input */}
                    <div className="flex items-center gap-2">
                      <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className={`border border-gray-300 p-1 rounded-md text-sm flex-1 ${theme === "dark"
                          ? "bg-gray-800 text-white"
                          : "bg-white text-black"
                          }`}
                        placeholder="Enter current password"
                      />
                    </div>
                    {/* New Password Input */}
                    <div className="flex items-center gap-2">
                      <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={`border border-gray-300 p-1 rounded-md text-sm flex-1 ${theme === "dark"
                          ? "bg-gray-800 text-white"
                          : "bg-white text-black"
                          }`}
                        placeholder="Enter new password"
                      />
                      <button
                        onClick={handleSavePasswordClick}
                        className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>



              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"
                      } `}
                  >
                    Delete User
                  </p>
                  <p
                    className={` text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                      } `}
                  >
                    Delete your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    Update
                  </button>
                </div>
              </div>
            </div>



            <div
              className={`  sm:w-[80%] md:w-1/2 border rounded-lg py-5 px-10 h-full ${theme === "dark"
                ? "bg-[#151515] border-[#2B2B2B] text-white"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                }`}
            >


              <div className="flex justify-between items-center pb-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"
                      } `}
                  >
                    My Asset
                  </p>
                  <p
                    className={` text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                      } `}
                  >
                    View your asset.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    View
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"
                      } `}
                  >
                    My Bank Details
                  </p>
                  <p
                    className={` text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                      } `}
                  >
                    View your bank details.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    Update
                  </button>
                </div>
              </div>

              <div className="flex justify-between items-center py-4 border-b border-gray-300/25">
                <div>
                  <p
                    className={`font-semibold ${theme === "dark" ? "text-white" : "text-black"}`}
                  >
                    Verify Kyc
                  </p>
                  <p
                    className={`text-xs mt-1 ${theme === "dark" ? "text-[#AAAAAA]" : "text-[#413D3D]"}`}
                  >
                    Edit Kyc details for your profile.
                    {kycStatus?.status && (
                      <p>
                        Status: {kycStatus.status}
                        {kycStatus.status === "rejected" && kycStatus.reason && (
                          <span className="text-red-500"> <br></br> Reason: {kycStatus.reason}</span>
                        )}
                      </p>
                    )}
                  </p>
                </div>
                <div>
                  <button
                    className={`text-xs font-semibold w-24 p-1.5 rounded-md ${!kycStatus?.status || kycStatus?.status === "rejected"
                      ? "bg-[#019444]"
                      : "bg-gray-400 cursor-not-allowed"
                      }`}
                    onClick={handleButtonClick}
                    disabled={kycStatus?.status === "pending" || kycStatus?.status === "accepted"}
                  >
                    Complete
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profilesetup;
