import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './Routes/Routers';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom'; 
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react';

const projectId = '8661278fa8f907da36f7fcc339e42b60';


const polygonMainnet = {
  chainId: 80002,
  name: 'Polygon Amoy testnet',
  currency: 'POL',
  explorerUrl: 'https://amoy.polygonscan.com/',
  rpcUrl: 'https://polygon-amoy.drpc.org'
};


const metadata = {
  name: 'OzoneX',
  description: 'My Website description',
  url: 'https://app.ozonex.io/',
  icons: ['https://app.ozonex.io/assets/Logo/Ozonedark.svg']
};

const modal = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [polygonMainnet],
  themeMode: 'light',
  projectId,
  enableEmail: true,
  defaultChain: polygonMainnet,
  themeVariables: {
    '--w3m-accent': '#019444', // Solid green
  },
  enableAnalytics: true
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter> 
      <Routers />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();