import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from 'react-hot-toast';

export default function Newcatergory({ theme, handleCatergory }) {

    const [categoryName, setCategoryName] = useState('');
    const [nftChecked, setNftChecked] = useState(false);
    const [fnftChecked, setFnftChecked] = useState(false);

    const handleSubmit = async () => {
        if (!categoryName) {
            toast.error('Please enter a category name');
            return;
        }

        let type = '';
        if (nftChecked && fnftChecked) {
            type = 'nft,fnft';
        } else if (nftChecked) {
            type = 'nft';
        } else if (fnftChecked) {
            type = 'fnft';
        } else {
            toast.error('Please select at least one type');
            return;
        }

        try {
            await axios.post(`${SERVER_URL}/api/category`, { categoryName, type });
            toast.success('Category added successfully');
            await handleCatergory();
            setTimeout(() => {
                window.location.reload();
              }, 800);
        } catch (error) {
            console.error(error);
            toast.error('Failed to add category');
        }
    };


    return (
        <div className='fixed inset-0 bg-black/70 flex items-center justify-center'>
            <Toaster/>
            <div className={`w-[40%] rounded-lg py-5 px-6 lg:px-10 relative ${theme === 'dark' ? 'bg-[#212121]' : 'bg-white'}`}>
                <div className='flex justify-end'><FontAwesomeIcon icon={faXmarkCircle} className='absolute top-2 right-2 cursor-pointer' onClick={handleCatergory} /></div>
                <h1 className='font-bold text-xl text-center mb-3 mt-2'>New Category</h1>
                <label className='font-medium'>Category Name</label>
                <input className={`w-[100%] px-3 py-2 rounded-md mt-2 outline-none ${theme === 'dark' ? 'bg-[#707070]' : 'bg-black/30'}`} value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}></input>
                <div className='mt-2 flex gap-2 items-center'><input type='checkbox' checked={nftChecked}
                        onChange={(e) => setNftChecked(e.target.checked)}></input>NFT</div>
                <div className='mt-2 flex gap-2 items-center'><input type='checkbox' checked={fnftChecked}
                        onChange={(e) => setFnftChecked(e.target.checked)}></input>FNFT</div>
                <div className='flex justify-center'>
                <button className='bg-secondary px-5 rounded-md'  onClick={handleSubmit}>Add</button>
                </div>
            </div>
        </div>
    )
}
