import React, { useState } from 'react'
import DistributionHistory from '../Popup/DistributionHistory';

export default function FooterCreation({ theme }) {

    const [DistributeHistory, setDistributeHistory] = useState(false);

    const handleDH = () => {
        setDistributeHistory(!DistributeHistory);
    }

    return (
        <div>
            <div className='flex flex-col md:flex-row justify-end gap-3 md:gap-10 text-lg  items-center font-medium my-3 px-5'>
                <div className={`border-r border-white/50 lg:pr-10 ${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total Buy Orders: 50</h1>
                </div>
                <div className={`${theme === 'dark' ? 'text-white/75 ' : ''}`}>
                    <h1>Total Sell Orders: 60</h1>
                </div>
                <div>
                    <button className='px-5 py-1.5 text-base rounded-md bg-secondary shadow-md' onClick={handleDH}>Dividends Distribution History</button>
                </div>
            </div>
            {DistributeHistory && (
                <DistributionHistory theme={theme} handleDH={handleDH} />
            )}
        </div>
    )
}
