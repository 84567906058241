import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import toast, { Toaster } from "react-hot-toast";
import { SERVER_URL } from '../config';

const ProtectedRoute = ({ children, allowedRoutes }) => {
  const username = useSelector((state) => state.user.username); // Retrieve username from Redux state
  const [kycStatus, setKycStatus] = useState(null); // Track KYC status
  const navigate = useNavigate();

  useEffect(() => {
    const fetchKycStatus = async () => {
      try {
        if (!username) {
          // Redirect to login if username is not found
          setKycStatus('not_logged_in');
          return;
        }

        const response = await axios.get(`${SERVER_URL}/api/checkkyc?username=${username}`);
        const hasKyc = response.data.hasKyc;
        setKycStatus(hasKyc ? 'completed' : 'not_completed'); // Update KYC status
      } catch (error) {
        console.error('Error fetching KYC status:', error);
        toast.error("Unable to verify KYC status. Please try again later.");
        setKycStatus('not_completed'); // Deny access in case of error
      }
    };

    fetchKycStatus();
  }, [username]);

  // Show loading state until KYC status is determined
  if (kycStatus === null) {
    return <div className="text-center mt-10">Loading...</div>;
  }

  // Handle route permissions
  if (!username && !allowedRoutes.includes('not_logged_in')) {
    toast.error("Please login first.");
    // navigate('/login');
    window.location.href = '/login'; 
    return null;
  }

  if (username && kycStatus === 'not_completed' && !allowedRoutes.includes('not_completed')) {
    toast.error("Complete your KYC to access this page.");
    navigate('/profile'); 
    // window.location.href = '/profile'; 
    return null;
  }

  return (
    <>
      <Toaster />
      {children}
    </>
  );
};

export default ProtectedRoute;
