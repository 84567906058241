import React from "react";
import { useState } from "react";
import Header from "../../Layout/Header";
import Accountant from "./Accountant";
import Details from "./Details";
import SimilarAssets from "./SimilarAssets";
import { useLocation } from "react-router-dom";
import Pasttransactions from "./Pasttransactions";
import Currentoffers from "./Currentoffers";
import SingleMarketPlace from "./SingleMarketPlace";

export default function FixedNft() {
    const [theme, setTheme] = useState("light");
    const location = useLocation();
    const NFT_data = location.state
    console.log(NFT_data, 'NFT data')

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    return (
        <div
            className={` bg-center ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <SingleMarketPlace theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <div className="w-[95%] flex xl:flex-row flex-col items-center gap-5 mx-auto " >
                <Pasttransactions theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            </div>
            <Details theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <Accountant theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <SimilarAssets theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />



        </div>
    );
}
