import React from 'react'

export default function Accountant({ theme }) {
    return (
        <div className="Sec-3 w-[90%] mx-auto py-10">
            <p className="font-bold text-2xl">Accountant</p>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 mt-3">
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs">Total Funds Spent</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Total Expenses</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Advance Payment to Suppliers</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs">Total Receipts</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 mt-5 md:mt-7">
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Total Assets</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Monthly avg. for this year</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs"> Total costs this month</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
                <div className={`w-[100%] px-4 py-2 rounded-lg ${theme === "dark"
                    ? "border border-white"
                    : "border border-black"
                    }`}>
                    <p className="text-[#019444] text-xs">Total costs previous month</p>
                    <p className="font-bold mt-2 text-xl">$4,727,454.35</p>
                </div>
            </div>
        </div>
    )
}
