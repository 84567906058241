import React, { useState, useEffect } from 'react'
import Header from '../../Layout/Header'
import Details from './Details';
import UploadDoc from './UploadDoc';
import { SERVER_URL } from '../../../config';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import { withAuthCheck } from '../../../authutils';
import upload from './Ipfs';
import { getCountryCallingCode, parsePhoneNumberFromString } from "libphonenumber-js";

export default function CreateAsset() {
  const [theme, setTheme] = useState("light");
  const [detailsData, setDetailsData] = useState({});
  const [uploadData, setUploadData] = useState({ images: [], videos: [], documents: [] });
  const user = useSelector((state) => state.user.username); // Get user info from Redux
  const isButtonDisabled = useSelector((state) => state.ui.isButtonDisabled);
  const navigate = useNavigate();
  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  

  const [phoneNumberLength, setPhoneNumberLength] = useState(10);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const handleDetailsChange = (updatedDetails) => {
    setDetailsData(updatedDetails);
  };

  useEffect(() => {
    const fetchPermanentWallet = async () => {
      if (!user) {
        console.error("Username is not available in Redux state.");
        return;
      }

      try {
        // Sending the username as a query parameter in the GET request
        const response = await axios.get(`${SERVER_URL}/api/get-user-details`, {
          params: { username: user },  // This sends the username as a query parameter
        });

        if (response.data) {
          setWalletAddress(response.data.permanent_wallet || "");
          console.log(response.data.permanent_wallet);
        } else {
          console.warn("No user details found for the given username.");
          setWalletAddress(""); // Reset if no data
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchPermanentWallet();
  }, [user]);

  const handleSubmit = async () => {

    const errors = {};

    if (isButtonDisabled) {
      console.log(walletAddress);
      const truncatedAddress = `${walletAddress.slice(0, 8)}...${walletAddress.slice(-8)}`;
      toast.error(`Please connect the correct wallet ${truncatedAddress}.`);
      return;
    }

    setLoading(true);
    console.log("Details Data:", detailsData);
    console.log("Upload Data:", uploadData);

    const requiredFields = [
      { key: 'name', label: 'Name' },
      { key: 'type', label: 'Type' },
      { key: 'category', label: 'Category' },
      { key: 'code', label: 'Code' },
      { key: 'status', label: 'Status' },
      { key: 'otherLinks', label: 'Other Relevant Links' },
      { key: 'startDate', label: 'Start Date' },
      { key: 'endDate', label: 'End Date' },
      { key: 'location', label: 'Project Location' },
      { key: 'locationLink', label: 'Location Link' },
      { key: 'website', label: 'Website' },
      { key: 'email', label: 'Email' },
      { key: 'contactNumber', label: 'Contact Number' },
      { key: 'expense', label: 'Expense' },
      { key: 'revenue', label: 'Revenue' },
      { key: 'profit', label: 'Profit' },
      { key: 'description', label: 'Description' },
    ];

    for (const field of requiredFields) {
      if (!detailsData[field.key]) {
        errors[field.key] = `Please fill in the ${field.label} field.`;
      }
    }



    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(detailsData.email)) {
      errors.email = "Please provide a valid email address.";
    }

    // Project location link validation
    if (!/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(detailsData.locationLink)) {
      errors.locationLink = "Please provide a valid project location link.";
    }

    // Website validation
    if (!/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(detailsData.website)) {
      errors.website = "Please provide a valid website link.";
    }

    // Other relevant links validation
    const links = detailsData.otherLinks?.split(',').map((link) => link.trim());
    if (links && !links.every((link) => /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/.test(link))) {
      errors.otherLinks = "Please ensure all provided links are valid.";
    }

    // Contact number validation
    if (detailsData.contactNumber && !/^\d+$/.test(detailsData.contactNumber)) {
      errors.contactNumber = "Contact Number must be numeric.";
    }

    if (!detailsData.RoyaltyFee) {
      errors.RoyaltyFee = "Please fill the Royalty Fee field.";
    }

    if (detailsData.code) {
      const maxLength = getPhoneLengthForCountry(detailsData.code);
      if (detailsData.contactNumber.length !== maxLength) {
        errors.contactNumber = `Contact Number must be length-${maxLength}.`
      }
    }

    if (detailsData.type === 'fnft') {
      if (!detailsData.totalfractions) {
        errors.totalfractions = "Please fill the Total Fractions field.";
      } else if (!/^\d+$/.test(detailsData.totalfractions)) {
        errors.totalfractions = "Total Fractions must be a valid whole number.";
      }

     
      if (!detailsData.Symbol) {
        errors.Symbol = "Please fill the Symbol field.";
      } else if (detailsData.Symbol.length > 5) {
        errors.Symbol = "Symbol should not exceed 5 characters.";
      } else if (/[^a-zA-Z0-9]/.test(detailsData.Symbol)) {
        errors.Symbol = "Symbol should only contain alphanumeric characters.";
      }

      if (!detailsData.TokenName) {
        errors.TokenName = "Please fill the Token Name field.";
      } else if (detailsData.TokenName.length > 25) {
        errors.TokenName = "Token Name should not exceed 25 characters.";
      }


      
    }

    if (
      uploadData.images.length === 0 &&
      uploadData.videos.length === 0 &&
      uploadData.documents.length === 0
    ) {
      toast.error("Please upload at least one file (image, video, or document).");
      setLoading(false);
      return;
    }

    setFormErrors(errors); // Update the errors state

    if (Object.keys(errors).length > 0) {
      setLoading(false); // Stop loading if there are errors
      return;
    }

    const toastId = toast.loading("Submitting your asset...");

    try {
      const formData = new FormData();

      Object.keys(detailsData).forEach((key) => {
        formData.append(key, detailsData[key]);
      });

      formData.append("username", user);

      uploadData.images.forEach((image, index) => {
        formData.append(`images[${index}]`, image);
        formData.append(`imagesMimeTypes[${index}]`, image.type);
      });

      uploadData.videos.forEach((video, index) => {
        formData.append(`videos[${index}]`, video);
        formData.append(`videosMimeTypes[${index}]`, video.type);
      });

      uploadData.documents.forEach((doc, index) => {
        formData.append(`documents[${index}]`, doc);
        formData.append(`documentsMimeTypes[${index}]`, doc.type);
      });

      console.log(formData);
      const response = await axios.post(`${SERVER_URL}/api/nft/create`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.status === 200) {
        toast.success("Asset submitted for approval.", { id: toastId });
        setTimeout(() => {
          window.location.href = '/profile';
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to create asset.", { id: toastId });
      setLoading(false);
    }
  };

  const getPhoneLengthForCountry = (dialCode) => {
    try {
      const examplePhone = parsePhoneNumberFromString(`${dialCode}1234567890`);
      if (!examplePhone) {
        console.error(`Unable to parse example number for dial code: ${dialCode}`);
        return null;
      }
      const phoneLength = examplePhone.nationalNumber.length;
      return phoneLength;
    } catch (error) {
      console.error(`Error processing dial code ${dialCode}:`, error);
      return null;
    }
  };



  return (
    <div className={`bg-cover bg-center font-Manrope ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"
      }`}
    >
      <Toaster />
      <Header theme={theme} toggleTheme={toggleTheme} />
      <div className='Sec-1 lg:px-20 lg:pt-10 pb-5 px-5'>
        <div>
          <p className='text-2xl text-center font-bold'>Create Asset</p>
        </div>
      </div>
      <Details theme={theme} onDetailsChange={handleDetailsChange} formErrors={formErrors} />
      <UploadDoc onUploadChange={(data) => setUploadData(data)} />
      <div className="flex justify-end pb-10 w-[90%] mx-auto">
        <button
          className={`rounded-md bg-secondary px-7 py-2 text-white font-bold font-semibold ${loading ? 'cursor-not-allowed' : ''
            }`}
          onClick={!loading ? handleSubmit : undefined}
          disabled={loading}
        >
          {loading ? (
            <img
              src="https://i.gifer.com/ZKZg.gif"
              alt="Loading"
              className="w-10 h-10 inline-block"
            />
          ) : (
            'Submit'
          )}
        </button>
      </div>
      {/*
      <div className='Sec-3 lg:px-20 lg:py-10 px-5'>
        <div className=''>
          <p className='text-xl font-bold'>Project Phases</p>
        </div>
        <div className={`w-[100%] p-4  rounded-md shadow-md  ${theme === "dark"
            ? "bg-[#151515] text-white border border-[#2b2b2b]"
            : "bg- text-black border"
          }`}>
          <div className='flex flex-col gap-10'>
            <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between items-center'>
              <div className='lg:w-[50%] flex justify-center gap-5'>
                <div className='w-[50%] flex flex-col gap-1'>
                  <p className='text-sm'>Name  *</p>
                  <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                      ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                      : "bg-white text-black border"
                    }`}></input>
                </div>
                <div className='w-[50%] flex flex-col gap-1'>
                  <p className='text-sm'>Opening Date *</p>
                  <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                      ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                      : "bg-white text-black border"
                    }`}></input>
                </div>
              </div>
              <div className='lg:w-[50%] flex justify-end'>
                <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Submit</button>

              </div>
            </div>
            <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between'>
              <div className='lg:w-[30%] flex flex-col gap-1'>
                <p className='text-sm'>Project Cover Image Page  *</p>
                <input type='file' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></input>
              </div>
              <div className='lg:w-[30%] flex flex-col gap-1'>
                <p className='text-sm'>Project Video  *</p>
                <input placeholder='URl' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></input>
              </div>
              <div className='lg:w-[30%] flex flex-col gap-1'>
                <p className='text-sm'>Project Location  *</p>
                <input placeholder='Insert Google Map URL' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></input>
              </div>
            </div>
            <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-around items-center'>
              <div className='lg:w-[30%]'>
                <img src='/assets/Others/Create-nft-building.svg'></img>
              </div>
              <div className='lg:w-[30%]'>
                <img src='/assets/Others/Video-2.svg'></img>
              </div>
              <div className='lg:w-[30%]'>
                <img src='/assets/Others/world-map 2.svg'></img>
              </div>
            </div>
            <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between'>
              <div className='lg:w-[30%] flex flex-col gap-1'>
                <p className='text-sm'>Project Full Address  *</p>
                <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></input>
              </div>
              <div className='lg:w-[30%] flex flex-col gap-1'>
                <p className='text-sm'>Promotors Company  *</p>
                <input placeholder='URl' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></input>
              </div>
              <div className='lg:w-[30%] flex flex-col gap-1'>
                <p className='text-sm'>City  *</p>
                <input placeholder='Insert Google Map URL' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></input>
              </div>
            </div>
            <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between lg:items-end'>
              <div className='lg:w-[60%] '>

                <p className='text-sm'>Project Descrpition  *</p>
                <textarea className={`w-[100%] max-h-[150px] overflow-auto outline-none px-3 py-1  rounded-md shadow-md  ${theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                  }`}></textarea>
              </div>
              <div className='lg:w-[40%]'>
                <div className='flex justify-center lg:justify-end gap-5'>
                  <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Reset</button>
                  <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
    </div>
  )
}