import React from 'react';
export default function Orderbook() {
    // Dummy data for red (sell orders)
    const redOrders = [
        { price: "95303.2", amount: "0.05678", total: "4.093227" },
        { price: "95310.5", amount: "0.04567", total: "3.202830" },
        { price: "95315.7", amount: "0.03984", total: "2.943104" },
        { price: "95320.0", amount: "0.05000", total: "3.800000" },
        { price: "95325.4", amount: "0.04830", total: "3.684209" },
        { price: "95330.6", amount: "0.04450", total: "3.424700" },
        { price: "95335.9", amount: "0.04000", total: "3.200000" },
    ];
    // Dummy data for green (buy orders)
    const greenOrders = [
        { price: "95296.5", amount: "0.07000", total: "4.970000" },
        { price: "95290.2", amount: "0.06850", total: "4.911700" },
        { price: "95285.0", amount: "0.06540", total: "4.835100" },
        { price: "95280.8", amount: "0.06000", total: "4.512000" },
        { price: "95275.6", amount: "0.05530", total: "4.200315" },
        { price: "95270.4", amount: "0.05200", total: "3.958400" },
        { price: "95265.0", amount: "0.04900", total: "3.786850" },
    ];
    return (
        <div className="w-[100%] lg:w-1/4 p-4 bg-[#0F0F0F] rounded">
            <h2 className="text-sm font-semibold text-white mb-4">Order Book</h2>
            <div className="text-xs font-medium flex flex-col md:flex-row lg:flex-col md:gap-5 lg:gap-0">
                <div className='w-[100%] md:w-[50%] lg:w-[100%]'>
                    <div className="flex justify-between mb-2 text-gray-400">
                        <span>Price (USDT)</span>
                        <span>Amount (BTC)</span>
                        <span>Total (BTC)</span>
                    </div>
                    {redOrders.map((order, index) => (
                        <div key={`red-${index}`} className="Content-red flex justify-between mb-2">
                            <span className="text-[#F7525F]">{order.price}</span>
                            <span>{order.amount}</span>
                            <span>{order.total}</span>
                        </div>
                    ))}
                </div>
                <div className='w-[100%] md:w-[50%] lg:w-[100%]'>
                    <div className="flex items-center my-4 md:my-0 lg:my-4">
                        <h1 className="font-bold text-sm text-white">95296.5 </h1>
                        <h1 className="text-gray-400 ml-2">~ 95334.61 USD</h1>
                    </div>
                    {greenOrders.map((order, index) => (
                        <div key={`green-${index}`} className="Content-green flex justify-between mb-2">
                            <span className="text-[#00BA80]">{order.price}</span>
                            <span>{order.amount}</span>
                            <span>{order.total}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
