import { faSun, faMoon, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useWeb3ModalAccount, useDisconnect } from "@web3modal/ethers5/react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { SERVER_URL } from "../../config";
import { useNavigate, useLocation } from "react-router-dom"; // For navigation
import { logout, setWalletChecked, setUserInfo, setButtonDisabled } from "../../store";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";



export default function Header({ theme, toggleTheme }) {
  const [isDropdown, setDropdown] = useState(false);

  const [walletAddress, setWalletAddress] = useState(null); // Store connected wallet address
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const [avatar, setAvatar] = useState(null);
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);
  const avatarRef = useRef(null);
  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [userAddress, setUserAddress] = useState(null);
  const [walletCheck, setWalletCheck] = useState(false);
  const [KYCstatus, setKYCstatus] = useState(false);
  const [KYCmessage, setKYCmessage] = useState(null);


  const isWalletChecked = useSelector((state) => state.user.isWalletChecked);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { disconnect } = useDisconnect();

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isProfileRestricted = !user.username;

  const handleWalletButtonClick = (event) => {
    if (!user.username) {
      event.preventDefault();
      toast.error("Please login to connect your wallet");
      setTimeout(() => {
        window.location.href = '/login';
      }, 2000);
      return;
    }
  };

  useEffect(() => {
    if (isConnected && address) {
      setWalletAddress(address);
    } else {
      setWalletAddress(null);
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (isConnected && address) {
      const checkWallet = async () => {
        try {
          const response = await axios.get(`${SERVER_URL}/api/user/checkWallet`, {
            params: { username: user.username },
          });

          if (response.data.walletAddress) {
            setUserAddress(response.data.walletAddress)
            if (response.data.walletAddress === address) {
              dispatch(setWalletChecked(true));
              dispatch(setButtonDisabled(false));
            } else if (!isWalletChecked) {
              dispatch(setButtonDisabled(true));
              toast.error("The connected wallet is different from the stored wallet.");
              dispatch(setWalletChecked(true));
            }
          } else {
            console.log("New wallet detected, prompting confirmation.");
            setShowConfirmation(true);
            dispatch(setWalletChecked(false));
          }
        } catch (error) {
          console.error("Error checking wallet:", error);
        }
      };

      checkWallet();
    }
  }, [isConnected, address, user.username, isWalletChecked, SERVER_URL, dispatch]);

  const handleConfirmPrimaryWallet = async (isConfirmed) => {
    if (isConfirmed) {
      try {
        const response = await axios.post(`${SERVER_URL}/api/user/setPrimaryWallet`, {
          walletAddress: address,
          username: user.username,
        });

        if (response.data.message === "Wallet address successfully updated") {
          setShowConfirmation(false);
          toast.success("Wallet address has been successfully stored.");
          dispatch(setWalletChecked(true));

        } else {
          toast.error("Failed to store wallet address.");
        }
      } catch (error) {
        console.error("Error storing wallet address:", error);
        toast.error("An error occurred while storing the wallet address.");
      }
    }
    else {
      setShowConfirmation(false);
    }
  };

  useEffect(() => {
    if (userAddress !== null && userAddress !== '' && address !== null) {
      if (userAddress !== address) {
        setWalletCheck(true)
      } else {
        setWalletCheck(false)
      }
    }
  }, [userAddress, address])

  useEffect(() => {
    if (user?.username) {
      fetchAvatar();
      checkKYCstatus()
    }
  }, [user?.username]);

  const fetchAvatar = async () => {
    if (!user?.username) return;
    try {
      const response = await axios.get(`${SERVER_URL}/api/user/avatar`, {
        params: { username: user.username },
      });
      setAvatar(response.data.avatar);
    } catch (error) {
      console.error("Error fetching avatar:", error);
      setAvatar(null);
    }
  };

  useEffect(() => {
    const path = location.pathname;

    if (path === "/") {
      setActiveLink("marketplace");
    } else if (path === "/profile") {
      setActiveLink("profile");
    }
    else if (path === "/trade") {
      setActiveLink("Exchange");
    }
    else if (path === "/createnft") {
      setActiveLink("createnft");
    }
  }, [location.pathname]);

  const disconnectWallet = () => {
    if (isConnected === true) {
      disconnect();
    }
  };

  const handleLogout = async () => {
    disconnectWallet();
    window.localStorage.clear();
    dispatch(logout());
    setWalletAddress(null);
    dispatch(setWalletChecked(false));
    dispatch(setButtonDisabled(false));
    localStorage.setItem("isWalletConnected", "false");
    navigate("/login");
  };

  const toggleLogout = () => {
    if (user?.username) {
      setIsLogoutVisible((prev) => !prev);
    }
  };

  const handleClickOutside = (event) => {
    if (
      isDropdown &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setDropdown(false);
    }
  };

  const handleDropdown = () => {
    setDropdown(!isDropdown);
  };

  const checkKYCstatus = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/checkkyc?username=${user.username}`);
      if (response.status === 200) {
        let hasKyc = response.data.hasKyc;
        setKYCstatus(hasKyc);
        setKYCmessage(response.data.status)
      } else {
        setKYCstatus(false);
        setKYCmessage("pending");
      }
    } catch (error) {
      console.error("Error during fetching the KYC data :", error);
      setKYCstatus(false);
      setKYCmessage("pending");
    }
  }

  const handleprofileClick = (link) => {
    if (link === "profile") {
      if (!user.username) {
        toast.error("Please login first");
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
        return;
      } else {
        navigate("/profile");
      }
    }
    setActiveLink(link);
  };

  const handleCreateNFTClick = (link) => {
    if (link === "createnft" || link === 'Exchange') {
      if (!user.username) {
        toast.error("Please login first");
        setTimeout(() => {
          window.location.href = '/login';
        }, 1000);
        return;
      } else if (KYCstatus === false) {
        toast.error("Update Your KYC ");
        setTimeout(() => {
          window.location.href = '/kycverify';
        }, 1000);
        return;
      } else {
        if (link === 'createnft') {
          navigate("/createnft");
        } else if (link === 'Exchange') {
          navigate("/trade");
        }
      }
    }
    setActiveLink(link);
  };

  return (
    <div>
      <Toaster />
      <div className="flex justify-between items-center w-[90%] mx-auto py-5">
        <div>
          <img
            src={`/assets/Logo/${theme === "light" ? "Ozonelight.svg" : "Ozonedark.svg"}`}
            alt="Logo"
          />
        </div>
        <div className="hidden lg:block">
          <div className="flex gap-10 items-center font-medium justify-end">

            <a
              href="/"
              className={`relative ${activeLink === "marketplace" ? "text-green-500" : ""}`}
              onClick={() => setActiveLink("marketplace")}
            >
              Launchpad
              {activeLink === "marketplace" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>

            <a
              className={`relative cursor-pointer ${activeLink === "Exchange" ? "text-green-500" : ""}`}
              onClick={(e) => { handleCreateNFTClick("Exchange"); }}
            >
              Exchange
              {activeLink === "Exchange" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>

            <a
              className={`relative cursor-pointer ${activeLink === "createnft" ? "text-green-500" : ""}`}
              onClick={(e) => { handleCreateNFTClick("createnft"); }}
            >
              Create NFT
              {activeLink === "createnft" && <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>}
            </a>

            <a
              className={`relative cursor-pointer ${activeLink === "profile" ? "text-green-500" : ""}`}
              onClick={(e) => { handleprofileClick("profile"); }}
            >
              Profile
              {activeLink === "profile" && (
                <div className="absolute bottom-0 left-0 w-full h-1 transition-all ease-in-out duration-300"></div>
              )}
            </a>

            {user.username ? (
              <w3m-button />
            ) : (
              <button
                onClick={(event) => handleWalletButtonClick(event)}
                className="px-4 py-2 bg-[#019444] text-white font-bold rounded-full transition duration-300"
              >
                Connect Wallet
              </button>
            )}

            {walletCheck && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg text-center relative">
                  <button
                    onClick={() => setWalletCheck(!walletCheck)}
                    className="absolute top-2 right-3 text-2xl font-bold text-gray-600 hover:text-gray-800">
                    ×
                  </button>
                  <h2 className="text-lg font-semibold mb-4">
                    Connect the Correct Wallet Address
                  </h2>
                  <p className="text-gray-600 mb-6">
                    Please connect the correct wallet address to use all the features. The current wallet ({address}) may not be suitable.
                  </p>
                </div>
              </div>
            )}

            {showConfirmation && (
              <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                  <h2 className="text-lg font-semibold mb-4">
                    Set Wallet as Primary
                  </h2>
                  <p className="text-gray-600 mb-6">
                    This wallet ({walletAddress}) will be set as your primary wallet. Are you sure you want to proceed?
                  </p>
                  <div className="flex justify-center gap-4">
                    <button onClick={() => handleConfirmPrimaryWallet(true)}
                      className="bg-green-500 text-white px-4 py-2 rounded-lg">
                      Yes
                    </button>
                    <button
                      onClick={() => handleConfirmPrimaryWallet(false)}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg">
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}

            <div className="relative">
              <div
                className="cursor-pointer"
                ref={avatarRef}
                onClick={toggleLogout}
              >
                {avatar ? (
                  <img
                    src={
                      avatar.includes("/avatars/")
                        ? `${SERVER_URL}${avatar}`
                        : avatar
                    }
                    alt="User Avatar"
                    className="w-10 h-10 rounded-full object-cover"
                  />
                ) : (
                  <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg" />
                )}
              </div>


              {/* Logout dropdown */}
              {isLogoutVisible && (
                <div
                  className={`cursor-pointer absolute top-12 right-0 bg-white text-gray-700 shadow-lg rounded-lg px-4 py-2 ${theme === "light" ? "bg-white" : "bg-[#151515] border-[#2B2B2B]"} z-50`}
                  onClick={handleLogout}
                >
                  Logout
                </div>

              )}
            </div>

            <button onClick={toggleTheme}>
              <FontAwesomeIcon
                icon={theme === "light" ? faSun : faMoon}
                className="text-gray-400"
              />
            </button>

          </div>
        </div>
        <div className="block lg:hidden">
          <div className="flex items-center justify-end gap-3">
            <FontAwesomeIcon icon={faUser} className="text-gray-400 text-lg" />
            <FontAwesomeIcon
              onClick={toggleTheme}
              icon={theme === "light" ? faSun : faMoon}
              className="text-gray-400 text-lg"
            />
            <button className="" onClick={handleDropdown} ref={buttonRef}>
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isDropdown && (
        <div
          className="absolute right-3 z-30"
          data-aos="flip-right"
          data-aos-duration="1000"
          ref={dropdownRef}
        >
          <nav
            className={`flex flex-col gap-1.5 text-center border shadow-md rounded-xl p-3 font-semibold ${theme === "light" ? "bg-white" : "bg-[#151515] border-[#2B2B2B]"
              }`}
          >
            <a href="#">Buy Now</a>
            <a href="#">Marketplace</a>
            <a href="#">Profile</a>
            <a href="#">Auction</a>
            {/* <button onClick={connectWallet} className="font-semibold text-gray-700">
              {walletAddress
                ? `Wallet: ${walletAddress.slice(0, 6)}...${walletAddress.slice(-4)}`
                : "Connect Wallet"}
            </button> */}
          </nav>
        </div>
      )}
    </div>
  );
}