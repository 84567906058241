import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

export default function Sidebar({ theme, active, setActive }) {
    const [Sidebar, setSidebar] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleSidebar = () => {
        setSidebar(!Sidebar);
    }
    const handleClickOutside = (event) => {
        if (
            (Sidebar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setSidebar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [Sidebar]);

    const Sidebarnav = [
        { id: 'div1', label: 'Analytics' },
        { id: 'div2', label: 'Users' },
        { id: 'div3', label: 'KYC Lists' },
        { id: 'div4', label: 'Mint Requests' },
        { id: 'div5', label: 'Transaction History' },
        { id: 'div6', label: 'Mint History' },
        { id: 'div7', label: 'Platform Categories' },
        { id: 'div8', label: 'Platform Fees' },
    ]

    return (
        <div>
            <div className={`hidden lg:block w-[100%] p-5 ${theme === 'dark' ? ' text-white ' : ' text-black'}`}>
                <ul className="space-y-2 font-medium ">
                    {Sidebarnav.map((item) => (
                        <button
                            key={item.id}
                            className={`w-full shadow rounded-md ${active === item.id ?
                                'bg-secondary text-white shadow-black/30' :
                                theme === 'dark' ? 'bg-[#151515]' : 'bg-[#D9D9D9] shadow-black/30 '}`}
                            onClick={() => setActive(item.id)}
                        >
                            <div className="py-3 2xl:py-5 rounded-md">{item.label}</div>
                        </button>
                    ))}
                </ul>
            </div>
            <div className={`block lg:hidden ${theme === 'dark' ? 'text-white' : ''}`}>
                <FontAwesomeIcon icon={faBars} className='py-3 px-5 text-xl' onClick={handleSidebar} ref={buttonRef} />
            </div>
            {Sidebar && (
                <div className={`absolute left-3 top-8 z-50 px-5 py-3 w-[90%] md:w-[50%] rounded-md ${theme === 'dark' ? 'bg-[#151515] ' : 'bg-[#D9D9D9]'}`}
                    data-aos='fade-right'
                    data-aos-duration='1000'
                    ref={dropdownRef}
                >
                    <ul class="space-y-4  font-semibold">
                        {Sidebarnav.map((item) => (
                            <button
                                key={item.id}
                                className={`w-full shadow rounded-md ${active === item.id ? 'bg-secondary text-white' : theme === 'dark' ? 'bg-black/50 ' : 'bg-white '}`} onClick={() => {setActive(item.id); setSidebar(false)}}>
                                <div className="py-3 rounded-md">{item.label}</div>
                            </button>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}
