import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import axios from 'axios';
import { SERVER_URL } from '../../../config';
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

export default function KycDetails({ theme, handleKYCDetails, UpdateKYCDetails, entity }) {

    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [currentImage, setCurrentImage] = useState(null);
    const [currentEntity, setCurrentEntity] = useState(entity);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const closeModal = () => {
        setCurrentImage(null);
    };

    const handleStatusChange = async (username, status, reason = null) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/updatekycstatus`, {
                username,
                status,
                reason,
            });

            if (response.data.status === "Success") {
                toast.success(`KYC ${status} successfully.`);
                setIsRejectionModalOpen(false);
                UpdateKYCDetails();
                setCurrentEntity((prev) => ({ ...prev, status, reason }));
            } else {
                toast.error(`Error: ${response.data.message}`);
            }
        } catch (error) {
            console.error("Error updating status:", error);
            toast.error("Error updating status.");
        }
    };

    return (
        <div className='fixed inset-0 bg-black/70 flex justify-center md:items-center py-3 lg:py-0'>
            <Toaster />
            <div className={`w-[90%] md:w-[75%] py-5 px-7 2xl:px-10 lg:w-[50%] rounded-xl overflow-y-auto ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'}`}>
                <div className='flex justify-end'>
                    <button onClick={() => handleKYCDetails(null)}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                </div>
                <h2 className="text-xl text-center font-medium">KYC Details</h2>

                <div className="grid lg:grid-cols-3 gap-5 mt-4">
                    <div className="lg:col-span-2 grid content-center text-sm space-y-2">
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Username:</span> {currentEntity.username}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">First Name:</span> {currentEntity.firstname}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Last Name:</span> {currentEntity.lastname}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Address:</span> {currentEntity.address}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">City:</span>  {currentEntity.city}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Country:</span> {currentEntity.country}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Date of Birth:</span>  {formatDate(currentEntity.dob)}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Document Type:</span> {currentEntity.doc_type}
                        </p>
                        <p className='md:flex'>
                            <span className="font-medium text-[#626262] md:w-[50%]">Document Expiry Date:</span> {formatDate(currentEntity.expiry)}
                        </p>
                    </div>

                    <div className="flex flex-wrap lg:flex-col gap-3 justify-center">
                        <div className={`flex flex-col items-center p-1 rounded-md ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`} onClick={() => setCurrentImage(currentEntity.picture1)}>
                            <img
                                src={`data:image/png;base64,${currentEntity.picture1}`}
                                alt="Document Front Page"
                                className="rounded-lg w-20 2xl:w-24"
                                target="_blank"
                            />
                            <p className="mt-2 text-xs">Document Front Page</p>
                        </div>
                        <div className={`flex flex-col items-center p-1 rounded-md ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`} onClick={() => setCurrentImage(currentEntity.picture2)}>
                            <img
                                src={`data:image/png;base64,${currentEntity.picture2}`}
                                alt="Document Back Page"
                                className="rounded-lg w-20 2xl:w-24"
                            />
                            <p className="mt-2 text-xs">Document Back Page</p>
                        </div>
                        <div className={`flex flex-col items-center p-1 rounded-md ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`} onClick={() => setCurrentImage(currentEntity.picture3)}>
                            <img
                                src={`data:image/png;base64,${currentEntity.picture3}`}
                                alt="Document Selfie Page"
                                className="rounded-lg w-20 2xl:w-24"
                            />
                            <p className="mt-2 text-xs">Document Selfie Page</p>
                        </div>
                    </div>
                    {currentImage && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 mt-[0px]">
                            <div className="relative  ">
                                <img
                                    src={`data:image/png;base64,${currentImage}`}
                                    alt="Zoomed-in Image"
                                    className="rounded-lg max-w-[85%] mx-auto max-h-screen"
                                />
                                <button
                                    onClick={closeModal}
                                    className="absolute -top-8 right-8 bg-white text-black rounded-full font-bold w-8 h-8 "
                                >
                                    X
                                </button>
                                {/* <button onClick={closeModal}><FontAwesomeIcon icon={faXmarkCircle} className='absolute top-0 right-0 z-60'/></button> */}
                            </div>
                        </div>
                    )}
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    {currentEntity.status === "pending" && (
                        <>
                            <button
                                className={`bg-secondary text-white font-medium py-1 px-4 rounded-md ${currentEntity.status !== "pending" ? " cursor-not-allowed" : ""}`}
                                disabled={currentEntity.status !== "pending"}
                                onClick={() => handleStatusChange(currentEntity.username, 'accepted')}
                            >
                                Approve
                            </button>
                            <button
                                className={`bg-[#F7525F] text-white font-medium py-1 px-4 rounded-md ${currentEntity.status !== "pending" ? " cursor-not-allowed" : ""}`}
                                disabled={currentEntity.status !== "pending"}
                                onClick={() => setIsRejectionModalOpen(true)}
                            >
                                Reject
                            </button>
                        </>
                    )}

                    {/* Show "Approved" button if status is "accepted" */}
                    {currentEntity.status === "accepted" && (
                        <button
                            className="bg-secondary text-white font-medium py-1 px-4 rounded-md cursor-not-allowed"

                        >
                            Approved
                        </button>
                    )}

                    {/* Show "Rejected" button with reason if status is "rejected" */}
                    {currentEntity.status === "rejected" && (
                        <>
                            <button
                                className="bg-[#F7525F] text-white font-medium py-1 px-4 rounded-md cursor-not-allowed"
                                disabled
                            >
                                Rejected
                            </button>
                            {/* Optionally, display the reason for rejection */}
                            <div className="text-sm text-gray-500 mt-2">
                                <strong>Reason:</strong> {currentEntity.reason || "No reason provided"}
                            </div>
                        </>
                    )}
                </div>

                {/* Rejection Modal */}
                {isRejectionModalOpen && (
                    <div className="fixed inset-0 bg-black/50 flex justify-center items-center">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-[90%] md:w-[50%] lg:w-[40%]">
                            <h3 className="text-lg font-medium mb-4">Reason for Rejection</h3>
                            <textarea
                                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:ring"
                                rows="4"
                                placeholder="Enter rejection reason"
                                value={rejectionReason}
                                onChange={(e) => setRejectionReason(e.target.value)}
                            ></textarea>
                            <div className="flex justify-end gap-4 mt-4">
                                <button
                                    className="bg-gray-300 text-black px-4 py-2 rounded-md"
                                    onClick={() => setIsRejectionModalOpen(false)}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                                    onClick={() => {
                                        handleStatusChange(currentEntity.username, 'rejected', rejectionReason);
                                        setIsRejectionModalOpen(false);
                                    }}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

