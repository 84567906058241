import { faArrowUpFromBracket, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { toast, Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import { QRCodeCanvas } from 'qrcode.react'; // Use named import
import { SERVER_URL } from '../../../../config';

export default function FooterNFT({ theme }) {
    const user = useSelector((state) => state.user.username);
    const [address, setAddress] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [receiveNFT, setReceviveNFT] = useState(false);

    const handleNFT = () => {
        setReceviveNFT(!receiveNFT);
    };

    const handleCopy = () => {
        if (address) {
            navigator.clipboard
                .writeText(address)
                .then(() => {
                    toast.success('Address copied to clipboard!');
                })
                .catch((err) => {
                    console.error('Failed to copy text: ', err);
                    toast.error('Failed to copy address.');
                });
        } else {
            toast.error('No address to copy.');
        }
    };

    useEffect(() => {

        if (user) {
            const fetchAddress = async () => {
                try {
                    const response = await fetch(`${SERVER_URL}/api/getUserAddress?username=${user}`);
                    if (!response.ok) {
                        throw new Error('User address not found');
                    }

                    const data = await response.json();

                    setAddress(data.address);
                    setLoading(false);
                } catch (err) {
                    setError(err.message);
                    setLoading(false);
                }
            };

            fetchAddress();
        }
    }, [user]);

    return (
        <div>
            <Toaster />
            <div className='flex justify-end items-center'>
                <div className='w-[100%] lg:w-[75%] flex flex-col md:flex-row gap-3 items-center justify-between my-3 mx-3 lg:my-2 lg:mx-0'>
                    <button className='bg-secondary rounded-md px-10 py-1 font-medium shadow-md' onClick={handleNFT}>Received NFT</button>
                    {/* <div className={`flex gap-5 font-medium items-center text-center rounded-lg px-7 py-1 shadow border ${theme === 'dark' ? 'bg-[#2b2b2b] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div>
                            <h2 className={` text-sm ${theme === 'dark' ? 'text-white/50' : ''}`}>Investment</h2>
                            <h1 className='font-bold text-3xl'>$ 500</h1>
                        </div>
                        <div>
                            <h2 className={` text-sm ${theme === 'dark' ? 'text-white/50' : ''}`}>Current Value</h2>
                            <h1 className='font-bold text-3xl'>$ 1500</h1>
                        </div>
                    </div> */}
                </div>
            </div>
            {receiveNFT && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/80 " data-aos='fade-in'>
                    <div className="w-[90%] md:w-[35%] rounded-lg bg-[#151515] p-5">
                        <div className='flex justify-end'>
                            <button onClick={handleNFT}><FontAwesomeIcon icon={faXmarkCircle} className='text-white/60' /></button>
                        </div>
                        <div>
                            {/* <QRCodeCanvas value={address} className='w-40 mx-auto rounded-lg' /> */}
                            <QRCodeCanvas
                                value={`ethereum:${address}`}
                                size={250}
                                errorLevel="H"
                                bgColor="#ffffff"
                                fgColor="#000000"
                                level="H"
                                includeMargin={true} // Include margin for better scanning
                                className='w-full mx-auto rounded-lg'
                                style={{ maxWidth: '100%' }} // Ensure the QR code fits the container properly
                            />

                            <h1 className='text-center text-white font-medium mt-2 2xl:text-lg'>{address}</h1>
                            <div className='flex justify-center items-center gap-3 mt-2 relative'>
                                <button className='px-10 rounded-lg bg-secondary text-white font-medium 2xl:text-lg' onClick={handleCopy}>Copy</button>
                                <FontAwesomeIcon icon={faArrowUpFromBracket} className='absolute right-16 md:right-32 2xl:right-44 text-white/50 cursor-pointer' onClick={handleCopy} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
