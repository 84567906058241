import { faStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
export default function Sidebar() {
    const NFT = [
        { name: "WBTC", secname: "USDT", price: 96455, percent: 0.1 },
        { name: "ETH", secname: "USDT", price: 1875, percent: -0.3 },
        { name: "BNB", secname: "USDT", price: 245, percent: 1.2 },
        { name: "SOL", secname: "USDT", price: 23.5, percent: -0.8 },
        { name: "ADA", secname: "USDT", price: 0.256, percent: 2.5 },
    ];
    return (
        <div className='w-[100%] lg:w-1/4 space-y-1 flex flex-col md:flex-row lg:flex-col md:gap-3 lg:gap-0'>
            <div className="w-[100%] md:w-[50%] lg:w-[100%] py-4 px-2 bg-[#222223] rounded">
                <div className="text-sm font-medium ">
                    {NFT.map((market, index) => (
                        <div
                            key={index}
                            className="flex justify-between p-2 rounded hover:bg-[#0F0F0F]"
                        >
                            <div className='flex items-center gap-1'>
                                <FontAwesomeIcon icon={faStar} className='text-[10px]' />
                                <span>{market.name}<span className='text-gray-400'>/{market.secname}</span></span>
                            </div>
                            <span>{market.price}</span>
                            <span className={` ${market.percent >= 0 ? ' text-[#00BA80]' : 'text-[#F7525F]'}`}>{market.percent}%</span>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-[100%] md:w-[50%] lg:w-[100%] bg-[#222223] text-white py-2 px-4 rounded-lg shadow-lg">
                <div className="flex justify-between mb-4 border-b border-gray-700">
                    <button className="text-white pb-2 border-b-2 border-green-500">Limit</button>
                    <button className="text-gray-400 pb-2">Market</button>
                </div>
                <div className="grid grid-cols-2 gap-4 font-medium">
                    <div className='space-y-2'>
                        <div className="flex items-center justify-between gap-3 border border-gray-700 rounded-lg px-2 py-2 ">
                            <h1 className="text-gray-400 text-sm">Price</h1>
                            <span className="text-white truncate">90000.00 USDT</span>
                        </div>
                        <div className="flex items-center justify-between border border-gray-700 rounded-lg px-2 py-2 ">
                            <h1 className="text-gray-400 text-sm">Amount</h1>
                            <span className="text-white">BTC</span>
                        </div>
                    </div>
                    <div className='space-y-2'>
                        <div className="flex items-center justify-between gap-3 border border-gray-700 rounded-lg px-2 py-2 ">
                            <h1 className="text-gray-400 text-sm">Price</h1>
                            <span className="text-white truncate">90000.00 USDT</span>
                        </div>
                        <div className="flex items-center justify-between border border-gray-700 rounded-lg px-2 py-2 ">
                            <h1 className="text-gray-400 text-sm">Amount</h1>
                            <span className="text-white">BTC</span>
                        </div>
                    </div>
                </div>
                <div className='flex justify-around mt-1'>
                    <img src='/assets/Others/border.svg' className='w-32 2xl:w-auto'></img>
                    <img src='/assets/Others/border.svg' className='w-32 2xl:w-auto'></img>
                </div>
                {/* TP/SL Checkbox */}
                <div className="flex items-center mt-4">
                    <input
                        type="checkbox"
                        className="form-checkbox text-green-500  border-gray-700 rounded focus:ring-0"
                    />
                    <label className="text-sm ml-2">TP/SL</label>
                </div>
                <div className="text-sm text-gray-500 mt-1 font-semibold w-[49%] px-1">
                    <p className='flex justify-between'><span>Avbl</span><span className='text-white'>-USDT</span></p>
                    <p className='flex justify-between'><span>Avbl</span><span className='text-white'>--BTC</span></p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-1 font-semibold text-sm">
                    <button className="text-white py-1 rounded-lg bg-secondary">
                        BUY
                    </button>
                    <button className="text-white py-1 rounded-lg bg-[#F7525F]">
                        SELL
                    </button>
                </div>
            </div>
        </div>
    )
}