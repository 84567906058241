import React, { useState, useEffect } from 'react'
import Header from '../Layout/Header';
import { fa0, faLocationDot, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select'
import Footer from '../Layout/Footer';
import { SERVER_URL } from '../../config';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Marketplace() {
    const [theme, setTheme] = useState('light');
    const navigate = useNavigate();
    const [selectedType, setSelectedType] = useState(null);
    const [filterType, setFilterType] = useState('all');
    const [category, setCategory] = useState('all');

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const collections = [
        { photo: 'Gold.png', rank: 1, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 2, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 3, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 4, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 5, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 6, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 7, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 8, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 9, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 10, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
    ];

    const [realEstateNfts, setRealEstateNfts] = useState([]);
    const [artNfts, setArtNfts] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categorizedData, setCategorizedData] = useState([]);

    const fetchNFTs = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getListedNfts`);
            console.log(response, 'response')
            if (response.data.status === 'success') {
                setCategories(response.data.categories);
                setCategorizedData(response.data.data);
                setResultData(response.data.combinedData);
                setCombinedData(response.data.combinedData);
            }
        } catch (error) {
            console.error('Error fetching NFTs:', error);
        }
    };

    useEffect(() => {
        fetchNFTs()
    }, [])

    const handleCategoryChange = (selectedOption) => {
        console.log(selectedOption.value);
        if (selectedOption.value === "all") {
            // Combine all categories into a single array
            const allData = Object.values(categorizedData).flat(); // Flatten all arrays in the object
            setResultData(allData);
        } else {
            setResultData(categorizedData[selectedOption.value] || []);
        }
    };

    const handleSortChange = (selectedOption) => {
        const sortedData = [...resultData].sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);

            if (selectedOption.value === "ascending") {
                return dateA - dateB;
            } else {
                return dateB - dateA;
            }
        });

        setResultData(sortedData);
    };

    const clickProperty = async (data) => {
        console.log(data)
        if (data.listing_type === "fixedprice") {
            navigate('/FixedNft/setState=relative', { state: data })
        }
        if (data.listing_type === "auction") {
            navigate('/AuctionNft/setState=relative', { state: data })
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const firstHalf = collections.slice(0, 5);
    const secondHalf = collections.slice(5, 10);

    const filters = [
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building5.png', wealthtype: 'Platinum', assettype: 'Platinum 100kg', status: 'Available' },
    ]

    // const category = [
    //     { value: 'all', label: 'All' },
    //     { value: 'realestate', label: 'Real Estate' },
    //     { value: 'art', label: 'Art' }
    // ]

    const sortBy = [
        { value: 'ascending', label: 'Ascending' },
        { value: 'decending', label: 'Decending' },
    ]

    const NFT101 = [
        { photo: 'NFT1.png', question: 'What is an NFT?' },
        { photo: 'NFT2.png', question: 'How to buy an NFT' },
        { photo: 'NFT3.png', question: 'How to create an NFT on OzoneX platform?' },
        { photo: 'NFT1.png', question: 'How to sell an NFT using OzoneX platform?' }
    ]

    const CustomStyle = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            boxShadow: '0px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'black',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isFocused ? '#d3d3d3' : 'white',
            color: '#000', // Color of the option text
            ':active': {
                backgroundColor: '#d3d3d3', // Light gray when clicked
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            background: '#fff', // Color of the typed input text
        }),
    };

    function formatDateToReadable(dateString) {
        if (!dateString) return "";

        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
            console.error("Invalid date:", dateString);
            return "";
        }
        return date.toLocaleDateString("en-US", options);
    }

    const filteredRealEstateNfts = realEstateNfts?.filter(item =>
        selectedType === null ? true : selectedType === 'single' ? item.asset.type === 'nft' : item.asset.type === 'fnft'
    );

    const filteredArtNfts = artNfts?.filter(item =>
        selectedType === null ? true : selectedType === 'single' ? item.asset.type === 'nft' : item.asset.type === 'fnft'
    );

    const filteredResultData = resultData?.filter(item =>
        selectedType === null ? true : selectedType === 'nft' ? item.asset.type === 'nft' : item.asset.type === 'fnft'
    );

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        if (name === 'type') {
            setFilterType(value);
        } else if (name === 'category') {
            setCategory(value);
        }
    };

    const filteredData = combinedData?.filter(item => {
        const matchesType = filterType === 'all' || item.listing_type === filterType;
        const matchesCategory = category === 'all' || item.asset.category === category;
        return matchesType && matchesCategory;
    });


    return (
        <div className={`bg-contain bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
            style={{
                backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
            }}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='w-[90%] mx-auto'>
                <div className='sec-1 flex flex-col lg:flex-row items-center justify-center'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/building/cartoonbuilding.png'></img>
                    </div>
                    <div className='w-[100%] lg:w-[30%]'>
                        <h1 className='text-2xl lg:text-3xl font-bold text-center'>Launchpad</h1>
                        <div className='flex gap-5 justify-center mt-5'>
                            <button
                                onClick={() => setSelectedType('nft')}
                                className="px-3 py-1 rounded-md text-white font-bold bg-[#019444]"

                            >
                                Single NFT
                            </button>
                            <button
                                onClick={() => setSelectedType('fnft')}
                                className="px-3 py-1 rounded-md text-white font-bold bg-[#019444]"
                            >
                                Fractional NFT
                            </button>
                        </div>
                    </div>
                </div>
                <div className="sec-2 pb-10">
                    <div className="flex justify-between items-center my-5">
                        <h2 className="text-xl font-bold">Trending collections</h2>
                        <button className='px-3 py-1 bg-[#019444] rounded-md'>View all</button>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        <table className="table-auto w-full border-collapse  text-left">
                            <thead>
                                <tr className={`duration-500 border-b ${theme === 'dark' ? 'border-white/50' : 'text-gray-500 border-gray-300'}`}>
                                    <th className=" px-4 py-2">Rank</th>
                                    <th className=" px-4 py-2">Collection</th>
                                    <th className=" px-4 py-2">Floor Price</th>
                                    <th className=" px-4 py-2">Volume</th>
                                </tr>
                            </thead>
                            <tbody>
                                {firstHalf?.map((item, index) => (
                                    <tr key={index} className={`duration-500  ${theme === 'dark' ? 'hover:bg-[#151515]' : 'hover:bg-gray-100'}`}>
                                        <td className=" px-4 py-2 text-center">{item.rank}</td>
                                        <td className=" px-4 py-2 flex items-center">
                                            <img
                                                src={`/assets/Others/${item.photo}`}
                                                alt={item.collection}
                                                className="w-10 h-10 rounded mr-3"
                                            />
                                            {item.collection}
                                        </td>
                                        <td className=" px-4 py-2 text-center">{item.floorPrice}</td>
                                        <td className=" px-4 py-2 text-center">{item.volume}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <table className="table-auto w-full border-collapse  text-left">
                            <thead>
                                <tr className={`duration-500 border-b ${theme === 'dark' ? 'border-white/50' : 'text-gray-500 border-gray-300'}`}>
                                    <th className=" px-4 py-2">Rank</th>
                                    <th className=" px-4 py-2">Collection</th>
                                    <th className=" px-4 py-2">Floor Price</th>
                                    <th className=" px-4 py-2">Volume</th>
                                </tr>
                            </thead>
                            <tbody>
                                {secondHalf?.map((item, index) => (
                                    <tr key={index} className={`duration-500  ${theme === 'dark' ? 'hover:bg-[#151515]' : 'hover:bg-gray-100'}`}>
                                        <td className=" px-4 py-2 text-center">{item.rank}</td>
                                        <td className=" px-4 py-2 flex items-center">
                                            <img
                                                src={`/assets/Others/${item.photo}`}
                                                alt={item.collection}
                                                className="w-10 h-10 rounded mr-3"
                                            />
                                            {item.collection}
                                        </td>
                                        <td className=" px-4 py-2 text-center">{item.floorPrice}</td>
                                        <td className=" px-4 py-2 text-center">{item.volume}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className='sec-3 pb-10'>
                    {categories.length > 0 && categories.map((category) => (
                        categorizedData[category]?.length > 0 && (
                            <div key={category} className="category-section mb-12">
                                <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>

                                    <h2 className="text-xl font-bold">{category.charAt(0).toUpperCase() + category.slice(1)} NFTs</h2>
                                </div>

                                <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                                    {categorizedData[category]
                                        ?.filter((item) => {

                                            if (selectedType === null) return true;
                                            return item.asset?.type === selectedType;
                                        })
                                        ?.map((item, index) => (
                                            <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                                <div className='flex justify-between font-bold'>
                                                    <div>
                                                        <h1>TOTAL PRICE</h1>
                                                        <p className='text-2xl'>{item.asset?.expense}</p>
                                                    </div>
                                                    <div>
                                                        <h1>Token Price</h1>
                                                        <p className='text-2xl'>$ {item.listing_start_price} </p>
                                                    </div>
                                                </div>
                                                <div className='mt-2 relative h-44 '>
                                                    <img className='h-full mx-auto ' src={`${item.files?.image[0]}`}></img>
                                                    <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.isSold === false ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.isSold === true ? "Sold out" : "Available"}</div>
                                                </div>
                                                <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                                    <div className='flex gap-3 items-center text-center text-black'>
                                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold capitalize'>
                                                            {item.asset?.location}
                                                        </div>
                                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                                            {item.listing_type === 'fixedprice' ? "Fixed Price" : "Auction"}
                                                        </div>
                                                    </div>
                                                    <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> {item.asset?.location}</p>
                                                </div>
                                                <div className='mt-5'>
                                                    <h1 className='font-bold text-lg'>{item.asset?.name}</h1>
                                                    <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                                        <div className='text-sm 2xl:text-base'>
                                                            <h2 className='font-bold'>Expected Income</h2>
                                                            <p className='mt-1'>Not including capital appreciation</p>
                                                        </div>
                                                        <p className='font-bold text-lg'>{item.asset?.profit}%</p>
                                                    </div>
                                                    <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                                        <h2>Income Start Date</h2>
                                                        <p>{formatDateToReadable(item.listing_start_date)}</p>
                                                    </div>
                                                    {item?.nftType === 'fnft' ?
                                                        <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                                            <h2>Fractions Available</h2>
                                                            <p>{item.fractions_to_sell ?? 0}</p>
                                                        </div>
                                                        : ''
                                                    }
                                                    <div className='flex justify-center mt-2'>
                                                        <button
                                                            className={`bg-[#019444] rounded-md px-3 py-1 ${item.isSold ? 'cursor-not-allowed' : ''}`}
                                                            onClick={() => clickProperty(item)}
                                                            disabled={item.isSold}
                                                        >
                                                            View Asset
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>


                            </div>
                        )
                    ))
                    }
                </div>
                {/* <div className='sec-3 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                        <h2 className="text-xl font-bold">Real Estate NFTs</h2>
                    </div>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                        {filteredRealEstateNfts?.map((item, index) => (
                            <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                <div className='flex justify-between font-bold'>
                                    <div>
                                        <h1>TOTAL PRICE</h1>
                                        <p className='text-2xl'>{item.asset?.expense}</p>
                                    </div>
                                    <div>
                                        <h1>Token Price</h1>
                                        <p className='text-2xl'>$ {item.listing_start_price} </p>
                                    </div>
                                </div>
                                <div className='mt-2 relative h-44 '>
                                    <img className='h-full mx-auto ' src={`${item.files?.image[0]}`}></img>
                                    <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.isSold === false ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.isSold === true ? "Sold out" : "Available"}</div>
                                </div>
                                <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                    <div className='flex gap-3 items-center text-center text-black'>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold capitalize'>
                                            {item.asset?.location}
                                        </div>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            {item.listing_type === 'fixedprice' ? "Fixed Price" : "Auction"}
                                        </div>
                                    </div>
                                    <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> {item.asset?.location}</p>
                                </div>
                                <div className='mt-5'>
                                    <h1 className='font-bold text-lg'>{item.asset?.name}</h1>
                                    <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <div className='text-sm 2xl:text-base'>
                                            <h2 className='font-bold'>Expected Income</h2>
                                            <p className='mt-1'>Not including capital appreciation</p>
                                        </div>
                                        <p className='font-bold text-lg'>{item.asset?.profit}%</p>
                                    </div>
                                    <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <h2>Income Start Date</h2>
                                        <p>{formatDateToReadable(item.listing_start_date)}</p>
                                    </div>
                                    <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                        <h2>Fractions Available</h2>
                                        <p>{item.fractions_to_sell ?? 0}</p>
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        <button
                                            className={`bg-[#019444] rounded-md px-3 py-1 ${item.isSold ? 'cursor-not-allowed' : ''}`}
                                            onClick={() => clickProperty(item)}
                                            disabled={item.isSold}
                                        >
                                            View Asset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='sec-4 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                        <h2 className="text-xl font-bold">Art</h2>
                    </div>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                        {filteredArtNfts?.map((item, index) => (
                            <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                <div className='flex justify-between font-bold'>
                                    <div>
                                        <h1>TOTAL PRICE</h1>
                                        <p className='text-2xl'>{item.asset?.expense}</p>
                                    </div>
                                    <div>
                                        <h1>Token Price</h1>
                                        <p className='text-2xl'>$ {item.listing_start_price}</p>
                                    </div>
                                </div>
                                <div className='mt-2 relative h-44'>
                                    <img className=' h-full mx-auto ' src={`${item.files?.image[0]}`}></img>
                                    <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.isSold === false ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.isSold === true ? "Sold out" : "Available"}</div>
                                </div>
                                <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                    <div className='flex gap-3 items-center text-center text-black'>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold capitalize'>
                                            {item.asset?.location}
                                        </div>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            {item.listing_type === 'fixedprice' ? "Fixed Price" : "Auction"}
                                        </div>
                                    </div>
                                    <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> {item.asset?.location}</p>
                                </div>
                                <div className='mt-5'>
                                    <h1 className='font-bold text-lg'>{item.asset?.name}</h1>
                                    <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <div className='text-sm 2xl:text-base'>
                                            <h2 className='font-bold'>Expected Income</h2>
                                            <p className='mt-1'>Not including capital appreciation</p>
                                        </div>
                                        <p className='font-bold text-lg'>{item.asset?.profit}%</p>
                                    </div>
                                    <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <h2>Income Start Date</h2>
                                        <p>{formatDateToReadable(item.listing_start_date)}</p>
                                    </div>
                                    <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                        <h2>Fractions Available</h2>
                                        <p>{item.fractions_to_sell ?? 0}</p>
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        <button
                                            className={`bg-[#019444] rounded-md px-3 py-1 ${item.isSold ? 'cursor-not-allowed' : ''}`}
                                            onClick={() => clickProperty(item)}
                                            disabled={item.isSold}
                                        >
                                            View Asset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                <div className='sec-5 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                        <div className='flex flex-col md:flex-row justify-between items-center'>
                            <div>
                                <h2 className="text-xl font-bold">Results</h2>
                            </div>
                            <div className='flex flex-col md:flex-row gap-2'>
                                <Select
                                    options={[
                                        { value: "all", label: "All" }, // Add the "All" option dynamically
                                        ...categories.map((category) => ({
                                            value: category,
                                            label: category.charAt(0).toUpperCase() + category.slice(1), // Transform here dynamically
                                        })),
                                    ]}
                                    styles={CustomStyle}
                                    onChange={handleCategoryChange}
                                    placeholder="Select Category"
                                    className="bg-[#d9d9d9] px-2 rounded-md"
                                />
                                <div className='flex gap-2 items-center justify-between bg-[#d9d9d9]  px-2 rounded-md'><span className='font-bold text-sm text-black'>Sort by :</span>
                                    <Select options={sortBy} onChange={handleSortChange}
                                        defaultValue={sortBy[0]} styles={CustomStyle} placeholder='Recently added' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10">
                        {filteredResultData?.length > 0 ? (
                            filteredResultData.map((item, index) => (
                                <div
                                    key={index}
                                    className={`px-6 py-4 rounded-2xl border ${theme === "dark"
                                        ? "bg-[#151515] border-[#2B2B2B]"
                                        : "bg-[#F8F8F8] border-[#D9D8D8]"
                                        }`}
                                >
                                    <div className="flex justify-between font-bold">
                                        <div>
                                            <h1>TOTAL PRICE</h1>
                                            <p className="text-2xl">{item.asset?.expense}</p>
                                        </div>
                                        <div>
                                            <h1>Token Price</h1>
                                            <p className="text-2xl">$ {item.listing_start_price}</p>
                                        </div>
                                    </div>
                                    <div className="mt-2 relative h-44">
                                        <img
                                            className="h-full mx-auto"
                                            src={`${item.files?.image[0]}`}
                                        />
                                        <div
                                            className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.isSold === false
                                                ? "bg-[#019444]"
                                                : "bg-[#94010A]"
                                                }`}
                                        >
                                            {item.isSold === true ? "Sold out" : "Available"}
                                        </div>
                                    </div>
                                    <div className="mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base">
                                        <div className="flex gap-3 items-center text-center text-black">
                                            <div className="bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold capitalize">
                                                {item.asset?.location}
                                            </div>
                                            <div className="bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold">
                                                {item.listing_type === "fixedprice"
                                                    ? "Fixed Price"
                                                    : "Auction"}
                                            </div>
                                        </div>
                                        <p className="truncate">
                                            <FontAwesomeIcon
                                                icon={faLocationDot}
                                                className="text-[#ff0000]"
                                            />{" "}
                                            {item.asset?.location}
                                        </p>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="font-bold text-lg">{item.asset?.name}</h1>
                                        <div
                                            className={`flex justify-between items-center border-b py-3 ${theme === "dark" ? "border-white/20" : ""
                                                }`}
                                        >
                                            <div className="text-sm 2xl:text-base">
                                                <h2 className="font-bold">Expected Income</h2>
                                                <p className="mt-1">
                                                    Not including capital appreciation
                                                </p>
                                            </div>
                                            <p className="font-bold text-lg">{item.asset?.profit}%</p>
                                        </div>
                                        <div
                                            className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === "dark" ? "border-white/20" : ""
                                                }`}
                                        >
                                            <h2>Income Start Date</h2>
                                            <p>{formatDateToReadable(item.listing_start_date)}</p>
                                        </div>
                                        {item?.nftType === 'fnft' ?
                                            <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                                <h2>Fractions Available</h2>
                                                <p>{item.fractions_to_sell ?? 0}</p>
                                            </div>
                                            : ''
                                        }
                                        <div className="flex justify-center mt-2">
                                            <button
                                                className={`bg-[#019444] rounded-md px-3 py-1 ${item.isSold ? "cursor-not-allowed" : ""
                                                    }`}
                                                onClick={() => clickProperty(item)}
                                                disabled={item.isSold}
                                            >
                                                View Asset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="col-span-full text-center">
                                <p className="text-lg font-semibold">
                                    No assets for this category.
                                </p>
                            </div>
                        )}
                    </div>

                </div>
                <div className='sec-6 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : 'border-black/20'}`}>
                        <h2 className="text-xl font-bold"><FontAwesomeIcon icon={faSliders} /> Filters</h2>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-5 md:gap-10'>
                        <div className='w-[100%] lg:w-1/3'>
                            <div className="grid md:grid-cols-2 lg:grid-cols-1 content-around gap-10 h-full w-[80%] mx-auto">
                                <div>
                                    <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                        <h3 className="text-base font-semibold mb-4">Type</h3>
                                        <div className="space-y-4">
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                    name="type"
                                                    value="all"
                                                    onChange={handleFilterChange}
                                                    checked={filterType === 'all'}
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">All</label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                    name="type"
                                                    value="fixedprice"
                                                    onChange={handleFilterChange}
                                                    checked={filterType === 'fixedprice'}
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">Buy Now</label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                    name="type"
                                                    value="auction"
                                                    onChange={handleFilterChange}
                                                    checked={filterType === 'auction'}
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">Auction</label>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                    <div className="space-y-4">

                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                value="all"
                                                name="category"
                                                onChange={handleFilterChange}
                                                checked={category === 'all'}
                                            />
                                            <label className="ml-2 text-sm font-medium text-gray-700">All</label>
                                        </div>


                                        {categories.map((cat, index) => (
                                            <div key={index} className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                    value={cat}
                                                    name="category"
                                                    onChange={handleFilterChange}
                                                    checked={category === cat}
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">{cat.charAt(0).toUpperCase() + cat.slice(1)}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div>
                                    <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                        <h3 className="text-base font-semibold mb-4">Minted</h3>
                                        <div className="space-y-4">
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                    defaultChecked
                                                />
                                                <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                    Polygon
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-[100%] lg:w-2/3'>
                            <div className='grid md:grid-cols-2 gap-5 md:gap-10'>
                                {filteredData?.map((item, index) => (
                                    <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                        <div className='flex justify-between font-bold'>
                                            <div>
                                                <h1>TOTAL PRICE</h1>
                                                <p className='text-2xl'>{item.asset?.expense}</p>
                                            </div>
                                            <div>
                                                <h1>Token Price</h1>
                                                <p className='text-2xl'>$ {item.listing_start_price}</p>
                                            </div>
                                        </div>
                                        <div className='mt-2 relative'>
                                            <img className='w-full' src={`${item.files?.image[0]}`}></img>
                                            <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.isSold === false ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.isSold === true ? "Sold out" : "Available"}</div>
                                        </div>
                                        <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                            <div className='flex gap-3 items-center text-center text-black'>
                                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                                    {item.asset?.location}
                                                </div>
                                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                                    {item.listing_type === 'fixedprice' ? "Fixed Price" : "Auction"}
                                                </div>
                                            </div>
                                            <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> {item.asset?.location}</p>
                                        </div>
                                        <div className='mt-5'>
                                            <h1 className='font-bold text-lg'>{item.asset?.name}</h1>
                                            <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                                <div className='text-sm 2xl:text-base'>
                                                    <h2 className='font-bold'>Expected Income</h2>
                                                    <p className='mt-1'>Not including capital appreciation</p>
                                                </div>
                                                <p className='font-bold text-lg'>{item.asset?.profit}%</p>
                                            </div>
                                            <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                                <h2>Income Start Date</h2>
                                                <p>{formatDateToReadable(item.listing_start_date)}</p>
                                            </div>
                                            {item?.nftType === 'fnft' ?
                                                <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                                    <h2>Fractions Available</h2>
                                                    <p>{item.fractions_to_sell ?? 0}</p>
                                                </div>
                                                : ''
                                            }
                                            <div className='flex justify-center mt-2'>
                                                <button
                                                    className={`bg-[#019444] rounded-md px-3 py-1 ${item.isSold ? 'cursor-not-allowed' : ''}`}
                                                    onClick={() => clickProperty(item)}
                                                    disabled={item.isSold && item.listing_type === 'auction'}
                                                >
                                                    View Asset
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sec-7 pb-10'>
                    <div className="flex justify-between items-center mb-5">
                        <h2 className="text-xl font-bold">NFT 101</h2>
                        <button className='px-3 py-1 bg-[#019444] rounded-md'>Learn more</button>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10'>
                        {NFT101?.map((item, index) => (
                            <div key={index} className={`rounded-xl p-4 border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                <img src={`/assets/building/${item.photo}`}></img>
                                <p className='mt-3 font-bold truncate '>{item.question}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer theme={theme} toggleTheme={toggleTheme} />
        </div>
    )
}