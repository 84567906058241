import React, { useEffect, useRef } from "react";
import { createChart } from "lightweight-charts";
export default function Chart() {
    const chartContainerRef = useRef(null);
    useEffect(() => {
        // Initialize Lightweight Chart
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 500,
            layout: {
                background: "#000000", // Set background to black
                textColor: "#D1D4DC",
            },
            grid: {
                vertLines: { color: "rgba(42, 46, 57, 0.5)" },
                horzLines: { color: "rgba(42, 46, 57, 0.5)" },
            },
            timeScale: { borderColor: "#485c7b" },
            rightPriceScale: { borderColor: "#485c7b" },
        });
        // Add Candlestick Data
        const candlestickSeries = chart.addCandlestickSeries({
            upColor: "#4caf50",
            downColor: "#f44336",
            borderVisible: false,
            wickUpColor: "#4caf50",
            wickDownColor: "#f44336",
        });
        candlestickSeries.setData([
            { time: "2024-12-20", open: 95, high: 97, low: 92, close: 94 },
            { time: "2024-12-21", open: 94, high: 96, low: 93, close: 95 },
            { time: "2024-12-22", open: 95, high: 98, low: 94, close: 96 },
            { time: "2024-12-23", open: 96, high: 99, low: 95, close: 98 },
            { time: "2024-12-24", open: 98, high: 100, low: 97, close: 99 },
        ]);
        // Resize Chart on Window Resize
        const handleResize = () => {
            chart.resize(chartContainerRef.current.clientWidth, 500);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            chart.remove();
        };
    }, []);
    return (
        <div className="w-[100%] lg:w-2/4 px-2">
            <div ref={chartContainerRef}></div>
        </div>
    );
}