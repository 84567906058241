import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import KycDetails from "./KycDetails";
import { SERVER_URL } from "../../../config";
import axios from "axios";

const KycList = ({ theme }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [entitiesPerPage, setEntitiesPerPage] = useState(4);
    const [searchTerm, setSearchTerm] = useState("");
    const [tableEntries, setTableEntries] = useState([]);
    const [kycDetails, setkycDetails] = useState(false);
    const [openedData, setOpenedData] = useState(null);


    const fetchKYCDetails = async () => {
        try {
            const response = await axios.get(`${SERVER_URL}/api/getkycdetails`);
            if (response.data.status === "Success") {
                setTableEntries(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching KYC details:", error);
        }
    };

    useEffect(() => {
        fetchKYCDetails();
    }, []);

    const filteredEntries = tableEntries.filter((entry) => {
        const userid = entry?.userid || "";
        const name = entry?.username || "";
        return (
            userid.toLowerCase().includes(searchTerm.toLowerCase()) ||
            name.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const indexOfLastEntity = currentPage * entitiesPerPage;
    const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
    const currentEntities = filteredEntries.slice(indexOfFirstEntity, indexOfLastEntity);

    const handlePrevClick = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextClick = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleDropdownChange = (e) => {
        setEntitiesPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleKYCDetails = (entity) => {
        setOpenedData(entity);
        setkycDetails(entity);
    };

    const UpdateKYCDetails = async() => {
       await fetchKYCDetails();
       let data = tableEntries.find((kyc) => kyc.id = openedData.id);
       setkycDetails(data);
    };

    return (
        <div className="admin_user_table h-full w-[100%]">
            <div className="px-2">
                <div className="pb-5 overflow-hidden">
                    <div className={`overflow-x-auto rounded-md mt-5 border shadow ${theme === 'dark' ? 'bg-[#0F0F0F] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                        <div className="flex justify-between items-center px-3 pt-3">
                            <div className={`bg-[#D9D9D9]/10 border rounded-full flex items-center ${theme === 'dark' ? 'border-white/20 ' : 'border-black/20'}`}>
                                <input
                                    className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
                            </div>
                            <p className="text-end text-xs px-3">
                                Show no of entity
                                <select
                                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                                    onChange={handleDropdownChange}
                                    value={entitiesPerPage}
                                >
                                    <option className="text-black" value="5">
                                        5
                                    </option>
                                    <option className="text-black" value="10">
                                        10
                                    </option>
                                    <option className="text-black" value="20">
                                        20
                                    </option>
                                </select>
                            </p>
                        </div>
                        <table className="w-full text-center text-sm 2xl:text-base">
                            <thead className={` ${theme === 'dark' ? 'bg-[#0F0F0F]' : ''}`}>
                                <tr>
                                    <th className="py-5 px-5 md:px-2 ">
                                        SI.NO
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Username
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Name
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Email
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Contact Number
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Individual / Business
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        2FA
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        KYC Details
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Profile Link
                                    </th>
                                    <th className="py-5 px-5 md:px-2 ">
                                        Send notification
                                    </th>
                                </tr>
                            </thead>
                            <tr>
                                <td
                                    colSpan="10"
                                    className={`  ${theme === 'dark' ? 'bg-[#2b2b2b] py-1' : 'border-b border-[#363636]'}`}>

                                </td>
                            </tr>
                            <tbody
                                className={`w-full text-sm rounded-lg  divide-y divide-[#363636] px-3 ${theme === 'dark' ? 'bg-[#191a1f]' : ''}`}
                            >
                                {currentEntities.length > 0 ? (
                                    currentEntities.map((entity, index) => (
                                        <tr
                                            key={index}
                                            className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                                        >
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {(currentPage - 1) * entitiesPerPage + index + 1}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.username}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.firstname} {entity.lastname}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.email}
                                            </td>
                                            <td className="px-5 lg:px-3 text-nowrap">
                                                {entity.country_code}-{entity.phnnumber}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                {entity.accountType ? entity.accountType : '-'}
                                            </td>
                                            <td className={`px-5 lg:px-3 uppercase text-wrap font-bold ${entity['2FA'] === 'on' ? 'text-[#00BA80]' : 'text-[#F7525F]'}`}>
                                                {entity['2FA']}
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 " onClick={() => handleKYCDetails(entity)}>
                                                    View
                                                </button>
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 ">
                                                    Open
                                                </button>
                                            </td>
                                            <td className="px-5 lg:px-3 text-wrap">
                                                <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5 ">
                                                    Send
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            colSpan="10"
                                            className="py-5 text-center text-sm"
                                        >
                                            No results found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mb-3 flex justify-end text-xs px-10">
                    <div className="flex gap-3">
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handlePrevClick}
                            disabled={currentPage === 1}
                        >
                            <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <button className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
                            onClick={handleNextClick}
                            disabled={currentEntities.length < entitiesPerPage}
                        >
                            <i className="fa-solid fa-arrow-right"></i>
                        </button>
                    </div>
                </div>
            </div>
            {kycDetails && <KycDetails theme={theme} handleKYCDetails={handleKYCDetails} UpdateKYCDetails={UpdateKYCDetails} entity={kycDetails} />}
        </div>
    );
};

export default KycList;
