import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

const UploadSection = ({ onUploadChange }) => {
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [documents, setDocuments] = useState([]);

    const handleFileUpload = (e, type) => {
        const allowedImageTypes = {
            "image/png": 10 * 1024 * 1024, // 10 MB
            "image/jpeg": 10 * 1024 * 1024, // 10 MB
            "image/webp": 10 * 1024 * 1024, // 10 MB
            "image/svg+xml": 5 * 1024 * 1024, // 5 MB
            "image/bmp": 20 * 1024 * 1024, // 20 MB
            "image/vnd.adobe.photoshop": 50 * 1024 * 1024, // 50 MB
            "image/raw": 50 * 1024 * 1024, // 50 MB
            "image/tiff": 50 * 1024 * 1024, // 50 MB
            "image/gif": 15 * 1024 * 1024, // 15 MB
            "image/x-gif": 15 * 1024 * 1024, // 15 MB,
            "image/eps": 20 * 1024 * 1024, // 20 MB
        };

        const allowedDocumentTypes = {
            "application/pdf": 5 * 1024 * 1024, // 5 MB
            "application/vnd.ms-excel": 5 * 1024 * 1024, // 5 MB
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": 5 * 1024 * 1024, // 5 MB
            "text/plain": 5 * 1024 * 1024, // 5 MB
        };

        const files = Array.from(e.target.files);

        if (type === "image") {
            setImages((prev) => {
                const filteredFiles = files.filter((file) => {
                    const maxSize = allowedImageTypes[file.type];
                    if (!maxSize) {
                        toast.error(`Unsupported image format: ${file.name}`);
                        return false;
                    }
                    if (file.size > maxSize) {
                        toast.error(`Image "${file.name}" exceeds the size limit (${maxSize / (1024 * 1024)} MB).`);
                        return false;
                    }
                    return true;
                });
                if (prev.length + filteredFiles.length > 10) {
                    toast.error("You can only upload up to 10 images.");
                    return prev;
                }
                const updated = [...prev, ...filteredFiles];
                onUploadChange({ images: updated, videos, documents });
                return updated;
            });
        } else if (type === "video") {
            setVideos((prev) => {
                if (files.length > 1) {
                    toast.error("You can only upload one video.");
                    return prev;
                }
                if (prev.length + files.length > 1) {
                    toast.error("You can only upload one video.");
                    return prev;
                }
                const updated = [...prev, ...files]; // No size restrictions for videos
                onUploadChange({ images, videos: updated, documents });
                return updated;
            });
        } else if (type === "document") {
            setDocuments((prev) => {
                const filteredFiles = files.filter((file) => {
                    const maxSize = allowedDocumentTypes[file.type];
                    if (!maxSize) {
                        toast.error(`Unsupported document format: ${file.name}`);
                        return false;
                    }
                    if (file.size > maxSize) {
                        toast.error(`Document "${file.name}" exceeds the size limit (${maxSize / (1024 * 1024)} MB).`);
                        return false;
                    }
                    return true;
                });
                if (prev.length + filteredFiles.length > 10) {
                    toast.error("You can only upload up to 10 documents.");
                    return prev;
                }
                const updated = [...prev, ...filteredFiles];
                onUploadChange({ images, videos, documents: updated });
                return updated;
            });
        }
    };



    const handleRemoveFile = (index, type) => {
        if (type === "image") {
            setImages((prev) => {
                const updated = prev.filter((_, i) => i !== index);
                onUploadChange({ images: updated, videos, documents });
                return updated;
            });
        } else if (type === "video") {
            setVideos((prev) => {
                const updated = prev.filter((_, i) => i !== index);
                onUploadChange({ images, videos: updated, documents });
                return updated;
            });
        } else if (type === "document") {
            setDocuments((prev) => {
                const updated = prev.filter((_, i) => i !== index);
                onUploadChange({ images, videos, documents: updated });
                return updated;
            });
        }
    };

    console.log(images, 'Images')

    return (
        <div>
            <Toaster />
            <div className="w-[90%] mx-auto mt-5 pb-10 grid md:grid-cols-2 lg:grid-cols-3 gap-10 2xl:gap-16">
                <div>
                    <div className="flex justify-around items-center">
                        <h1 className="font-medium text-xl">Images (Upload upto 10)</h1>
                        <label className="bg-secondary w-5 h-5 rounded-full grid place-content-center cursor-pointer">
                            <FontAwesomeIcon icon={faPlus} />
                            <input
                                type="file"
                                multiple
                                accept="image/*"
                                className="hidden"
                                onChange={(e) => handleFileUpload(e, "image")}
                            />
                        </label>
                    </div>
                    <div className="mt-2 flex flex-wrap justify-center gap-2">
                        {images.map((image, index) => (
                            <div
                                key={index}
                                className="mb-2 p-2 w-[28%] md:w-[35%] lg:w-[32%] rounded grid place-content-center">
                                <div className="flex gap-2 items-center">
                                    <div>
                                        <img
                                            src={URL.createObjectURL(image)}
                                            alt={image.name}
                                            className=" object-cover rounded shadow"
                                        />
                                        {/*<span className="text-sm truncate flex-1">{image.name}</span>*/}
                                    </div>
                                    <div>
                                        <button
                                            className="bg-red-500 rounded-full w-5 h-5 grid place-content-center"
                                            onClick={() => handleRemoveFile(index, "image")}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <div className="flex justify-around items-center">
                        <h1 className="font-medium text-xl">Videos (Upload only 1)</h1>
                        <label className="bg-secondary w-5 h-5 rounded-full grid place-content-center cursor-pointer">
                            <FontAwesomeIcon icon={faPlus} />
                            <input
                                type="file"
                                multiple
                                accept="video/*"
                                className="hidden"
                                onChange={(e) => handleFileUpload(e, "video")}
                            />
                        </label>
                    </div>
                    <div className="mt-2 flex flex-wrap justify-center gap-2">
                        {videos.map((video, index) => (
                            <div
                                key={index}
                                className="mb-2 p-2 w-[28%] md:w-[35%] lg:w-[32%] rounded grid place-content-center"
                            >   <div className="flex gap-2 items-center">
                                    <div>
                                        <video
                                            src={URL.createObjectURL(video)}
                                            controls
                                            className="object-cover rounded"
                                        />
                                        {/*<span className="text-sm truncate flex-1">{video.name}</span>*/}
                                    </div>
                                    <div>
                                        <button
                                            className="bg-red-500 rounded-full w-5 h-5 grid place-content-center"
                                            onClick={() => handleRemoveFile(index, "video")}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <div className="flex justify-around items-center">
                        <h1 className="font-medium text-xl">Documents (Upload upto 10)</h1>
                        <label className="bg-secondary w-5 h-5 rounded-full grid place-content-center cursor-pointer">
                            <FontAwesomeIcon icon={faPlus} />
                            <input
                                type="file"
                                multiple
                                accept=".pdf,.doc,.docx,.txt"
                                className="hidden"
                                onChange={(e) => handleFileUpload(e, "document")}
                            />
                        </label>
                    </div>
                    <div className="mt-2">
                        {documents.map((doc, index) => (
                            <div
                                key={index}
                                className="flex items-center gap-2 mb-2 p-2 rounded"
                            >
                                <a
                                    href={URL.createObjectURL(doc)}
                                    download={doc.name}
                                    className="text-blue-500 underline flex-1"
                                >
                                    {doc.name}
                                </a>
                                <button
                                    className="bg-red-500 rounded-full w-5 h-5 grid place-content-center"
                                    onClick={() => handleRemoveFile(index, "document")}
                                >
                                    <FontAwesomeIcon icon={faMinus} />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* <div className="flex justify-end pb-10 w-[90%] mx-auto">
                <button className="rounded-md bg-secondary px-7 py-2 font-semibold">Submit</button>
            </div> */}
        </div>
    );
};

export default UploadSection;
