import React, { useState } from "react";
import Header from "../Layout/Header";

const Walletmetamask = () => {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  return (
    <div
      className={`w-full bg-cover h-screen overflow-hidden overflow-y-auto font-manrope ${
        theme === "dark" ? "bg-black text-white" : "bg-white text-black"
      }`}
      style={{
        backgroundImage: `url("../assets/Others/${
          theme === "dark" ? "bgdark.svg" : "bglight.svg"
        }")`,
      }}
    >
      <div>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <div className="w-full flex flex-col justify-center items-center ">
          <div
            className={`${
              theme === "dark"
                ? "bg-[#151515] text-white border-[#2B2B2B]"
                : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
            } mt-5 w-[95%] mx-auto max-w-sm  rounded-lg border  sm:p-10 p-5 `}
          >
            <div className="mt-5 flex flex-col justify-center items-center gap-5">
              <p className=" text-2xl text-center ">Metamask</p>
              <div>
                <img
                  src="../assets/Others/metamask.png"
                  className="w-5/12 mx-auto"
                />
              </div>
              <appkit-button />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Walletmetamask;
