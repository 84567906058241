/* global grecaptcha */

import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { SERVER_URL } from "../../config";
import { useLocation } from "react-router-dom";

export default function VerifyOTP() {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]);
    const [captchaValid, setCaptchaValid] = useState(false);
    const location = useLocation();
    const userData = location.state;
    console.log(userData, 'userData');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        script.defer = true;
        script.onload = () => {
            console.log('reCAPTCHA script loaded');
        };
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    const handleOtpChange = (value, index) => {
        if (!/^\d*$/.test(value)) return;
        const newOtp = [...otp];
        newOtp[index] = value.slice(0, 1);
        setOtp(newOtp);

        if (value && index < 5) {
            document.getElementById(`otp-input-${index + 1}`).focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
        }
    };

    const handleSubmit = async () => {

        if (typeof grecaptcha === "undefined") {
            toast.error("reCAPTCHA is not loaded. Please try again.");
            return;
        }

        const captchaResponse = grecaptcha.getResponse();
        if (!captchaResponse) {
            toast.error("Please complete the reCAPTCHA");
            return;
        }

        const verifyotpData = await verifyRegisterOTP();
        if (verifyotpData === false) {
            return;
        }

        const submissionData = { ...userData, captchaResponse };

        try {
            const response = await axios.post(`${SERVER_URL}/api/signup`, submissionData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            toast.success(response.data.message);

            setTimeout(() => {
                window.location.href = '/login';
            }, 1000);
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
            toast.error('Error: ' + errorMessage);
        }
    };

    const verifyRegisterOTP = async () => {
        const otpValue = otp.join("");

        if (otpValue.length !== 6) {
            toast.error("Please enter all 6 digits of the OTP.");
            return false;
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/verify-otp`, { email: userData?.email, otp: otpValue });
            if (response.data.status === "success") {
                toast.success(response.data.message);
                return true;
            } else {
                toast.error(response.data.message);
                return false;
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || "An error occurred while verifying the OTP.";
            toast.error(errorMessage);
            return false;
        }
    };

    const handleResend = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/send-register-otp`, { email: userData?.email, username: userData?.username });

            if (response.data.status === "success") {
                toast.success("OTP resent successfully!");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while resending the OTP");
        }
    };

    const handleSend = async () => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/send-register-otp`, { email: userData?.email, username: userData?.username });

            if (response.data.status === "success") {
                toast.success("OTP sent successfully!");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("An error occurred while resending the OTP");
        }
    };

    const handleCaptchaResponse = (value) => {
        setCaptchaValid(!!value);
    };

    useEffect(() => {
        if (userData?.email) {
            handleSend()
        }
    }, [userData])

    return (
        <div className="w-full bg-[#0F0F0F] text-white bg-cover font-manrope">
            <Toaster />
            <div className="min-h-screen lg:h-screen flex flex-col lg:flex-row lg:overflow-hidden">
                <div className="w-[100%] lg:w-[50%] bg-[#151515] flex items-center justify-center">
                    <div>
                        <img src="/assets/Logo/mobilelogo.svg" alt="Logo" />
                    </div>
                </div>
                <div className="w-[100%] lg:w-[50%] flex flex-col justify-center items-center py-10 lg:py-0">
                    <div>
                        <p className="font-medium text-3xl">Verify Email Address</p>
                    </div>

                    <div className="mt-5 w-[90%] md:w-[50%] mx-auto rounded-lg">
                        <div className="mb-4">
                            <label className="block text-sm font-medium">
                                Enter OTP
                            </label>
                            <div className="flex justify-between gap-2 mt-2">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`otp-input-${index}`}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(e.target.value, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        className="w-full px-2 py-3 text-center rounded-lg bg-[#707070] outline-none text-lg"
                                    />
                                ))}
                            </div>
                            <p className="text-xs mt-2">
                                An OTP has been sent to your Email address {userData?.email}.
                                Please verify the account by entering the OTP above.
                            </p>
                        </div>
                        <div className="flex justify-center mt-4">
                            <div
                                className="g-recaptcha"
                                data-sitekey="6Lfq-rAqAAAAACHPKukFuIxYFQb9_m6aCBn2F4rO"
                                data-callback={handleCaptchaResponse}
                            ></div>
                        </div>

                        <div className="w-full flex justify-center gap-5 mt-5">
                            <button
                                type="button"
                                onClick={handleResend}
                                className="bg-[#707070] font-medium text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
                            >
                                Resend OTP
                            </button>
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="bg-secondary font-medium text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
