import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faUsers } from "@fortawesome/free-solid-svg-icons";
import { faEye, faHeart } from "@fortawesome/free-regular-svg-icons"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from '../Layout/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { MintContract, SERVER_URL, TokenContract } from '../../config';
import { ethers } from 'ethers';
import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers5/react';



export default function FractionalDetails() {

    const { address, isConnected } = useWeb3ModalAccount();
    const [theme, setTheme] = useState("light");
    const Navigate = useNavigate()
    const location = useLocation();
    const NFT = location.state || {};

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const imageFiles = NFT.files.image ? [NFT.files.image] : [];
    const videoFiles = NFT.files.video ? [NFT.files.video] : [];

    const sliderFiles = [...imageFiles, ...videoFiles].filter(file => file !== undefined && file !== '');
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
        <div {...props} className="absolute z-10 top-1/2 transform -translate-y-1/2 left-0 w-10 h-10 rounded-full bg-black cursor-pointer duration-500">
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronLeft} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
        <div {...props} className="absolute top-1/2 transform -translate-y-1/2 right-0 w-10 h-10 rounded-full bg-black cursor-pointer  duration-500">
            <div className="flex justify-center items-center w-full h-full">
                <FontAwesomeIcon icon={faChevronRight} className="text-white font-bold text-xl" />
            </div>
        </div>
    );

    const settings = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
    };
    console.log(NFT)

    return (
        <div className={`bg-center min-h-screen flex flex-col ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"}`}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='flex flex-1 items-center'>
                <div className={`Sec-1 flex flex-col lg:flex-row items-center gap-16 w-[90%] mx-auto pb-10`}>
                    <div className='w-[100%] lg:w-[35%]'>
                        <div className={`w-[100%] md:w-[75%] mx-auto lg:w-[100%] ${theme === "dark" ? "bg-[#151515] text-white border border-[#2b2b2b]" : "bg-black text-white"} rounded-lg py-6 px-8`}>
                            <div className="flex justify-between">
                                {/* <p className="font-Manrope text-white uppercase">{NFT.type}</p> */}
                                <div className="flex gap-2 items-center">
                                    {NFT.files.video ? (
                                        <video
                                            src={NFT.files.video} // Use the video source
                                            className="w-full h-64 object-cover"
                                            controls={false}
                                            autoPlay
                                            loop
                                            muted
                                            alt="NFT Video"
                                        />
                                    ) : (
                                        <img
                                            src={Array.isArray(NFT.files.image) ? NFT.files.image[0] : NFT.files.image} // Handle single or multiple images
                                            className="w-full h-64 object-cover"
                                            alt="NFT Image"
                                        />
                                    )}
                                </div>

                            </div>
                            <div className="mt-2">
                                {/* Check if slider is needed or just a single file */}
                                {/* {sliderFiles.length > 1 ? (
    <Slider {...settings} infinite={sliderFiles.length > 1}>
        {sliderFiles.map((file, index) => (
            <div key={index} className="px-1 lg:px-3">
                {file.file_type === 'image' ? (
                    <img
                        src={file.file_path}
                        className="w-full h-64 object-cover mx-auto"
                        alt={`NFT Image ${index}`}
                    />
                ) : file.file_type === 'video' ? (
                    <video
                        src={file.file_path}
                        className="w-full h-64 object-cover mx-auto"
                        controls
                        alt={`NFT Video ${index}`}
                    />
                ) : null}
            </div>
        ))}
    </Slider>
) : (
    sliderFiles.map((file, index) => (
        <div key={index} className="px-1 lg:px-3">
            {file.file_type === 'image' ? (
                <img
                    src={file.file_path}
                    className="w-full h-64 object-cover mx-auto"
                    alt={`NFT Image ${index}`}
                />
            ) : file.file_type === 'video' ? (
                <video
                    src={file.file_path}
                    className="w-full h-64 object-cover mx-auto"
                    controls
                    alt={`NFT Video ${index}`}
                />
            ) : null}
        </div>
    ))
)} */}
                            </div>

                            <div className='mt-2 grid grid-cols-4 gap-2 w-[90%] mx-auto'>
                                {NFT && NFT.files.image.slice(0, 4).map((file, index) => (
                                    <img key={index} src={file} className='w-24 h-24 object-cover rounded-md' alt={`NFT Thumbnail ${index}`} />
                                ))}
                            </div>
                            <div className="mt-4 space-y-1">
                                <p>Total Fractions: {NFT.type == 'nft' ? "SINGLE NFT(ERC721)" : NFT.totalFractions}</p>
                                <p>Contract Address: {NFT.type == 'nft' ? MintContract : NFT.mintingData.Contract}</p>
                                <p>Token ID: {NFT.mintingData[0].TokenId}</p>
                                <div className="flex items-center space-x-2">
                                    <p>Status:</p>
                                    <span className={`py-1 text-sm px-3 rounded-md ${NFT ? 'bg-green-600' : 'bg-gray-600'} text-white`}>
                                        MINTED
                                    </span>
                                    <span className="text-gray-400 text-sm truncate">(Awaiting for Listing)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-[100%] lg:w-[65%]">
                        <p className="lg:text-left text-center text-3xl font-Manrope font-bold flex items-center gap-2 uppercase">
                            {NFT.name} <div className='p-1 rounded-md bg-secondary text-sm font-normal'>{NFT.type == 'nft' ? "NFT (ERC721)" : 'F-NFT'}</div>
                        </p>
                        <div className="flex flex-col md:flex-row justify-start md:gap-10 mt-5">
                            <p className="flex justify-center items-center gap-2 text-xl">
                                <FontAwesomeIcon icon={faUsers} className="text-[#019444]" />
                                &nbsp;{NFT ? 1 : 'N/A'} owner
                            </p>
                            {/* <p className="flex justify-center items-center gap-2 text-xl">
                                <FontAwesomeIcon icon={faEye} className="text-[#019444]" />&nbsp;{nftData ? 100 : 'N/A'} Views
                            </p> */}
                            <p className="flex justify-center items-center gap-2 text-xl">
                                <FontAwesomeIcon icon={faHeart} className="text-[#019444]" />&nbsp;favorite
                            </p>
                            <div className="flex items-center gap-2">
                                {/* Conditional Rendering Based on Status */}
                                <a onClick={() => Navigate('/listasset', { state: NFT })}>
                                    <button className="bg-secondary rounded-full p-3">List Asset on LaunchPad</button>
                                </a>

                            </div>

                        </div>
                        <div className={`${theme === "dark" ? "bg-[#151515] text-white border border-[#2b2b2b]" : "bg-black text-white"} rounded-lg py-6 px-8 mt-10`}>
                            <h2 className="text-lg font-semibold mb-4">Details</h2>
                            <div className="grid md:grid-cols-2 gap-4 ">
                                <div className='space-y-2'>
                                    <p><span className="font-bold">Category:</span> {NFT.category || "Real Estate"}</p>
                                    <p><span className="font-bold">Project Start Date:</span> {formatDate(NFT.startDate) || "12 November 2024"}</p>
                                    <p>
                                        <span className="font-bold">Project Website:</span>
                                        <a
                                            href={NFT.website || "https://sdf.sfsd"}
                                            className="text-blue-400 truncate block"
                                            title={NFT.website || "https://sdf.sfsd"}
                                        >
                                            {NFT.website || "https://sdf.sfsd"}
                                        </a>
                                    </p>
                                    <p><span className="font-bold">Project Location:</span> {NFT.location || "Dubai, UAE"}</p>
                                    <p><span className="font-bold">Expected Project Expense:</span> {NFT.expense || "5,000,000 USD"}</p>
                                </div>
                                <div className='space-y-2'>
                                    <p><span className="font-bold">Status:</span> {NFT.status || "Under-development"}</p>
                                    <p><span className="font-bold">Project End Date:</span> {formatDate(NFT.endDate) || "25 December 2030"}</p>
                                    <p><span className="font-bold">Contact Email:</span> <a href={`mailto:${NFT.email || "sdc@sdcv.sd"}`} className="text-blue-400">{NFT.email || "sdc@sdcv.sd"}</a></p>
                                    <p>
                                        <span className="font-bold">Project Location Link:</span>
                                        <a
                                            href={NFT.locationLink || "https://g.maps/chdbewi"}
                                            className="text-blue-400 truncate block w-[200px]"
                                            title={NFT.locationLink || "https://g.maps/chdbewi"}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {NFT.locationLink || "https://g.maps/chdbewi"}
                                        </a>
                                    </p>
                                </div>
                                <div className='grid content-center space-y-2'>
                                    <p><span className="font-bold">Expected Project Revenue:</span> {NFT.revenue || "12,000,000 USD"}</p>
                                    <p><span className="font-bold">Contact Number:</span> {NFT.contactNumber || "+198767890767"}</p>
                                    <p><span className="font-bold">Expected Project Profit:</span> {NFT.profit || "5,000,000 USD"}</p>
                                    <p><span className="font-bold">Description:</span> {NFT.description || "fyfjindcmlojfoill"}</p>
                                </div>
                                <div >
                                    <h2 className="text-xl text-center font-semibold mb-3">Official Documents</h2>
                                    <div className="grid gap-1 w-[80%] mx-auto text-center">
                                        {NFT.files.document && NFT.files.document.length > 0 ? NFT.files.document.map((file, index) => {
                                            const fileName = file.split('https://ozonex.s3.eu-north-1.amazonaws.com/')[1];
                                            return (
                                                <div key={index} className="bg-secondary truncate py-2 px-4 rounded-md text-left">
                                                    {index + 1}. <a href={file} target="_blank" rel="noopener noreferrer">{fileName}</a>
                                                </div>
                                            );
                                        }) : "No Documents Attached"}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
