import React, { useState, useEffect } from "react";
import '../Style/style.css';
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import data from '../Data/Data.json';
import { SERVER_URL } from '../../../../config';
// import { Transactiondata as tableEntries } from '../Data/Data.js';

const Transaction = ({ theme }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.username);
  const [transactions, setTransactions] = useState([]);


  // useEffect(() => {
  //   // Fetch transaction data based on the username
  //   if (username) {
  //     axios.get(`${SERVER_URL}/api/transactions`)
  //       .then((response) => {
  //         setTransactions(response.data);
  //         console.log(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching transactions:", error);
  //       });
  //   }
  // }, [username]);

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/transactions`, {
          params: { username: user },
        });
        setTransactions(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    fetchNFTs();
  }, [user]);


  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = transactions.slice(indexOfFirstEntity, indexOfLastEntity);

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };
  return (
    <div
      className={`table_content w-full h-full bg-cover overflow-hidden overflow-y-auto font-manrope lg:content-start`}>
      <div className=" w-full mx-auto">
        <div className="w-[95%] mx-auto mt-3">
          <div className="font-san">
            <p className="text-end text-xs px-3 mb-2">
              Show no of entity
              <select
                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                onChange={handleDropdownChange}
                value={entitiesPerPage}
              >
                <option className="text-black" value="4">
                  4
                </option>
                <option className="text-black" value="5">
                  5
                </option>
                <option className="text-black" value="10">
                  10
                </option>
              </select>
            </p>
            <div className="pb-5  overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full text-center">
                  <thead
                    className={`rounded ${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                      } text-sm rounded-lg  `}
                  >
                    <tr>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Name
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Order Type
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Asset Type
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Order Qty
                      </th>
                      {/* <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Unit Price
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Total Cost
                      </th> */}
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Txn Date
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Txn ID
                      </th>
                      <th className="py-5 px-5 md:px-0 whitespace-nowrap">
                        Link
                      </th>
                    </tr>
                  </thead>
                  <tr>
                    <td colSpan="8" className="h-4"></td>
                  </tr>
                  <tbody
                    className={`mt-5 ${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                      } w-full text-sm rounded-lg  divide-y divide-[#363636] px-3`}
                  >
                    {currentEntities.map((transaction, index) => (
                      <tr key={index} className="h-16 text-xs md:text-sm cursor-pointer rounded-xl">
                        <td className="px-5 lg:px-0 whitespace-nowrap">{user}</td>
                        <td className={`uppercase font-bold ${transaction.functionType === "buy" ? "text-green-500" : "text-red-600"} px-5 lg:px-0 whitespace-nowrap`}>
                          {transaction.functionType}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">{transaction.type}</td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">{transaction.purchasedAmount}</td>
                        {/* <td className="px-5 lg:px-0 whitespace-nowrap">{transaction.unitprice}</td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">{transaction.totalcost}</td> */}
                        <td className="px-5 lg:px-0 whitespace-nowrap">{formatDate(transaction.createdAt)}</td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">{transaction.transactionHash}</td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          <button
                            onClick={() => window.open(`https://amoy.polygonscan.com/tx/${transaction.transactionHash}`, '_blank')}
                            className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5"
                          >
                            Open
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-2 flex justify-end text-xs px-10">
              <div className="flex gap-3">
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                    }  rounded-md px-2 py-1  `}
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </button>
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                    }  rounded-md px-2 py-1  `}
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
