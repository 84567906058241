import React, { useState, useEffect } from 'react'
import Header from "../../Layout/Header";
import TradeMarketplace from "./TradeMarketplace";
import Accountant from "./Accountant";
import Details from "./Details";
import SimilarAssets from "./SimilarAssets";
import { useLocation } from "react-router-dom";
import Pasttransactions from "./Pasttransactions";
import Currentoffers from "./Currentoffers";
import axios from 'axios';
import { useSelector } from 'react-redux';
import { SERVER_URL } from '../../../config';

export default function FractionalNft() {
    const [theme, setTheme] = useState("light");
    const [currentTrans, setCurrentTrans] = useState(null);
    const user = useSelector((state) => state.user.username);
    const location = useLocation();
    const NFT_data = location.state
    console.log(NFT_data, 'NFT data')

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };


    useEffect(() => {
        getMakeOffer()
    }, [user])

    const getMakeOffer = async () => {
        const response = await axios.get(`${SERVER_URL}/api/getMakeOfferData?tokenID=${NFT_data.mintingData.TokenId}`);
        console.log(response)
        if (response.data.status === "success") {
            setCurrentTrans(response.data.data)
        }
    }

    return (
        <div
            className={` bg-center  ${theme === "dark" ? "bg-black text-white bg-DarkBG" : "bg-white text-black bg-LightBG"
                }`}>
            <Header theme={theme} toggleTheme={toggleTheme} />

            <TradeMarketplace theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <div className="w-[95%] flex xl:flex-row flex-col items-center gap-5 mx-auto " >
                <Pasttransactions theme={theme} toggleTheme={toggleTheme} />
                <Currentoffers theme={theme} toggleTheme={toggleTheme} data={currentTrans} />
            </div>
            <Details theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />

            <Accountant theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />
            <SimilarAssets theme={theme} toggleTheme={toggleTheme} NFT_data={NFT_data} />



        </div>
    );
}
