import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config';
import "@fortawesome/fontawesome-free/css/all.min.css";

const KYCDashboard = () => {
    const [kycDetails, setKycDetails] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [rejectReason, setRejectReason] = useState('');
    const [rejectingKYCId, setRejectingKYCId] = useState(null);

    useEffect(() => {
        const fetchKYCDetails = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/getkycdetails`);
                if (response.data.status === "Success") {
                    setKycDetails(response.data.data);
                }
            } catch (error) {   
                console.error("Error fetching KYC details:", error);
            }
        };

        fetchKYCDetails();
    }, []);

    const handleViewClick = (image) => {
        setSelectedImage(image);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handleAccept = async (id) => {
        try {
            const response = await axios.post(`${SERVER_URL}/api/updateKYCStatus`, {
                id,
                status: 'Accepted',
            });
            if (response.data.status === "Success") {
                alert("KYC Accepted!");
                setKycDetails((prevDetails) =>
                    prevDetails.map((kyc) =>
                        kyc.id === id ? { ...kyc, status: 'Accepted' } : kyc
                    )
                );
            }
        } catch (error) {
            console.error("Error updating KYC status:", error);
        }
    };

    const handleReject = async (id) => {
        if (!rejectReason.trim()) {
            alert("Please provide a reason for rejection.");
            return;
        }

        try {
            const response = await axios.post(`${SERVER_URL}/api/updateKYCStatus`, {
                id,
                status: 'Rejected',
                reason: rejectReason,
            });
            if (response.data.status === "Success") {
                alert("KYC Rejected!");
                setKycDetails((prevDetails) =>
                    prevDetails.map((kyc) =>
                        kyc.id === id ? { ...kyc, status: 'Rejected', reason: rejectReason } : kyc
                    )
                );
                setRejectReason('');
                setRejectingKYCId(null);
            }
        } catch (error) {
            console.error("Error updating KYC status:", error);
        }
    };

    return (
        <div className=' w-full bg-cover h-screen overflow-hidden overflow-y-auto font-manrope' 
        style={{
            backgroundImage: `url("../assets/bg/bglight.svg")`,
          }}>
            <div className='mt-[40px] ml-[40px] mr-[40px]'>
            <h1 className='text-2xl font-semibold text-center mb-[40px]'>KYC Details</h1>
            <table border="1" style={{ width: '100%', border: '1px solid black', borderCollapse: 'collapse', textAlign: 'left' }} >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                        <th>Date of Birth</th>
                        <th>City</th>
                        <th>Country</th>
                        <th>Phone Number</th>
                        <th>ID Number</th>
                        <th>Expiry</th>
                        <th>Address</th>
                        <th>Picture 1</th>
                        <th>Picture 2</th>
                        <th>Picture 3</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {kycDetails.map((kyc) => (
                        <tr key={kyc.id} style={{ border: '1px solid black' }}>
                            <td>{kyc.id}</td>
                            <td>{kyc.firstname}</td>
                            <td>{kyc.lastname}</td>
                            <td>{kyc.username}</td>
                            <td>{new Date(kyc.dob).toLocaleDateString()}</td>
                            <td>{kyc.city}</td>
                            <td>{kyc.country}</td>
                            <td>{kyc.phnnumber}</td>
                            <td>{kyc.idnumber}</td>
                            <td>{new Date(kyc.expiry).toLocaleDateString()}</td>
                            <td>{kyc.address}</td>
                            <td>
                                <p
                                    style={{ cursor: "pointer", color: "black", textDecoration: "none" }}
                                    onClick={() => handleViewClick(kyc.picture1)}
                                >
                                    view <i className="fas fa-eye"></i>
                                </p>
                            </td>
                            <td>
                                <p
                                    style={{ cursor: "pointer", color: "black", textDecoration: "none" }}
                                    onClick={() => handleViewClick(kyc.picture2)}
                                >
                                    view <i className="fas fa-eye"></i>
                                </p>
                            </td>
                            <td>
                                <p
                                    style={{ cursor: "pointer", color: "black", textDecoration: "none" }}
                                    onClick={() => handleViewClick(kyc.picture3)}
                                >
                                    view <i className="fas fa-eye"></i>
                                </p>
                            </td>
                            <td>{kyc.status || "Pending"}</td>
                            <td>
                                <button
                                    className='rounded-lg'
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        padding: '5px 10px',
                                        border: 'none',
                                        cursor: 'pointer',
                                        margin: '5px',
                                    }}
                                    onClick={() => handleAccept(kyc.id)}
                                >
                                    Accept
                                </button>
                                {rejectingKYCId === kyc.id ? (
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Reason for rejection"
                                            value={rejectReason}
                                            onChange={(e) => setRejectReason(e.target.value)}
                                            style={{ margin: '5px' }}
                                            className='border border-black rounded-sm px-4'
                                        />
                                        <button
                                            className='rounded-lg'
                                            style={{
                                                backgroundColor: 'green',
                                                color: 'white',
                                                padding: '5px 10px',
                                                border: 'none',
                                                cursor: 'pointer',
                                                margin: '5px',
                                            }}
                                            onClick={() => handleReject(kyc.id)}
                                        >
                                            Submit
                                        </button>
                                        <button
                                            className='rounded-lg'
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                padding: '5px 10px',
                                                border: 'none',
                                                cursor: 'pointer',
                                                margin: '5px',
                                            }}
                                            onClick={() => setRejectingKYCId(null)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                ) : (
                                    <button
                                        className='rounded-lg'
                                        style={{
                                            backgroundColor: 'red',
                                            color: 'white',
                                            padding: '5px 10px',
                                            border: 'none',
                                            cursor: 'pointer',
                                            margin: '5px',
                                        }}
                                        onClick={() => setRejectingKYCId(kyc.id)}
                                    >
                                        Reject
                                    </button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {selectedImage && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    onClick={closeModal}
                >
                    <img
                        src={`data:image/png;base64,${selectedImage}`}
                        alt="Full View"
                        style={{
                            maxWidth: "90%",
                            maxHeight: "90%",
                            boxShadow: "0px 0px 15px rgba(255, 255, 255, 0.5)",
                        }}
                    />
                </div>
            )}
        </div>
        </div>
    );
};

export default KYCDashboard;
