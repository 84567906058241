import React from 'react'
import '../Style/Style.css'

export default function Platformfee({ theme }) {
    return (
        <div className={`admin_table rounded-lg h-full grid content-between py-10 ${theme === 'dark' ? 'bg-[#191A1F]' : ''}`}>
            <div className='flex flex-col md:flex-row gap-10 w-[90%] mx-auto '>
                <div className='w-[100%] md:w-[50%] grid gap-5 2xl:gap-7'>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>NFT Minting Fees</label>
                        <input className='outline-none py-2 2xl:py-4 px-3 rounded-md bg-[#707070] w-[100%]'></input>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Marketplace Maker Fees</label>
                        <input className='outline-none py-2 2xl:py-4 px-3 rounded-md bg-[#707070] w-[100%]'></input>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Launchpad NFT Buy Fees</label>
                        <input className='outline-none py-2 2xl:py-4 px-3 rounded-md bg-[#707070] w-[100%]'></input>
                    </div>
                </div>
                <div className='w-[100%] md:w-[50%] grid gap-5  2xl:gap-7'>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>FNFT Minting Fees</label>
                        <input className='outline-none py-2 2xl:py-4 px-3 rounded-md bg-[#707070] w-[100%]'></input>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Marketplace Maker Fees</label>
                        <input className='outline-none py-2 2xl:py-4 px-3 rounded-md bg-[#707070] w-[100%]'></input>
                    </div>
                    <div className='grid gap-1 2xl:gap-3'>
                        <label>Launchpad FNFT Buy Fees</label>
                        <input className='outline-none py-2 2xl:py-4 px-3 rounded-md bg-[#707070] w-[100%]'></input>
                    </div>
                </div>
            </div>
            <div className='flex justify-center gap-5 md:gap-10 mt-10 lg:mt-0'>
                <button className='bg-[#f7535f] px-3 py-0.5 rounded-md w-40'>Discard Changes</button>
                <button className='bg-secondary px-3 py-0.5 rounded-md w-40'>Update</button>
            </div>
        </div>
    )
}
