/* global grecaptcha */

import React, { useState, useEffect } from "react";
import { SERVER_URL } from "../../config";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { allCountries } from "country-telephone-data";
import { getCountryCallingCode, parsePhoneNumberFromString } from "libphonenumber-js";
import { debounce } from 'lodash';

const Signup = () => {

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [availability, setAvailability] = useState({
    username: null,
    email: null,
    phone: null,
  });
  const [phoneNumberLength, setPhoneNumberLength] = useState(10);


  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.google.com/recaptcha/api.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      console.log('reCAPTCHA script loaded');
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "",
    phone: "",
    countryCode: ""
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    country: "",
    phone: "",
    countryCode: ""
  });

  const countryCodes = {
    USA: "+1",
    India: "+91",
    UK: "+44",
    Canada: "+1",
    Australia: "+61",
  };

  const countryList = allCountries.map((country) => ({
    name: country.name,
    dialCode: `+${country.dialCode}`,
  }));

  const getPhoneLengthForCountry = (dialCode) => {
    try {
      // Map dial codes to a dummy example number
      const examplePhone = parsePhoneNumberFromString(`${dialCode}1234567890`);
      if (!examplePhone) {
        console.error(`Unable to parse example number for dial code: ${dialCode}`);
        return null;
      }

      // The length of the phone number for that country
      const phoneLength = examplePhone.nationalNumber.length;
      return phoneLength;
    } catch (error) {
      console.error(`Error processing dial code ${dialCode}:`, error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name)

    if (name === "phone") {
      console.log(formData.countryCode);
      const maxLength = getPhoneLengthForCountry(formData.countryCode);
      console.log(maxLength);
      setPhoneNumberLength(maxLength)
      if (maxLength && value.length > maxLength) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: `Phone number should be ${maxLength} digits for ${formData.countryCode}.`,
        }));
        return;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "",
        }));
      }

      debouncedCheckAvailability(name, value);
    }

    if (name === "country") {
      const selectedCountry = countryList.find(c => c.name === value);
      if (selectedCountry) {
        setFormData({
          ...formData,
          country: selectedCountry.name,
          countryCode: selectedCountry.dialCode,
        });
        const maxLength = getPhoneLengthForCountry(selectedCountry.dialCode);
        setPhoneNumberLength(maxLength)
      }
      return;
    }


    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
    if (name === "username" || name === "email" || name === "phone") {
      setAvailability({ ...availability, [name]: null });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = "First Name is required";
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last Name is required";
    }
    if (!formData.username.trim()) {
      newErrors.username = "Username is required";
    }
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }
    if (!formData.country) {
      newErrors.country = "Country is required";
    }
    if (formData.phone && !formData.phone) {
      newErrors.phone = "Phone number is required";
    } else if (formData.phone && !/^\d+$/.test(formData.phone)) {
      newErrors.phone = "Phone number must be numeric";
    }
    return newErrors;
  };

  const debouncedCheckAvailability = debounce((field, value) => {
    checkAvailability(field, value);
  }, 500);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const checkAvailability = async (field, value) => {
    if (!value.trim()) return;
    console.log(field);
    console.log(value);
    try {
      const response = await axios.post(`${SERVER_URL}/api/check-availability`, {
        field,
        value,
      });

      setAvailability({ ...availability, [field]: response.data.available });
      setErrors({ ...errors, [field]: "" });
    } catch (error) {
      setAvailability({ ...availability, [field]: false });
      setErrors({
        ...errors,
        [field]: "An error occurred. Please try again later.",
      });
    }
  };

  const generateOtp = async (e) => {
    e.preventDefault();

    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      try {
        if (!formData.email) {
          toast.error("Please provide a valid email address.");
          return;
        }
        else {
          navigate('/VerifyOTP/setState=relative', { state: formData })
        }
      } catch (error) {
        console.error("Error generating OTP:", error);

        const errorMessage = error.response?.data?.message || "An unexpected error occurred while generating OTP.";
        toast.error(errorMessage);
      }
    } else {
      setErrors(formErrors);
    }

  };

  return (
    <div className="w-full bg-[#0F0F0F] text-white">
      <Toaster />
      <div className="min-h-screen lg:h-screen flex flex-col lg:flex-row items-center lg:overflow-hidden">
        <div className="w-[100%] lg:w-[50%] bg-[#151515] flex items-center justify-center">
          <div><img src="/assets/Logo/mobilelogo.svg"></img></div>
        </div>
        <div className="w-[100%] lg:w-[50%] py-10 lg:py-0">
          <form
            onSubmit={generateOtp}
            className="mt-5 mx-auto rounded-lg w-[85%]"
          >
            <div>
              <p className="text-center mb-5 font-medium text-3xl ">Signup</p>
            </div>
            <div className="grid md:grid-cols-2 gap-5">

              <div className="">
                <label className="block text-sm font-medium ">
                  First Name*
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                />
                {errors.firstName && (
                  <span className="w-full text-red-500 text-sm">
                    {errors.firstName}
                  </span>
                )}
              </div>

              <div className="">
                <label className="block text-sm font-medium ">
                  Last Name*
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                />
                {errors.lastName && (
                  <span className="w-full text-red-500 text-sm">
                    {errors.lastName}
                  </span>
                )}
              </div>

              <div className="">
                <label className="block text-sm font-medium ">
                  User Name*
                </label>
                <input
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  onBlur={() => checkAvailability("username", formData.username)}
                  className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                />
                {errors.username ? (
                  <span className="w-full text-red-500 text-sm">
                    {errors.username}
                  </span>
                ) : (
                  availability.username === true ? (
                    <span className="text-green-500 text-sm">Username is available</span>
                  ) : availability.username === false ? (
                    <span className="text-red-500 text-sm">
                      Username is already taken
                    </span>
                  ) : null
                )}
              </div>

              <div className="">
                <label className="block text-sm font-medium ">
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  onBlur={() => checkAvailability("email", formData.email)}
                  className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                />
                {!errors.email && availability.email === true && (
                  <span className="text-green-500 text-sm">Email is available</span>
                )}
                {!errors.email && availability.email === false && (
                  <span className="text-red-500 text-sm">Email is already taken</span>
                )}
                {errors.email && (
                  <span className="w-full text-red-500 text-sm">{errors.email}</span>
                )}

              </div>

              <div>
                <label className="block text-sm font-medium ">
                  Password*
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="absolute right-3 top-[50%] -translate-y-[50%] cursor-pointer text-black"
                  >
                    {showPassword ? (
                      <i className="fa-solid fa-eye fa-sm"></i>
                    ) : (
                      <i className="fa-solid fa-eye-slash fa-sm"></i>
                    )}
                  </span>
                  {errors.password && (
                    <span className="text-red-500 text-sm">{errors.password}</span>
                  )}
                </div>
              </div>

              <div className=" relative">
                <label className="block text-sm font-medium ">
                  Confirm Password*
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                />
                {errors.confirmPassword && (
                  <span className="text-red-500 text-sm">
                    {errors.confirmPassword}
                  </span>
                )}
              </div>

              <div className="">
                <label className="block text-sm font-medium ">
                  Country*
                </label>
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                >
                  <option value="">Select a country</option>
                  {countryList.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name} - {country.dialCode}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <span className="w-full text-red-500 text-sm">
                    {errors.country}
                  </span>
                )}
              </div>

              <div className="flex gap-2">
                <div className="w-[600px]">
                  <label className="block ml-[10px] text-sm font-medium">Phone Number</label>
                  <div className="flex items-center">
                    {formData.country && (
                      <span className="mr-2">{countryCodes[formData.country]}</span>
                    )}
                    <input
                      type="text"
                      name="phone"
                      minLength={phoneNumberLength}
                      // maxLength={phoneNumberLength}
                      value={formData.phone}
                      onChange={handleInputChange}
                      onBlur={() => checkAvailability("phone", formData.phone)}
                      className="w-[100%] px-4 py-2 rounded-lg bg-[#707070] outline-none"
                    />
                  </div>
                  {errors.phone ? (
                    <span className="text-red-500 text-sm">{errors.phone}</span>
                  ) : (
                    availability.phone !== null && (
                      <span
                        className={`text-sm ml-[10px] ${availability.phone ? "text-green-500" : "text-red-500"
                          }`}
                      >
                        {availability.phone
                          ? "Phone number is available"
                          : "Phone number is already taken"}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            {/* <div className="flex justify-center mt-4">
              <div
                className="g-recaptcha"
                data-sitekey="6Lfq-rAqAAAAACHPKukFuIxYFQb9_m6aCBn2F4rO"
                data-callback={handleCaptchaResponse}
              ></div>
            </div> */}
            <div className="w-[100%] md:w-[50%] mx-auto">
              <button
                type="submit"
                className="w-full py-2 mt-9 bg-[#019444] text-white font-bold rounded-lg focus:outline-none"
              >
                Create Account
              </button>
            </div>
          </form>
          <div className="text-center mt-[36px] text-sm text-white">
            <p>Already an OzoneX member? <Link to="/login" className="px-[4px] underline text-green-500">Signin</Link></p>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Signup;
