import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { SERVER_URL } from "../../config";

export default function ResetPassword() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [oldPasswordHash, setOldPasswordHash] = useState(""); // Store old password hash

    // Fetch the email from URL
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const emailFromUrl = params.get("email");
        if (emailFromUrl) {
            setEmail(emailFromUrl);
        } else {
            toast.error("No email found.");
            window.location.href = "/forgotpassword";
        }
    }, []);

    const passwordsMatch = password && confirmPassword && password === confirmPassword;

    const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

    const handleSubmit = async () => {
        if (passwordsMatch) {
            try {
                // Step 1: Fetch the user's old password hash from the database
                const response = await axios.post(`${SERVER_URL}/api/get-user-password`, { email });

                if (response.data.status === "success") {
                    const { password: storedPasswordHash } = response.data.user;
                    setOldPasswordHash(storedPasswordHash); // Store the old password hash
                    const bcrypt = require("bcryptjs");
                    // Step 2: Compare the entered password with the stored hash
                    const isOldPasswordMatch = await bcrypt.compare(password, storedPasswordHash);

                    if (isOldPasswordMatch) {
                        toast.error("New password cannot be the same as the old password.");
                    } else {
                        // Step 3: Update the password in the database
                        const updateResponse = await axios.post(`${SERVER_URL}/api/update-password`, {
                            email,
                            password
                        });

                        if (updateResponse.data.status === "success") {
                            toast.success("Password reset successfully!");
                            setTimeout(() => {
                                window.location.href = '/login'; // Adjust to the correct path of the Profile Setup page
                              }, 1000);
                        } else {
                            toast.error(updateResponse.data.message);
                        }
                    }
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error verifying email:', error);
                toast.error("An error occurred while resetting the password.");
            }
        } else {
            toast.error("Passwords do not match!");
        }
    };

    return (
        <div className="w-full bg-[#0F0F0F] text-white bg-cover font-manrope">
            <Toaster />
            <div className="min-h-screen lg:h-screen flex flex-col lg:flex-row lg:overflow-hidden">
                <div className="w-[100%] lg:w-[50%] bg-[#151515] flex items-center justify-center">
                    <div>
                        <img src="/assets/Logo/mobilelogo.svg" alt="Logo" />
                    </div>
                </div>
                <div className="w-[100%] lg:w-[50%] flex flex-col justify-center items-center py-10 lg:py-0">
                    <div>
                        <p className="font-medium text-3xl">Reset Password</p>
                    </div>

                    <div className="mt-5 w-[90%] md:w-[50%] mx-auto rounded-lg">
                        {/* New Password */}
                        <div className="mb-4 relative">
                            <label className="block text-sm font-medium">Enter New Password</label>
                            <div className="relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full px-4 py-2 rounded-lg bg-[#707070] outline-none"
                                />
                                <span
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                    onClick={togglePasswordVisibility}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                </span>
                            </div>
                        </div>

                        {/* Confirm Password */}
                        <div className="mb-4 relative">
                            <label className="block text-sm font-medium">Confirm Password</label>
                            <div className="relative">
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className="w-full px-4 py-2 rounded-lg bg-[#707070] outline-none"
                                />
                                <span
                                    className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    <FontAwesomeIcon icon={showConfirmPassword ? faEye : faEyeSlash} />
                                </span>
                            </div>
                        </div>

                        {/* Success Icon */}
                        {passwordsMatch && (
                            <div className="mb-4 text-green-500 flex items-center gap-2">
                                <FontAwesomeIcon icon={faCheckCircle} />
                                <span>Passwords match</span>
                            </div>
                        )}

                        {/* Submit Button */}
                        <div className="w-full flex justify-center mt-5">
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="bg-secondary font-medium text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
