import React from "react";
import Chart from "./Chart";
import Orderbook from "./Orderbook";
import Sidebar from "./Sidebar";
import Header from "../../../../Layout/Header";
import Topbar from "./Topbar";
import Bottombar from "./Bottombar";
const App = () => {
    return (
        <div className="bg-[#2b2b2b] text-white min-h-screen">
            {/* Header */}
            <Header/>
            <Topbar/>
            {/* Main Layout */}
            <div className="flex flex-col lg:flex-row py-1">
                
                {/* Order Book */}
                <Orderbook />
                {/*Chart*/}
                <Chart />
                {/* Sidebar */}
                <Sidebar/>
            </div>
            <Bottombar/>
        </div>
    );
};
export default App;