import React, { useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from './config'; // Assuming you have this for the server URL

const KYCViewer = () => {
  const [username, setUsername] = useState('');
  const [pictures, setPictures] = useState({ picture1: null, picture2: null, picture3: null });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleFetchKYC = async () => {
    setError('');
    setLoading(true);
    setPictures({ picture1: null, picture2: null, picture3: null });

    try {
      const response = await axios.post(`${SERVER_URL}/api/getpicture`, { username });
      const { picture1, picture2, picture3 } = response.data;
      console.log('Received images:', { picture1, picture2, picture3 }); // Debugging
      setPictures({ picture1, picture2, picture3 });
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to fetch KYC data');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-2xl font-bold mb-4">KYC Viewer</h1>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="border border-gray-300 px-4 py-2 rounded w-72"
        />
      </div>
      <button
        onClick={handleFetchKYC}
        className="bg-blue-500 text-white px-6 py-2 rounded"
        disabled={loading}
      >
        {loading ? 'Loading...' : 'Fetch KYC Data'}
      </button>
      {error && <p className="text-red-500 mt-4">{error}</p>}
      <div className="mt-8 grid grid-cols-1 sm:grid-cols-3 gap-4">
        {pictures.picture1 && (
          <img
            src={`data:image/png;base64,${pictures.picture1}`}
            alt="KYC Document 1"
            className="w-48 h-48 object-cover border rounded"
          />
        )}
        {pictures.picture2 && (
          <img
            src={`data:image/png;base64,${pictures.picture2}`}
            alt="KYC Document 2"
            className="w-48 h-48 object-cover border rounded"
          />
        )}
        {pictures.picture3 && (
          <img
            src={`data:image/png;base64,${pictures.picture3}`}
            alt="KYC Document 3"
            className="w-48 h-48 object-cover border rounded"
          />
        )}
      </div>
    </div>
  );
};

export default KYCViewer;
