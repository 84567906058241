import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
export default function Topbar() {
    return (
        <div className='bg-[#0F0F0F] pl-5 pr-1 text-sm 2xl:text-base rounded-lg py-2 font-semibold text-center grid grid-cols-2 md:grid-cols-3 lg:flex justify-between items-center'>
            <p>ABC F-NFT  120 USD</p>
            <p>24h change: +15 USD | +5%</p>
            <p>24h High: 200 USD</p>
            <p>24h Low: 120 USD</p>
            <p>24h Volume: 50 ABC | 500USD</p>
            <div className='w-[100%] lg:w-1/4 relative'>
            <input
                type="text"
                placeholder="Search markets..."
                className="w-[100%] bg-white/50 p-2 placeholder:text-white rounded-full text-sm outline-none"
            />
            <FontAwesomeIcon icon={faMagnifyingGlass} className='absolute right-3 top-2.5'/>
            </div>
        </div>
    )
}