import React, { act, useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import axios from 'axios';
import { SERVER_URL } from "../../../../config";

const Dividends = ({ theme }) => {

  const user = useSelector((state) => state.user.username);

  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [tableEntries, setTableEntries] = useState([]);

  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;

  let currentEntities = tableEntries.slice(
    indexOfFirstEntity,
    indexOfLastEntity
  );

  const emptyRowCount = entitiesPerPage - currentEntities.length;

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentEntities.length === entitiesPerPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  useEffect(() => {
    getUserDividendHistory();
  }, [user]);

  const getUserDividendHistory = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/api/getUserDividendHistory?username=${user}`);
      console.log(response)
      setTableEntries(response.data.data);
    } catch (error) {
      console.error("Error fetching user dividend history:", error);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const viewHash = async (hash) => {
    window.open(`https://amoy.polygonscan.com/tx/${hash}`, '_blank')
  }

  const calculateReward = (total_holdings, dividend_amount) => {
    let totalHoldings = total_holdings === null ? 1 : Number(total_holdings)
    let totalAmount = Number(dividend_amount)
    let dividend_perunit = (1 / totalHoldings) * totalAmount
    return dividend_perunit.toFixed(2);
  }

  const DPY = (dividend_amount, listing_start_price, totalfractions) => {
    let totalAmount = Number(dividend_amount);
    let invetment = (Number(listing_start_price) * Number(totalfractions ?? 1))
    let dpy_value = (totalAmount * 100) / invetment;
    return dpy_value.toFixed();
  }

  return (
    <div
      className={`table_content w-[100%] h-full bg-cover overflow-hidden overflow-y-auto font-manrope  lg:grid lg:content-start`}>
      <div className=" w-[100%] mx-auto">
        <div className="w-[100%] px-3 mx-auto mt-3">
          <div className="font-san">
            <p className="text-end text-xs px-3 mb-2">
              Show no of entity
              <select
                className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                onChange={handleDropdownChange}
                value={entitiesPerPage}
              >
                <option className="text-black" value="4">
                  4
                </option>
                <option className="text-black" value="5">
                  5
                </option>
                <option className="text-black" value="10">
                  10
                </option>
              </select>
            </p>
            <div className="pb-5  overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full text-center">
                  <thead
                    className={`${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                      } text-sm rounded-lg`}
                  >
                    <tr>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Name
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Dividend receipt date
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Asset Type
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Holding Qty
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Investment
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Dividend per unit
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        DPY
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Dividends received
                      </th>
                      <th className="py-5 px-5 md:px-2 whitespace-nowrap">
                        Transaction ID
                      </th>
                    </tr>
                  </thead>
                  <tr>
                    <td colSpan="8" className="h-4"></td>
                  </tr>
                  <tbody
                    className={`mt-5 ${theme === "dark"
                      ? "bg-[#0f0f0f] text-[#bbbabb] "
                      : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                      } w-full text-sm rounded-lg  divide-y divide-[#363636] px-3`}
                  >
                    {currentEntities.map((entity, index) => (
                      <tr
                        key={index}
                        className="h-16 text-xs md:text-sm cursor-pointer rounded-xl"
                      >
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.asset_name}
                        </td>
                        <td
                          className={`uppercase font-bold px-5 lg:px-0 whitespace-nowrap`}
                        >
                          {formatDate(entity.created_at)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.type === 'fnft' ? 'F-NFT' : 'NFT'}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.type === 'fnft' ? entity.totalfractions : 1}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {Number(entity.listing_start_price) * Number(entity.type === 'fnft' ? entity.totalfractions : 1)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {calculateReward(entity.holdings, entity.split_received)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {DPY(entity.split_received, entity.listing_start_price, entity.type === 'fnft' ? entity.totalfractions : 1)}%
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {Number(entity.split_received).toFixed(2)}
                        </td>
                        <td className="px-5 lg:px-0 whitespace-nowrap">
                          {entity.status === 'completed' ?
                            <button className="text-xs bg-[#00a04a] rounded-lg text-white font-medium px-3 py-0.5" onClick={() => { viewHash(entity.transaction_hash) }}>
                              View
                            </button>
                            :
                            <button className="text-xs bg-yellow-500 cursor-not-allowed rounded-lg text-white font-medium px-3 py-0.5" >
                              Scheduled
                            </button>
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mt-2 flex justify-end text-xs px-10">
              <div className="flex gap-3">
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                    }  rounded-md px-2 py-1  `}
                  onClick={handlePrevClick}
                  disabled={currentPage === 1}
                >
                  <i class="fa-solid fa-arrow-left"></i>
                </button>
                <button
                  className={` ${theme === "dark"
                    ? "bg-[#0f0f0f] text-[#bbbabb] "
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                    }  rounded-md px-2 py-1  `}
                  onClick={handleNextClick}
                  disabled={currentEntities.length < entitiesPerPage}
                >
                  <i class="fa-solid fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dividends;
