import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import axios from 'axios';

import toast, { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";

export default function UserKyc({ theme, handleKYCDetails, entity }) {

    const [isRejectionModalOpen, setIsRejectionModalOpen] = useState(false);
    const [rejectionReason, setRejectionReason] = useState("");
    const [currentImage, setCurrentImage] = useState(null);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        return date.toISOString().split('T')[0]; // Extract only YYYY-MM-DD
    };

    const closeModal = () => {
        setCurrentImage(null);
    };

    return (
        <div className='fixed inset-0 bg-black/70 flex justify-center md:items-center py-3 lg:py-0'>
            <Toaster />
            <div className={`w-[90%] md:w-[75%] py-5 px-7 2xl:px-10 lg:w-[50%] rounded-xl overflow-y-auto ${theme === 'dark' ? 'bg-[#151515]' : 'bg-[#f8f8f8]'}`}>
                <div className='flex justify-end'>
                    <button onClick={() => handleKYCDetails(null)}><FontAwesomeIcon icon={faXmarkCircle} /></button>
                </div>
                <h2 className="text-xl text-center font-medium">KYC Details</h2>
                {entity.firstname ? (
                    <div className="grid lg:grid-cols-3 gap-5 mt-4">
                        <div className="lg:col-span-2 grid content-center text-sm space-y-2">
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Username:</span> {entity.username}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">UserID:</span> {entity.id}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">First Name:</span> {entity.firstname}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Last Name:</span> {entity.lastname}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Address:</span> {entity.address}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">City:</span>  {entity.city}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Country:</span> {entity.country}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Date of Birth:</span>  {formatDate(entity.dob)}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Document Type:</span> {entity.doc_type}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">Document Expiry Date:</span> {formatDate(entity.expiry)}
                            </p>
                            <p className='md:flex'>
                                <span className="font-medium text-[#626262] md:w-[50%]">KYC Status</span> {entity.status}
                            </p>
                        </div>

                        <div className="flex flex-wrap lg:flex-col gap-3 justify-center">
                            <div className={`flex flex-col items-center p-1 rounded-md ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`} onClick={() => setCurrentImage(entity.picture1)}>
                                <img
                                    src={`data:image/png;base64,${entity.picture1}`}
                                    alt="Document Front Page"
                                    className="rounded-lg w-20 2xl:w-24"
                                    target="_blank"
                                />
                                <p className="mt-2 text-xs">Document Front Page</p>
                            </div>
                            <div className={`flex flex-col items-center p-1 rounded-md ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`} onClick={() => setCurrentImage(entity.picture2)}>
                                <img
                                    src={`data:image/png;base64,${entity.picture2}`}
                                    alt="Document Back Page"
                                    className="rounded-lg w-20 2xl:w-24"
                                />
                                <p className="mt-2 text-xs">Document Back Page</p>
                            </div>
                            <div className={`flex flex-col items-center p-1 rounded-md ${theme === 'dark' ? 'bg-white/10' : 'bg-black/20'}`} onClick={() => setCurrentImage(entity.picture3)}>
                                <img
                                    src={`data:image/png;base64,${entity.picture3}`}
                                    alt="Document Selfie Page"
                                    className="rounded-lg w-20 2xl:w-24"
                                />
                                <p className="mt-2 text-xs">Document Selfie Page</p>
                            </div>
                        </div>
                        {currentImage && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 mt-[0px]">
                            <div className="relative  ">
                                <img
                                    src={`data:image/png;base64,${currentImage}`}
                                    alt="Zoomed-in Image"
                                    className="rounded-lg max-w-[85%] mx-auto max-h-screen"
                                />
                                <button
                                    onClick={closeModal}
                                    className="absolute -top-8 right-8 bg-white text-black rounded-full font-bold w-8 h-8 "
                                >
                                    X
                                </button>
                                {/* <button onClick={closeModal}><FontAwesomeIcon icon={faXmarkCircle} className='absolute top-0 right-0 z-60'/></button> */}
                            </div>
                        </div>
                    )}
                    </div>

                ) : (
                    <div className="text-center mt-4">
                        <p className="text-red-500 font-medium">The user has not completed the KYC process yet.</p>
                    </div>
                )}

            </div>
        </div>
    )
}

