import { faMagnifyingGlass, faMinus, faPlus, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import Newcatergory from "./Newcatergory";
import axios from "axios";
import { SERVER_URL } from "../../../config";
import toast, { Toaster } from 'react-hot-toast';


const PlateformCategories = ({ theme }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entitiesPerPage, setEntitiesPerPage] = useState(4);
  const [searchTerm, setSearchTerm] = useState("");
  const [Catergory, setCatergory] = useState(false);

  // States for NFT and FNFT tables
  const [nftTable, setNftTable] = useState([]);
  const [fnftTable, setFnftTable] = useState([]);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [categoryToRemove, setCategoryToRemove] = useState("");
  const [categoryTypeToRemove, setCategoryTypeToRemove] = useState("");

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/getcategory`);
        // console.log(response);
        const categories = response.data.data;


        const nftCategories = categories
          .filter((category) => category.type.split(",").includes("nft") && !category.type.split(",").includes("fnft"))
          .map((category) => category.category_name);

        const fnftCategories = categories
          .filter((category) => category.type.split(",").includes("fnft") && !category.type.split(",").includes("nft"))
          .map((category) => category.category_name);

        const bothCategories = categories
          .filter((category) => category.type.split(",").includes("nft") && category.type.split(",").includes("fnft"))
          .map((category) => category.category_name);


        setNftTable([...nftCategories, ...bothCategories]);
        setFnftTable([...fnftCategories, ...bothCategories]);

      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);


  // useEffect(() => {
  //   console.log("Current NFT Table:", nftTable);
  //   console.log("Current FNFT Table:", fnftTable);
  // }, [nftTable, fnftTable]);

  const handleCatergory = () => {
    setCatergory(!Catergory);
  };

  // Filter table entries based on the search term
  const filteredNft = nftTable.filter((entry) =>
    entry.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredFnft = fnftTable.filter((entry) =>
    entry.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic for NFT and FNFT tables
  const indexOfLastEntity = currentPage * entitiesPerPage;
  const indexOfFirstEntity = indexOfLastEntity - entitiesPerPage;
  const currentNft = filteredNft.slice(indexOfFirstEntity, indexOfLastEntity);
  const currentFnft = filteredFnft.slice(indexOfFirstEntity, indexOfLastEntity);

  const handlePrevClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleDropdownChange = (e) => {
    setEntitiesPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  // Handle adding category
  const addCategory = (categoryName, isNftChecked, isFnftChecked) => {
    if (isNftChecked) {
      setNftTable((prev) => [...prev, categoryName]);
    }
    if (isFnftChecked) {
      setFnftTable((prev) => [...prev, categoryName]);
    }
    setCatergory(false); // Close the popup
  };

  const handleRemoveCategory = (category, type) => {
    setCategoryToRemove(category);
    setCategoryTypeToRemove(type);
    setShowConfirmation(true); // Show confirmation popup
  };

  const confirmRemoveCategory = async (confirm) => {
    if (confirm) {
      try {
        if (categoryTypeToRemove === "nft" && fnftTable.includes(categoryToRemove)) {
          // If category is in both, update it in the database to only FNFT
          await axios.post(`${SERVER_URL}/api/updateCategoryType`, {
            category: categoryToRemove,
            type: "fnft"
          });
          toast.success("Category has been deleted");
          setTimeout(() => {
            window.location.reload();
          }, 800);

          setFnftTable((prev) => [...prev, categoryToRemove]);
          setNftTable((prev) => prev.filter(item => item !== categoryToRemove));
        } else if (categoryTypeToRemove === "fnft" && nftTable.includes(categoryToRemove)) {
          // If category is in both, update it in the database to only NFT
          await axios.post(`${SERVER_URL}/api/updateCategoryType`, {
            category: categoryToRemove,
            type: "nft"
          });
          toast.success("Category has been deleted");
          setTimeout(() => {
            window.location.reload();
          }, 800);
          setNftTable((prev) => [...prev, categoryToRemove]);
          setFnftTable((prev) => prev.filter(item => item !== categoryToRemove));
        } else {
          // If only in one category, delete it from DB
          await axios.post(`${SERVER_URL}/api/deleteCategory`, { category: categoryToRemove });
          toast.success("Category has been deleted");
          setTimeout(() => {
            window.location.reload();
          }, 800);
          if (categoryTypeToRemove === "nft") {
            setNftTable((prev) => prev.filter(item => item !== categoryToRemove));
          } else {
            setFnftTable((prev) => prev.filter(item => item !== categoryToRemove));
          }
        }
      } catch (error) {
        console.error("Error handling category removal:", error);
      }
    }
    setShowConfirmation(false);
  };



  return (
    <div className="w-[100%] h-full admin_table">
      <Toaster />
      <div className="px-2">
        <div className="pb-5 overflow-hidden">
          <div
            className={`overflow-x-auto rounded-md mt-5 border shadow ${theme === "dark" ? "bg-[#0F0F0F] border-[#2B2B2B]" : "bg-[#F8F8F8] border-[#D9D8D8]"
              }`}
          >
            <div className="flex flex-col md:flex-row gap-3 lg:gap-0 justify-between items-center px-3 pt-3">
              <div
                className={`bg-[#D9D9D9]/10 border rounded-full flex items-center ${theme === "dark" ? "border-white/20 " : "border-black/20"
                  }`}
              >
                <input
                  className="bg-transparent rounded-md py-1 px-3 outline-none w-[100%]"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FontAwesomeIcon icon={faMagnifyingGlass} className="px-3" />
              </div>
              <div className="flex items-center gap-5">
                <p className="text-end text-xs px-3">
                  Show no of entity
                  <select
                    className="ml-2 outline-none rounded-md bg-transparent border-[1px]"
                    onChange={handleDropdownChange}
                    value={entitiesPerPage}
                  >
                    <option className="text-black" value="4">
                      4
                    </option>
                    <option className="text-black" value="5">
                      5
                    </option>
                    <option className="text-black" value="10">
                      10
                    </option>
                  </select>
                </p>
                <button
                  className="bg-secondary rounded-full w-5 h-5 grid place-content-center"
                  onClick={handleCatergory}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
            <table className="w-full text-center text-base">
              <thead className={`${theme === "dark" ? "bg-[#0F0F0F]" : ""}`}>
                <tr>
                  <th className="py-5 px-5 md:px-2 ">NFT</th>
                  <th className="py-5 px-5 md:px-2 ">FNFT</th>
                </tr>
              </thead>
              <tr>
                <td colSpan="2" className={`${theme === "dark" ? "bg-[#2b2b2b] py-1" : "border-b border-[#363636]"}`}></td>
              </tr>
              <tbody
                className={`w-full text-sm rounded-lg px-3 ${theme === "dark" ? "bg-[#191a1f]" : ""
                  }`}
              >
                <tr>
                  <td className="divide-y divide-[#363636] w-[50%]">
                    {currentNft.length > 0 ? (
                      currentNft.map((item, index) => (
                        <div key={index} className="h-16 flex px-3 items-center gap-2 border-x border-[#363636] ">
                          <button
                            className="grid place-content-center h-4 w-4 rounded-full bg-red-500"
                            onClick={() => handleRemoveCategory(item, "nft")}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <div className="w-full truncate text-nowrap">{item}</div>
                        </div>
                      ))
                    ) : (
                      <p className="h-16 grid place-content-center">No NFT categories</p>
                    )}
                  </td>
                  <td className="divide-y divide-[#363636] w-[50%]">
                    {currentFnft.length > 0 ? (
                      currentFnft.map((item, index) => (
                        <div key={index} className="h-16 flex px-3 items-center gap-2 border-x border-[#363636] ">
                          <button
                            className="grid place-content-center h-4 w-4 rounded-full bg-red-500"
                            onClick={() => handleRemoveCategory(item, "fnft")}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <div className="w-full truncate text-nowrap">{item}</div>
                        </div>
                      ))
                    ) : (
                      <p className="h-16 grid place-content-center">No FNFT categories</p>
                    )}
                  </td>
                </tr>
              </tbody>

            </table>
          </div>
          {showConfirmation && (
            <div className="fixed inset-0 flex items-center justify-center z-20 bg-black/80">
              <div className="w-[90%] md:w-[35%] rounded-lg bg-[#151515] p-5">
                <div className="text-white space-y-4">
                  <h2 className="text-center font-bold">Do you want to delete this category?</h2>
                  <div className="flex justify-center gap-3">
                    <button
                      className="bg-red-500 text-white py-1 px-3 rounded"
                      onClick={() => confirmRemoveCategory(true)}
                    >
                      Yes
                    </button>
                    <button
                      className="bg-gray-500 text-white py-1 px-3 rounded"
                      onClick={() => confirmRemoveCategory(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

        </div>

        <div className="mb-3 flex justify-end text-xs px-10">
          <div className="flex gap-3">
            <button
              className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
              onClick={handlePrevClick}
              disabled={currentPage === 1}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <button
              className="px-3 py-1 rounded-lg bg-[#d2d2d2]/20"
              onClick={handleNextClick}
              disabled={currentNft.length < entitiesPerPage && currentFnft.length < entitiesPerPage}
            >
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      {Catergory && <Newcatergory theme={theme} handleCatergory={handleCatergory} addCategory={addCategory} />}
    </div>
  );
};

export default PlateformCategories;
