import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';  // To make HTTP requests
import 'react-toastify/dist/ReactToastify.css';
import { SERVER_URL } from '../../config';

export default function Forgetpassword() {
    const [email, setEmail] = useState(''); // State to store entered email
    const [isLoading, setIsLoading] = useState(false); // State to handle loading during request

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form default submission
    
        if (!email) {
            toast.error("Please enter an email address"); // Show toast if email is empty
            return;
        }
    
        setIsLoading(true); // Start loading
    
        try {
            // Send request to verify email existence in the database
            const response = await axios.post(`${SERVER_URL}/api/verify-email`, { email });
    
            if (response.data.status === 'success') {
                toast.success(response.data.message); // Show success toast if OTP is sent
                setTimeout(() => {
                  window.location.href = `/verifyemail?email=${encodeURIComponent(email)}&action=reset`; // Action parameter added
                }, 1000);
              }
              
        } catch (error) {
            // Handle the case where email is not registered
            if (error.response && error.response.status === 404) {
                toast.error(error.response.data.message); // Show the toast with the message from the server
            } else {
                toast.error('An error occurred. Please try again'); // Show a generic error toast
            }
        } finally {
            setIsLoading(false); // Stop loading
        }
    };
    
    return (
        <div className="w-full bg-[#0F0F0F] text-white bg-cover font-manrope">
            <Toaster />
            <div className="min-h-screen lg:h-screen flex flex-col lg:flex-row lg:overflow-hidden">
                <div className="w-[100%] lg:w-[50%] bg-[#151515] flex items-center justify-center">
                    <div><img src="/assets/Logo/mobilelogo.svg" alt="Logo" /></div>
                </div>
                <div className="w-[100%] lg:w-[50%] flex flex-col justify-center items-center py-10 lg:py-0">
                    <div>
                        <p className="font-medium text-3xl">Forgot Password</p>
                    </div>

                    <div className="mt-5 w-[90%] md:w-[50%] mx-auto rounded-lg">
                        <div className="mb-4">
                            <label className="block text-sm font-medium">
                                Enter Registered Email Address
                            </label>
                            <input
                                type="email"
                                className="w-full px-4 py-2 rounded-lg bg-[#707070] outline-none"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)} // Update email state
                                placeholder="Enter your email"
                            />
                        </div>

                        <div className="w-full flex justify-center gap-5 mt-5">
                            <button
                                type="submit"
                                onClick={handleSubmit} // Handle form submission
                                disabled={isLoading} // Disable button when loading
                                className="bg-secondary font-medium text-sm py-1.5 px-5 rounded-md hover:bg-opacity-85"
                            >
                                {isLoading ? 'Loading...' : 'Next'} {/* Show loading text while sending request */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
