const SERVER_URL ="https://app.ozonex.io";
// const SERVER_URL = "http://localhost:3006";

const BASE_URL = "https://app.ozonex.io";
// const BASE_URL = "http://localhost:3000";


const TYPE = "TESTNET"
const PROVIDER_URL = 'https://polygon-amoy.infura.io/v3/d888ba14c2914a21b28308709bc30b85'
const ADMIN_MINT_KEY = "42072b4e52266003803448d6c93ff40dd2d1ea612a1afe46d9c7d07a7fe3ab66"
const ADMIN_WALLET = "0xe133De20D3a16caE04c34d78d7E03845C8De3d9B"
let MintContract;
let FractionContract;
let TradeContract;
let TransferProxyContract;
let TokenContract;
let DividentContract;


if (TYPE == 'TESTNET') {
    MintContract = "0xDbbbBB7F920702c0CF2C382Be47a7B4219e6F9c4";
    FractionContract = "0xa1e4C8B3E717e83D99a95aC920263112d747D4F5";
    TradeContract = "0xF8B8dBD9030375c2DfB93fFF523082807d8D5a95";
    TransferProxyContract = "0xe133De20D3a16caE04c34d78d7E03845C8De3d9B";
    TokenContract = "0x088ea19b7f2038b1337f387f330E6709C48AB41B";
    DividentContract = '0xFBd6d44a0513e5e8b8906eeaE9A2c4597348F9dE';
}   

module.exports = {
    SERVER_URL, MintContract, PROVIDER_URL, ADMIN_MINT_KEY, FractionContract, ADMIN_WALLET, TradeContract, TransferProxyContract,
    TokenContract, DividentContract, BASE_URL
}