import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from '../../config';

export default function Dashboard({ theme }) {
    const [userCount, setUserCount] = useState(0);
    const [active, setActive] = useState("div1");

    useEffect(() => {
        const fetchUserCount = async () => {
            try {
                const response = await axios.get(`${SERVER_URL}/api/users/count`);
                setUserCount(response.data.userCount);
            } catch (error) {
                console.error('Error fetching user count:', error);
            }
        };

        fetchUserCount();
    }, []);

    const data = [
        {
            date: "2024-11-28",
            transactionhash: "0x7a4b3f56e89a12a7e6c4f9e3e2c19d4f5b8e123f",
            category: "Deposit",
            blockchain: "Ethereum",
            amount: "2.5 ETH",
        },
        {
            date: "2024-11-27",
            transactionhash: "0x9b6f5e2c91d4f3b8e6a7a4b3f2e89a12e4f6b3c9",
            category: "Withdrawal",
            blockchain: "Bitcoin",
            amount: "0.05 BTC",
        },
        {
            date: "2024-11-26",
            transactionhash: "0x2c9d4f5b8e6a7a4b3f2e89a123f91d6e5f9e3e19",
            category: "Transfer",
            blockchain: "Polygon",
            amount: "150 MATIC",
        },
        {
            date: "2024-11-25",
            transactionhash: "0x5e89a123f91d4f3b8e6a7a4b3f2e9c1d6e5f2e19",
            category: "Payment",
            blockchain: "Binance Smart Chain",
            amount: "300 BNB",
        },
        {
            date: "2024-11-24",
            transactionhash: "0x3e19c1d6e5f2e89a123f91d4f3b8e6a7a4b3f2e9",
            category: "Staking",
            blockchain: "Solana",
            amount: "50 SOL",
        },
    ];


    return (
        <div>
            <div className='px-10 py-10 lg:py-7'>
                <h1 className='text-xl font-semibold'>Top Collections</h1>
                <div className='mt-5 grid md:grid-cols-2 lg:grid-cols-4 gap-5'>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>Categories</h1>
                        <p className='font-bold text-2xl mt-2'>4</p>
                    </div>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>Minting Count</h1>
                        <p className='font-bold text-2xl mt-2'>39</p>
                    </div>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>User</h1>
                        <p className='font-bold text-2xl mt-2'>{userCount}</p>
                    </div>
                    <div className={`text-white py-4 px-6 rounded-md border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#2B2B2B] border-[#D9D8D8]'}`}>
                        <h1>Transactions</h1>
                        <p className='font-bold text-2xl mt-2'>191</p>
                    </div>
                </div>
                <div className="mt-5 flex flex-col gap-5 lg:gap-0 lg:flex-row items-center">
                    <p className='text-xl font-semibold'>Latest Mint</p>
                </div>
                <div className={`overflow-x-auto rounded-md p-3 mt-5 border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                    <table className="table-auto w-full text-left border-collapse ">
                        <thead>
                            <tr className="border border-gray-700">
                                <th className="px-4 py-2">Date</th>
                                <th className="px-4 py-2">Transaction Hash</th>
                                <th className="px-4 py-2">Category</th>
                                <th className="px-4 py-2">Blockchain</th>
                                <th className="px-4 py-2">Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index} className="">
                                    <td className="px-2 py-2 text-sm whitespace-nowrap ">{row.date}</td>
                                    <td className="px-4 py-2 truncate">{row.transactionhash}</td>
                                    <td className="px-4 py-2 ">{row.category}</td>
                                    <td className="px-4 py-2 ">{row.blockchain}</td>
                                    <td className="px-4 py-2 ">{row.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-5 flex flex-col gap-5 lg:gap-0 lg:flex-row items-center">
                    <p className='text-xl font-semibold'>Latest Transactions </p>
                </div>
                <div className={`overflow-x-auto rounded-md p-3 mt-5 border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                    <table className="table-auto w-full text-left border-collapse ">
                        <thead>
                            <tr className="border border-gray-700">
                                <th className="px-4 py-2">Date</th>
                                <th className="px-4 py-2">Transaction Hash</th>
                                <th className="px-4 py-2">Category</th>
                                <th className="px-4 py-2">Blockchain</th>
                                <th className="px-4 py-2">Amount</th>
                            </tr>
                        </thead>

                        <tbody>
                            {data.map((row, index) => (
                                <tr key={index} className="">
                                    <td className="px-4 py-2 text-sm whitespace-nowrap ">{row.date}</td>
                                    <td className="px-4 py-2 truncate">{row.transactionhash}</td>
                                    <td className="px-4 py-2 ">{row.category}</td>
                                    <td className="px-4 py-2 ">{row.blockchain}</td>
                                    <td className="px-4 py-2 ">{row.amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
