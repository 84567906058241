import toast from "react-hot-toast";
import { store } from "./store";

export const withAuthCheck = (callback) => {
  return (event) => {
    const state = store.getState(); // Access Redux state
    const isLoggedIn = !!state.user.username; // Check if the user is logged in

    if (!isLoggedIn) {
      event.preventDefault();
      toast.error("You must log in to perform this action.");
      setTimeout(() => {
        window.location.href = '/login'; // Adjust to the correct path of the Profile Setup page
      }, 1000);
      return;
      
    }

    // If logged in, execute the original callback
    callback(event);
  };
};
