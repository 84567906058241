import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialUserState = {
  userID: null,
  username: "",
  isWalletChecked: false,
};

const SET_USER_INFO = "SET_USER_INFO";
const SET_USER_ID = "SET_USER_ID";
const UPDATE_USER = "UPDATE_USER";
const SET_WALLET_CHECKED = "SET_WALLET_CHECKED";
const LOGOUT = "LOGOUT";

const initialAdminState = {
  username: "",
  email: "",
};

const SET_ADMIN_INFO = "SET_ADMIN_INFO";
const LOGOUT_ADMIN = "LOGOUT_ADMIN";

export const setAdminInfo = (adminData) => ({
  type: SET_ADMIN_INFO,
  payload: adminData,
});

export const logoutAdmin = () => ({
  type: LOGOUT_ADMIN,
});

export const setUserID = (userID) => ({
  type: SET_USER_ID,
  payload: userID,
});

export const setUserInfo = (username) => ({
  type: SET_USER_INFO,
  payload: username,
});

export const updateUser = (userData) => ({
  type: UPDATE_USER,
  payload: userData,
});

export const setWalletChecked = (isChecked) => ({
  type: SET_WALLET_CHECKED,
  payload: isChecked,
});

export const logout = () => ({
  type: LOGOUT,
});

const adminReducer = (state = initialAdminState, action) => {
  switch (action.type) {
    case SET_ADMIN_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT_ADMIN:
      return {
        ...initialAdminState,
      };
    default:
      return state;
  }
};

const userReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        username: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.payload,
      };
    case SET_WALLET_CHECKED:
      return {
        ...state,
        isWalletChecked: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userID: action.payload,
      };
    case LOGOUT:
      return {
        ...initialUserState,
      };
    default:
      return state;
  }
};

const initialUIState = {
  isButtonDisabled: false,
};

const SET_BUTTON_DISABLED = "SET_BUTTON_DISABLED";

export const setButtonDisabled = (isDisabled) => ({
  type: SET_BUTTON_DISABLED,
  payload: isDisabled,
});

const uiReducer = (state = initialUIState, action) => {
  switch (action.type) {
    case SET_BUTTON_DISABLED:
      return {
        ...state,
        isButtonDisabled: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  admin: adminReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user", "ui", "admin"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store);
